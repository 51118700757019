/* eslint-disable no-nested-ternary */
// @ts-nocheck
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionPanelProps,
  SemanticShorthandCollection,
  Transition,
} from "semantic-ui-react";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import SearchBox from "../shared/components/SearchBox.component";
import botAllowed from "../shared/utils/botAllowed";
import useUserIsAdmin from "../hooks/useUserIsAdmin";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";

const SettingsPage = ({ toggleSidebar }: { toggleSidebar: boolean }) => {
  const { isAdmin } = useUserIsAdmin();
  const { canAccess } = useAccessControl();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState([0]);
  const [keyword, setKeyword] = useState("");

  interface itemsAccordion {
    [key: string]: {
      _id: string;
      icon: string;
      name: string;
      url: string;
    };
  }

  const items: itemsAccordion = {
    general: {
      _id: "general",
      icon: "las la-cog",
      name: "Configurações gerais",
      url: "/settings/general",
    },
    officehours: {
      _id: "officehours",
      icon: "las la-calendar-check",
      name: "Horários de atendimento",
      url: "/settings/officehours",
    },
    holidays: {
      _id: "holidays",
      icon: "las la-calendar-times",
      name: "Feriados",
      url: "/settings/holidays",
    },
    offlinemessages: {
      _id: "offlinemessages",
      icon: "las la-hourglass-end",
      name: "Perfis de atendimento fora de horário",
      url: "/settings/offlinemessages",
    },
    agidesk: {
      _id: "agidesk",
      icon: "las la-life-ring",
      name: "Agidesk",
      url: "/settings/integration/agidesk",
    },
    whatsapp: {
      _id: "whatsapp",
      icon: "lab la-whatsapp",
      name: "WhatsApp",
      url: "/settings/integration/whatsapp",
    },
    agibot: {
      _id: "agibot",
      icon: "las la-robot",
      name: "Agibot",
      url: "/settings/integration/agibot",
    },
    vtex: {
      _id: "vtex",
      icon: "las la-shopping-cart",
      name: "VTEX",
      url: "/settings/integration/vtex",
    },
    contacts: {
      _id: "contacts",
      icon: "las la-address-book",
      name: "Contatos",
      url: "/settings/contacts",
    },
    customers: {
      _id: "customers",
      icon: "las la-building",
      name: "Clientes",
      url: "/settings/customers",
    },
    users: {
      _id: "users",
      icon: "las la-user",
      name: "Agentes",
      url: "/settings/users",
    },
    teams: {
      _id: "teams",
      icon: "las la-user-friends",
      name: "Equipes",
      url: "/settings/teams",
    },
    profile: {
      _id: "profile",
      icon: "las la-user-circle",
      name: "Meu perfil",
      url: "/settings/profile",
    },
    dashboards: {
      _id: "dashboards",
      icon: "las la-chart-bar",
      name: "Resultados",
      url: "/dashboards",
    },
    surveys: {
      _id: "surveys",
      icon: "las la-smile",
      name: "Pesquisas de satisfação",
      url: "/settings/surveys",
    },
    campaigns: {
      _id: "campaigns",
      icon: "las la-bullhorn",
      name: "Campanhas",
      url: "/settings/campaigns",
    },
    commenttemplates: {
      _id: "commenttemplates",
      icon: "las la-comment",
      name: "Modelos de respostas",
      url: "/settings/commenttemplates",
    },
    tags: {
      _id: "tags",
      icon: "las la-tags",
      name: "Marcadores",
      url: "/settings/tags/chattags",
    },
    permissiongroups: {
      _id: "permissiongroups",
      icon: "las la-user-shield ",
      name: "Grupos de permissão",
      url: "/settings/permissiongroups",
    },
  };

  const searchfields = {
    0: canAccess(Permissions.VIEW_GENERAL_SETTINGS)
      ? "sistema informações da empresa definição de padrões configurações de atendimento gerais widget padrão anônimo ações anônimas chaves de acesso"
      : "",
    1: "grupos usuários cadastros de contatos agentes clientes equipes",
    2: "operação definição de horários feriados perfis de atendimento fora de horário",
    3:
      canAccess(Permissions.VIEW_COMMENT_TEMPLATES)
        ? "comunicação criação de modelos de respostas"
        : "modelos de respostas",
    4:
      canAccess(Permissions.VIEW_CHAT_TAGS) && canAccess(Permissions.VIEW_CONTACT_TAGS) && canAccess(Permissions.VIEW_SURVEYS)
        ? "criação marcadores conversa contato gerenciamento acompanhamento resultados pesquisas de satisfação campanhas"
        : "marcadores conversa contato",
    5: "integrações recursos avançados para autenticação envio recebimento de dados agidesk whatsapp agibot vtex",
  };

  const getItems = ({
    _array,
    _panel,
  }: {
    _array: {
      _id: string;
      icon: string;
      name: string;
      url: string;
    }[];
    _panel: string;
  }) => {
    const _items =
      keyword.length === 0
        ? _array
        : _array.filter((_item) =>
          _item.name.toLowerCase().includes(keyword.toLowerCase())
        );
    if (
      _panel.toLowerCase().includes(keyword.toLowerCase()) &&
      _items.length === 0
    ) {
      return _array;
    }

    return _items;
  };

  const _panels = useMemo(() => {
    const __panels = [];
    if (canAccess(Permissions.VIEW_GENERAL_SETTINGS)) {
      __panels.push({
        _id: __panels.length,
        icon: "las la-cog",
        title: "Sistema",
        searchfield: searchfields[0],
        subtitle:
          "Informações da empresa, chaves de acesso, definição de padrões e configurações de atendimento",
        items: getItems({
          _array: [items.general],
          _panel: searchfields[0],
        }),
      })
    }
    if (canAccess(Permissions.VIEW_PERMISSIONS_GROUPS)
      || canAccess(Permissions.VIEW_CONTACTS)
      || canAccess(Permissions.VIEW_AGENTS)
      || canAccess(Permissions.VIEW_CUSTOMERS)
      || canAccess(Permissions.VIEW_TEAMS)) {
      __panels.push({
        _id: __panels.length,
        icon: "las la-user-friends",
        searchfield: searchfields[1],
        title: "Grupos e usuários",
        subtitle: "Cadastros de contatos, agentes, clientes e equipes",
        items: getItems({
          _array: [
            canAccess(Permissions.VIEW_PERMISSIONS_GROUPS) && items.permissiongroups,
            canAccess(Permissions.VIEW_CONTACTS) && items.contacts,
            canAccess(Permissions.VIEW_AGENTS) && items.users,
            canAccess(Permissions.VIEW_CUSTOMERS) && items.customers,
            canAccess(Permissions.VIEW_TEAMS) && items.teams,
          ].filter(i => !!i),
          _panel: searchfields[1],
        }),
      })
    }
    if (canAccess(Permissions.VIEW_OFFICE_HOURS)
      || canAccess(Permissions.VIEW_HOLLIDAYS)
      || canAccess(Permissions.VIEW_OFFILINE_MESSAGES)) {
      __panels.push({
        _id: __panels.length,
        icon: "las la-sitemap",
        title: "Operação",
        searchfield: searchfields[2],
        subtitle:
          "Definição de horários, feriados e perfis de atendimento fora de horário",
        items: getItems({
          _array: [
            canAccess(Permissions.VIEW_OFFICE_HOURS) && items.officehours,
            canAccess(Permissions.VIEW_HOLLIDAYS) && items.holidays,
            canAccess(Permissions.VIEW_OFFILINE_MESSAGES) && items.offlinemessages,
          ].filter(i => !!i),
          _panel: searchfields[2],
        }),
      })
    }
    if (canAccess(Permissions.VIEW_COMMENT_TEMPLATES)) {
      __panels.push({
        _id: __panels.length,
        icon: "las la-envelope",
        title: "Comunicação",
        searchfield: searchfields[3],
        subtitle: "Criação de modelos de respostas",
        items: getItems({
          _array: [items.commenttemplates],
          _panel: searchfields[3],
        }),
      });
    }
    if (canAccess(Permissions.VIEW_DASHBOARDS)
      || canAccess(Permissions.VIEW_SURVEYS)
      || canAccess(Permissions.VIEW_CAMPAIGNS)
      || canAccess(Permissions.VIEW_CHAT_TAGS)
      || canAccess(Permissions.VIEW_CONTACT_TAGS)) {
      __panels.push({
        _id: __panels.length,
        icon: "las la-chart-pie",
        title: "Gerenciamento",
        searchfield: searchfields[4],
        subtitle:
          "Criação e gerenciamento de marcadores, acompanhamento de resultados, pesquisas de satisfação e campanhas",
        items: getItems({
          _array: [
            canAccess(Permissions.VIEW_DASHBOARDS) && items.dashboards,
            canAccess(Permissions.VIEW_SURVEYS) && items.surveys,
            canAccess(Permissions.VIEW_CAMPAIGNS) && items.campaigns,
            (canAccess(Permissions.VIEW_CHAT_TAGS) || canAccess(Permissions.VIEW_CONTACT_TAGS)) && items.tags,
          ].filter(i => !!i),
          _panel: searchfields[4],
        }),
      })
    }
    if (canAccess(Permissions.VIEW_INTEGRATIONS_AGIDESK)
      || canAccess(Permissions.VIEW_INTEGRATIONS_WHATSAPP)
      || canAccess(Permissions.VIEW_INTEGRATIONS_AGIBOT)
      || canAccess(Permissions.VIEW_INTEGRATIONS_VTEX)) {
      __panels.push({
        _id: __panels.length,
        icon: "las la-code",
        title: "Integrações",
        searchfield: searchfields[5],
        subtitle:
          "Recursos avançados para autenticação, envio e recebimento de dados",
        items: getItems({
          _array: botAllowed()
            ? [
              canAccess(Permissions.VIEW_INTEGRATIONS_AGIDESK) && items.agidesk,
              canAccess(Permissions.VIEW_INTEGRATIONS_WHATSAPP) && items.whatsapp,
              canAccess(Permissions.VIEW_INTEGRATIONS_AGIBOT) && items.agibot,
              canAccess(Permissions.VIEW_INTEGRATIONS_VTEX) && items.vtex
            ].filter(i => !!i)
            : [
              canAccess(Permissions.VIEW_INTEGRATIONS_AGIDESK) && items.agidesk,
              canAccess(Permissions.VIEW_INTEGRATIONS_WHATSAPP) && items.whatsapp
            ].filter(i => !!i),
          _panel: searchfields[5],
        }),
      })
    }

    return __panels;
  }, [canAccess, Permissions, items, searchfields, getItems]);

  const goBack = () => {
    navigate(-1);
  };

  const goSave = async () => {
    return null;
  };

  const filteredpanels =
    keyword.length > 0
      ? _panels.filter((_panel) =>
        _panel?.searchfield.toLowerCase().includes(keyword.toLowerCase())
      )
      : _panels;

  useEffect(() => {
    if (filteredpanels.length === 1 || keyword.length === 0) {
      setActiveIndex([0]);
    } else if (filteredpanels.length > 1) {
      setActiveIndex(Array.from(Array(filteredpanels.length).keys()));
    }
  }, [keyword]);

  const panels: SemanticShorthandCollection<AccordionPanelProps> | undefined =
    filteredpanels.map((panel, index) => ({
      key: index,
      title: {
        children: (
          <div className="flex items-center justify-between p-4 rounded-md bg-agitalks-alpha30 cursor-pointer w-full">
            <div className="flex items-center">
              <i className={`text-[22px] ${panel?.icon} mr-4`} />
              <div className="flex flex-col">
                <h1 className="font-semibold text-[16px]">{panel?.title}</h1>
                <h1 className="text-[14px]">{panel?.subtitle}</h1>
              </div>
            </div>
            <i
              className={`${activeIndex.includes(index)
                ? "las la-angle-up"
                : "las la-angle-down"
                }`}
            />
          </div>
        ),
      },
      content: {
        content: (
          <Transition
            animation="fade down"
            duration={{ hide: 0, show: 250 }}
            visible={activeIndex.includes(index)}
          >
            <div>
              <div className="grid grid-cols-5 mx-4 lg:mx-8">
                {panel?.items.map((_item) => (
                  <NavLink
                    key={_item._id}
                    to={_item.url}
                    className="flex flex-col items-center mx-[22px]"
                  >
                    <span
                      className={`mb-2 text-agitalks ${_item.icon} font-semibold text-[35px]`}
                    />
                    <h1 className="mb-2 text-agitalks text-center">
                      {_item.name}
                    </h1>
                  </NavLink>
                ))}
              </div>
            </div>
          </Transition>
        ),
      },
    }));

  return (
    <div
      id="settings"
      className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-8"
    >
      <HeaderPageInfo
        created={null}
        updated={null}
        deleted={null}
        title="Configurações"
        imported={false}
        icon="las la-cog"
      />
      <div className="flex items-center justify-between py-5">
        <SearchBox
          placeholder="Filtrar na lista..."
          extraClass=""
          keyword={keyword}
          _escFunction={() => {
            setKeyword("");
            setActiveIndex([0]);
          }}
          listFilter={(e) => {
            setKeyword(e.target.value);
          }}
        />
        <div className="flex items-center justify-end">
          <div
            className="text-blue-link cursor-pointer"
            role="presentation"
            onClick={() => {
              if (activeIndex.length < filteredpanels.length) {
                setActiveIndex(Array.from(Array(filteredpanels.length).keys()));
              }
            }}
          >
            Expandir todos
          </div>
          <h1 className="mx-1">|</h1>
          <div
            className="text-blue-link cursor-pointer"
            role="presentation"
            onClick={() => {
              if (activeIndex.length > 0) {
                setActiveIndex([]);
              }
            }}
          >
            Fechar todos
          </div>
        </div>
      </div>
      {/* {isAdmin && panels.length > 0 ? ( */}
      {panels.length > 0 ? (
        <Accordion
          panels={panels}
          fluid
          exclusive={false}
          activeIndex={activeIndex}
          onTitleClick={(e, { index }) => {
            const _index = index as number;
            if (!activeIndex.includes(_index)) {
              const _activeIndex = [...activeIndex, _index];
              setActiveIndex(_activeIndex);
            } else {
              const _activeIndex = activeIndex.filter(
                (item) => item !== _index
              );
              setActiveIndex(_activeIndex);
            }
          }}
        />
      ) : (
        <div className="flex w-full h-full justify-center p-6">
          Nenhum resultado
        </div>
      )}
      <div className="mb-20" />
      <FooterPageInfo
        toggleSidebar={toggleSidebar}
        back={goBack}
      />
    </div>
  );
};

export default SettingsPage;
