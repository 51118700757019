import React from "react";
import { useLocation } from "react-router-dom";
import Toggle from "../Toggle.component";
import Button from "../Buttons/Button.component";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";
// import TagTypeDropdown from "../Dropdowns/TagTypes.dropdown.component";
// import { TagType } from "../../../slices/tags.slice";

const GridButtonOptions = ({
  setShowCards,
  setShowModal,
  showDeleted,
  showSpam,
  setSkip,
  setShowDeleted,
  setShowSpam,
  setLimit,
}: {
  setShowCards: () => void;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleted: boolean;
  setShowDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  showSpam: boolean;
  setShowSpam: React.Dispatch<React.SetStateAction<boolean>>;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const location = useLocation();
  return (
    <div className="flex flex-1 justify-end items-center">
      <div className="flex w-full justify-start items-center">
        {/* {location.pathname.includes("tag") ? (
          <TagTypeDropdown
            placeholder="Filtrar por tipo"
            onChange={async (e, { value }) => {
              const _value = value as TagType;
              setTagToggleList(_value);
            }}
          />
        ) : null} */}
        <Toggle
          id="toggle-grid-deleted"
          containerClass="flex justify-start items-center"
          checked={showDeleted}
          // defaultChecked={showDeleted}
          mainClass="ml-4 flex items-center"
          onChange={(_, { checked }) => {
            setSkip(0);
            setLimit(30);
            setShowDeleted(checked || false);
          }}
          labelClass="hidden sm:flex ml-4"
          label="Removidos"
        />
        {(isAdmin || isSupervisor) &&
          location.pathname.includes("settings/contact") ? (
          <Toggle
            id="toggle-grid-spam"
            containerClass="flex justify-start items-center"
            checked={showSpam}
            mainClass="ml-4 flex items-center"
            onChange={(_, { checked }) => {
              setSkip(0);
              setLimit(30);
              setShowSpam(checked || false);
            }}
            labelClass="hidden sm:flex ml-4"
            label="SPAM"
          />
        ) : null}
      </div>
      {!!setShowModal ? (
        <>
          <Button
            extraClass="md:w-[80px]"
            minWidth={false}
            label="Criar"
            onClick={() => setShowModal(true)}
            icon="las la-plus"
          />
          <div className="mr-1" />
        </>
      ) : null}
      <div className="flex">
        <Button
          extraClass="md:w-[80px]"
          minWidth={false}
          label="Lista"
          icon="las la-list-ul"
          onClick={setShowCards}
          inverted
        />
      </div>
    </div>
  );
};

export default GridButtonOptions;
