// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { Permissions } from "../slices/permissiongroups.slice";
import { useAppSelector } from "./redux/hooks";

const useAccessControl = () => {
  const { user } = useAppSelector((state) => state.auth);

  const canAccess = React.useCallback(
    (permission: Permissions) => {
      return user?.permissions?.includes(permission) ?? false;
    },
    [user?.permissions]
  );

  return { canAccess };
};

export default useAccessControl;
