import React, { useEffect, useMemo } from "react";
import { Form } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux/hooks";
import { ActiveDropdown, RolesDropdown } from "../../../shared/components/Dropdowns";
import usePermissiongroups from "../../../hooks/features/usePermissiongroups";
import Toggle from "../../../shared/components/Toggle.component";
import PopupComponent from "../../../shared/components/Popup.component";
import { reselectLocalPermissiongroup, updatePermissiongroup } from "../../../slices/permissiongroups.slice";

const Infos = () => {

  const dispatch = useAppDispatch();
  const { infos, permissiongroupCallback } = usePermissiongroups({});
  const { selectedLocalPermissiongroup, selectedPermissiongroup } = useAppSelector(
    (state) => state.permissiongroups
  );

  useEffect(() => {
    permissiongroupCallback();
  }, [permissiongroupCallback]);

  return (
    <Form>
      <Form.Group className="mt-4" widths="equal">
        <Form.Input
          autoComplete="off"
          readOnly={infos.readonly}
          className="w-full md:w-full"
          label="Nome"
          fluid
          placeholder="Nome"
          defaultValue={selectedLocalPermissiongroup?.name}
          type="text"
          required
          onChange={(e, { value }) => {
            infos.onChange.name(e, { value });
          }}
        />
        <ActiveDropdown
          disabled={infos.active.disabled}
          className="w-full mt-6"
          fluid
          value={selectedLocalPermissiongroup?.active}
          onChange={(e, { value }) => {
            infos.onChange.active(e, { value });
          }}
        />
      </Form.Group>
      <Form.Group className="mt-4" widths="equal">
        {selectedLocalPermissiongroup?.role && (
          <RolesDropdown
            defaultValue={selectedLocalPermissiongroup?.role}
            onChange={(e, { value }) => {
              if (value) {
                infos.onChange.role(e, { value });
              }
            }}
          />
        )}
        {!selectedLocalPermissiongroup?.default ? (
          <Toggle
            containerClass="flex w-full mt-6"
            defaultChecked={selectedLocalPermissiongroup?.default || false}
            readOnly={
              selectedLocalPermissiongroup?.deleted ||
              !selectedLocalPermissiongroup?.active
            }
            mainClass="items-center flex"
            onChange={async (_, { checked }) => {
              const _permissionGroup = {
                ...selectedPermissiongroup!,
                default: checked,
              };
              const { payload } = await dispatch(
                updatePermissiongroup({ _permissionGroup })
              );
              dispatch(reselectLocalPermissiongroup(payload));
            }}
            labelClass="ml-4"
            label="Definir como padrão do perfil"
          />
        ) : (
          <PopupComponent
            className="mt-6 flex items-center w-full"
            label="Grupo de permissões definido como padrão do perfil"
            italic
            content={
              <h1>
                Novos agentes cadastrados com este perfil respeitarão este
                grupo de permissão.
              </h1>
            }
          />
        )}
      </Form.Group>
    </Form>
  );
};

export default Infos;
