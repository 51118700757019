import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  ChatRoutingType,
  deleteTeam,
  reselectTeam,
  selectTeam,
  updateTeam,
} from "../slices/teams.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import ChatAgentModal from "../shared/components/Modals/ChatAgentModal.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import { SidebarComponent } from "../shared/components/Sidebars";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
// import ConfirmationModal from "../shared/components/Modals/ConfirmationModal.component";
// import { UserType } from "../slices/users.slice";
import { getSettings } from "../slices/settings.slice";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";

const TeamPage = ({
  icon,
  title,
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const { canAccess } = useAccessControl();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingTeams, selectedTeam } = useAppSelector(
    (state) => state.teams
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  // const [showLastDefaultteamModal, setShowLastDefaultteamModal] =
  //   useState<boolean>(false);
  // const [msgError, setMsgError] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      await dispatch(selectTeam({ _id: getId() }));
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedTeam?._id) {
      const { payload } = await dispatch(deleteTeam([selectedTeam._id]));
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate(-1);
      } // else {
      //   // SHOW MODAL
      //   setMsgError(payload?.message);
      //   setShowLastDefaultteamModal(true);
      // }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _team = {
      ...selectedTeam,
      officehour: selectedTeam?.officehour?._id,
      survey: selectedTeam?.survey?._id,
      deleted: false,
      active: true,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateTeam({ _team }));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSave = async () => {
    setDisable(true);
    const _team = {
      ...selectedTeam,
      _id: selectedTeam?._id,
      active: selectedTeam?.active,
      assignedbacklog: selectedTeam?.assignedbacklog,
      assignedtype: selectedTeam?.assignedtype,
      backlogsize: selectedTeam?.backlogsize,
      chatlimit: selectedTeam?.chatlimit,
      chatlimitsize: selectedTeam?.chatlimitsize,
      chatusers: selectedTeam?.chatusers,
      name: selectedTeam?.name,
      routingtype: ChatRoutingType.ROUNDROBIN,
      officehour: selectedTeam?.officehour?._id,
      survey: selectedTeam?.survey?._id,
    };
    const { payload } = await dispatch(updateTeam({ _team }));
    setDisable(false);
    if (
      payload !== null &&
      typeof payload !== "undefined" &&
      typeof payload?.message !== "string" &&
      typeof payload?._id !== "undefined"
    ) {
      dispatch(
        reselectTeam({
          ...selectedTeam,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
    } else {
      await dispatch(selectTeam({ _id: selectedTeam?._id || "" }));
    }
  };

  // const getOrigin = () => {
  //   if (msgError?.includes("external app")) {
  //     return "um aplicativo externo";
  //   }
  //   return "o atendimento com widget";
  // };

  if (isLoadingTeams)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12  px-4 sm:px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        content={
          <CreateModal
            title={title}
            icon={icon}
            setShowModal={setShowModal}
            setVisible={setVisible}
          />
        }
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedTeam?.created_at}
          updated={selectedTeam?.updated_at}
          deleted={selectedTeam?.deleted_at}
          title={selectedTeam?.name || ""}
          imported={!!selectedTeam?.externalcode}
          icon="las la-user-friends"
        />
        <Section
          icon="las la-info-circle"
          title="Informações"
          description="Edite as informações exibidas"
          component={
            <SectionElement instance={selectedTeam} type="team-info" />
          }
        />
        <DividerInfoPages />
        <Section
          icon="las la-user"
          title="Agentes que pertencem a equipe"
          description="Relacione os agentes desejados"
          component={
            <SectionElement
              instance={selectedTeam}
              type="team-users"
              setShowModal={setShowModal}
              setVisible={setVisible}
            />
          }
        />
        <DividerInfoPages />
        <Section
          icon="las la-headset"
          title="Agentes disponíveis para atendimento"
          description="Organize os agentes disponíveis para atender o canal de chat. Você pode arrastá-los para determinar a ordem de ação."
          component={
            <SectionElement
              instance={selectedTeam}
              type="team-chatusers"
              setShowChatModal={setShowChatModal}
            />
          }
        />
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            selectedTeam?.deleted ||
            typeof selectedTeam?.name === "undefined" ||
            selectedTeam?.name?.length < 2
          }
          deleted={selectedTeam?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedTeam?.deleted
              ? "Deseja reativar esta equipe?"
              : "Deseja remover esta equipe?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={canAccess(Permissions.DELETE_TEAMS) ? goReactive : undefined}
          remove={canAccess(Permissions.DELETE_TEAMS) ? goRemove : undefined}
          save={canAccess(Permissions.UPDATE_TEAMS) ? goSave : undefined}
        />
        {/* MODALS */}
        {showModal ? (
          <RelationModal
            title="Relacionar agentes"
            icon={icon}
            setShowModal={setShowModal}
          />
        ) : null}
        {showChatModal ? (
          <ChatAgentModal
            list={selectedTeam?.users?.filter(
              (_user) => _user.active !== false
            )}
            title="Agentes disponíveis para atendimento"
            setShowModal={setShowChatModal}
          />
        ) : null}
        {/* {showLastDefaultteamModal ? (
          <ConfirmationModal
            widgetType={UserType.NONE}
            disabled={false}
            title="Ação interrompida"
            content={`A equipe <b>${
              selectedTeam?.name
            }</b> é a única equipe configurada para ${getOrigin()} e, assim, não pode ser excluída ou inativada.<br />
            setShowModal={setShowLastDefaultteamModal}
            action={() => {
              setShowLastDefaultteamModal(false);
              navigate("/settings/general#settings-defaultteam");
            }}
            submitIcon="las la-cog"
            submitLabel="Ir para configurações"
          />
        ) : null} */}
      </Sidebar.Pusher>
    </div>
  );
};

export default TeamPage;
