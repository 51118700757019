import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  deleteCustomer,
  reselectCustomer,
  selectCustomer,
  updateCustomer,
} from "../slices/customers.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import { SidebarComponent } from "../shared/components/Sidebars";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import { getSettings } from "../slices/settings.slice";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";

const CustomerPage = ({
  icon,
  title,
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const { canAccess } = useAccessControl();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingCustomers, selectedCustomer } = useAppSelector(
    (state) => state.customers
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      await dispatch(selectCustomer({ _id: getId() }));
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedCustomer?._id) {
      const { payload } = await dispatch(
        deleteCustomer([selectedCustomer._id])
      );
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate(-1);
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _customer = {
      ...selectedCustomer,
      active: true,
      deleted: false,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateCustomer({ _customer }));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSave = async () => {
    setDisable(true);
    const _customer = {
      _id: selectedCustomer?._id,
      name: selectedCustomer?.name,
      active: selectedCustomer?.active,
      fullname: selectedCustomer?.fullname,
      code: selectedCustomer?.code,
      reference: selectedCustomer?.reference,
    };
    const { payload } = await dispatch(updateCustomer({ _customer }));
    setDisable(false);
    if (
      payload !== null &&
      typeof payload !== "undefined" &&
      typeof payload?.message !== "string" &&
      typeof payload?._id !== "undefined"
    ) {
      dispatch(
        reselectCustomer({
          ...selectedCustomer,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
    } else {
      await dispatch(selectCustomer({ _id: selectedCustomer?._id || "" }));
    }
  };

  if (isLoadingCustomers)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        content={
          <CreateModal
            title={title}
            icon={icon}
            setShowModal={setShowModal}
            setVisible={setVisible}
          />
        }
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedCustomer?.created_at}
          updated={selectedCustomer?.updated_at}
          deleted={selectedCustomer?.deleted_at}
          title={
            selectedCustomer?.name
              ? selectedCustomer?.name
              : selectedCustomer?.fullname || ""
          }
          imported={!!selectedCustomer?.externalcode}
          icon="las la-building"
        />
        <Section
          icon="las la-info-circle"
          title="Informações"
          description="Edite as informações exibidas"
          component={
            <SectionElement instance={selectedCustomer} type="customer-info" />
          }
        />
        <DividerInfoPages />
        <Section
          icon="las la-user"
          title="Contatos que pertencem ao cliente"
          description="Relacione os contatos desejados"
          component={
            <SectionElement
              instance={selectedCustomer}
              type="customer-contacts"
              setShowModal={setShowModal}
              setVisible={setVisible}
            />
          }
        />
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            selectedCustomer?.deleted ||
            typeof selectedCustomer?.name === "undefined" ||
            selectedCustomer?.name?.length < 2
          }
          deleted={selectedCustomer?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedCustomer?.deleted
              ? "Deseja reativar este cliente?"
              : "Deseja remover este cliente?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={canAccess(Permissions.DELETE_CUSTOMERS) ? goReactive : undefined}
          remove={canAccess(Permissions.DELETE_CUSTOMERS) ? goRemove : undefined}
          save={canAccess(Permissions.UPDATE_CUSTOMERS) ? goSave : undefined}
        />
        {/* MODALS */}
        {showModal ? (
          <RelationModal
            title="Relacionar contatos"
            icon={icon}
            setShowModal={setShowModal}
          />
        ) : null}
      </Sidebar.Pusher>
    </div>
  );
};

export default CustomerPage;
