/* eslint-disable no-case-declarations */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Column,
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  Row,
} from "react-table";
import { Table } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DEFAULT_COLUMNS,
  DEFAULT_WITH_TEAMS_COLUMNS,
  CONTACTS_COLUMNS,
  WHATSAPP_COLUMNS,
  OFFICEHOURS_COLUMNS,
  CAMPAIGNBATCHTARGET_COLUMNS,
  CAMPAIGNBATCHTARGET_NOCHATS_COLUMNS,
  TARGET_COLUMNS,
} from "./columns";
import TableHeader from "./TableHeader.component";
import TableBody, { cellObject } from "./TableBody.component";
import IndeterminateCheckbox from "./IndeterminateCheckbox.component";
import TableButtonOptions from "./TableButtonOptions.component";
import SearchBox from "../SearchBox.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import {
  deleteContact,
  getSearchContacts,
  getSearchUsers,
  deleteUser,
  UserType,
  IUser,
  spamContact,
} from "../../../slices/users.slice";
import {
  deleteCustomer,
  getSearchCustomers,
  ICustomer,
} from "../../../slices/customers.slice";
import {
  deleteOfficehour,
  getSearchOfficehours,
  IOfficehour,
} from "../../../slices/officehours.slice";
import { deleteTeam, getSearchTeams, ITeam } from "../../../slices/teams.slice";
import ConfirmationModal from "../Modals/ConfirmationModal.component";
import PaginationComponent from "./Pagination.component";
import SelectPerPage from "./SelectPerPage.component";

import { ISettings } from "../../../slices/settings.slice";
import {
  ExternalAppType,
  IExternalAppAgidesk,
  IExternalAppWhatsApp,
  IExternalAppBotmaker,
  getSearchExternalApps,
  deleteExternalapps,
  IExternalAppVtex,
} from "../../../slices/externalapps.slice";
import LoadingDots from "../LoadingDots.component";
import findInObject from "../../utils/findInObject";
import logoonly from "../../../assets/images/logo-only-gray.png";
import {
  IHoliday,
  deleteHoliday,
  getSearchHolidays,
} from "../../../slices/holidays.slice";
import {
  IOfflinemessage,
  deleteOfflinemessage,
  getSearchOfflinemessages,
} from "../../../slices/offlinemessages.slice";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";
import {
  ISurvey,
  deleteSurvey,
  getSearchSurveys,
} from "../../../slices/surveys.slice";
import {
  ICampaign,
  ICampaignBatch,
  ITarget,
  deleteCampaign,
  getSearchCampaigns,
} from "../../../slices/campaigns.slice";
import {
  CampaignBatchStatus,
  deleteTargets,
} from "../../../slices/campaignbatches.slice";
import useCampaigns from "../../../hooks/features/useCampaigns";
import {
  inactiveContactChats,
  spamContactChats,
} from "../../../slices/chats.slice";
import {
  ICommenttemplate,
  deleteCommenttemplate,
  getSearchCommenttemplates,
} from "../../../slices/commenttemplates.slice";
import {
  IPermissionGroup,
  deletePermissiongroup,
  getSearchPermissiongroups,
} from "../../../slices/permissiongroups.slice";
import {
  ITag,
  TagType,
  deleteTag,
  getSearchTags,
} from "../../../slices/tags.slice";
import LinkButton from "../Buttons/LinkButton.component";
import RelationModal from "../Modals/RelationModal.component";

const TableComponent = ({
  tableId,
  keyword,
  isLoading,
  basicGrid,
  setKeyword,
  skip,
  setSkip,
  activePage,
  setActivePage,
  instancesPerPage,
  setInstancesPerPage,
  limit,
  setLimit,
  list,
  total,
  title,
  icon,
  setShowCards,
  showDeleted,
  setShowDeleted,
  showSpam,
  setShowSpam,
  setShowModal,
  reload,
  setReload,
  campaign,
}: {
  tableId?: string;
  keyword: string;
  isLoading: boolean;
  basicGrid?: boolean;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  skip: number;
  list:
    | IUser[]
    | ICustomer[]
    | ITeam[]
    | ISettings[]
    | IExternalAppWhatsApp[]
    | IExternalAppBotmaker[]
    | IExternalAppAgidesk[]
    | IExternalAppVtex[]
    | IOfficehour[]
    | IHoliday[]
    | IOfflinemessage[]
    | ISurvey[]
    | ICampaign[]
    | ITag[]
    | ICampaignBatch[]
    | ITarget[]
    | ICommenttemplate[]
    | IPermissionGroup[];
  total: number;
  title?: string;
  plus?: string;
  icon: string;
  setShowCards?: () => void;
  showDeleted?: boolean;
  setShowDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
  showSpam?: boolean;
  setShowSpam?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  instancesPerPage: number;
  setInstancesPerPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  reload?: boolean;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  campaign?: boolean;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const { target } = useCampaigns();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);
  const { user } = useAppSelector((state) => state.auth);
  const [validRows, setValidRows] = useState<Row<object>[]>([]);
  const [disable, setDisable] = useState(false);
  const [showDefaultagentModal, setShowDefaultagentModal] =
    useState<boolean>(false);
  const [showImport, setShowImport] = useState(false);

  const getColumns = () => {
    switch (icon) {
      case "las la-address-book":
        return CONTACTS_COLUMNS;
      case "lab la-whatsapp":
        return WHATSAPP_COLUMNS;
      case "las la-calendar":
        return OFFICEHOURS_COLUMNS;
      case "las la-user":
      case "las la-robot":
      case "las la-calendar-check":
      case "las la-calendar-times":
      case "las la-comment":
      case "las la-smile":
      case "las la-tags":
        return DEFAULT_WITH_TEAMS_COLUMNS;
      case "las la-bullhorn":
        const _list: ITarget[] = list;
        if (
          typeof _list[0]?.chat?.users !== "undefined" &&
          _list[0]?.chat?.users.length === 0
        ) {
          return CAMPAIGNBATCHTARGET_NOCHATS_COLUMNS;
        }
        return typeof tableId !== "undefined" &&
          tableId.indexOf("campaignbatchtargets") !== -1
          ? CAMPAIGNBATCHTARGET_COLUMNS
          : DEFAULT_COLUMNS;
      case "las la-bullseye":
        return TARGET_COLUMNS;
      default:
        return DEFAULT_COLUMNS;
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showSpamModal, setShowSpamModal] = useState<boolean>(false);

  const deletedItems = useRef<string[]>([]);

  const columns: readonly Column<object>[] = useMemo(
    () => getColumns(),
    [list]
  );
  const [selectedRow, setSelectedRow] = useState<cellObject[]>([]);
  const pageCount = Math.ceil(total / limit);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    gotoPage,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: list,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsHook) => {
        const tableKey =
          typeof tableId !== "undefined" ? tableId.split("-") : undefined;
        const _tableId =
          typeof tableKey !== "undefined" ? tableKey[0] : tableId;
        if (
          typeof _tableId !== "undefined" &&
          ["campaignbatchtargets"].includes(_tableId)
        )
          return [...columnsHook];
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => {
              const disableCondition =
                (!isAdmin && !isSupervisor) ||
                (isSupervisor && tableId !== "campaign-targets");
              // if (basicGrid === true) return (<></>);
              if (disableCondition) {
                return <input title="" type="checkbox" disabled />;
              }
              return (
                <IndeterminateCheckbox
                  {...getToggleAllRowsSelectedProps({
                    title: "Selecionar todos",
                  })}
                />
              );
            },
            Cell: ({ row }) => {
              const _row = row.original as cellObject;
              const disableCondition =
                _row?._id === user?._id ||
                _row?.default === true ||
                (!isAdmin && !isSupervisor) ||
                (isSupervisor && tableId !== "campaign-targets");
              // if (basicGrid === true) return (<></>)
              if (!disableCondition && !_row.deleted) {
                return (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps({
                      title: "Selecionar",
                    })}
                  />
                );
              }
              return <input title="" type="checkbox" disabled />;
            },
          },
          ...columnsHook,
        ];
      });
    }
  );

  const deleteSelection = async (_ids: string[]) => {
    switch (icon) {
      case "las la-user":
        const _users = selectedFlatRows.map((_user) => _user.original);
        if (
          selectedSettings?.defaultagent?._id &&
          typeof findInObject(
            _users,
            "_id",
            selectedSettings.defaultagent._id
          ) !== "undefined"
        ) {
          setShowDefaultagentModal(true);
          setDisable(false);
          break;
        }
        const actionUser = await dispatch(deleteUser(_ids));
        setDisable(false);
        if (
          actionUser.payload !== null &&
          typeof actionUser.payload !== "undefined" &&
          typeof actionUser.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchUsers({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        } else {
          setShowDefaultagentModal(true);
        }
        break;
      case "las la-building":
        const actionCustomer = await dispatch(deleteCustomer(_ids));
        setDisable(false);
        if (
          actionCustomer.payload !== null &&
          typeof actionCustomer.payload !== "undefined" &&
          typeof actionCustomer.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchCustomers({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-calendar-check":
        const actionOfficehour = await dispatch(deleteOfficehour(_ids));
        setDisable(false);
        if (
          actionOfficehour.payload !== null &&
          typeof actionOfficehour.payload !== "undefined" &&
          typeof actionOfficehour.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchOfficehours({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-tags":
        const actionChatTag = await dispatch(deleteTag(_ids));
        setDisable(false);
        if (
          actionChatTag.payload !== null &&
          typeof actionChatTag.payload !== "undefined" &&
          typeof actionChatTag.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchTags({
              skip,
              limit,
              filter: {
                content: "",
                type: [TagType.CHAT],
              },
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-user-tag":
        const actionContactTag = await dispatch(deleteTag(_ids));
        setDisable(false);
        if (
          actionContactTag.payload !== null &&
          typeof actionContactTag.payload !== "undefined" &&
          typeof actionContactTag.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchTags({
              skip,
              limit,
              filter: {
                content: "",
                type: [TagType.CONTACT],
              },
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-calendar-times":
        const actionHoliday = await dispatch(deleteHoliday(_ids));
        setDisable(false);
        if (
          actionHoliday.payload !== null &&
          typeof actionHoliday.payload !== "undefined" &&
          typeof actionHoliday.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchHolidays({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-hourglass-end":
        const actionOfflinemessage = await dispatch(deleteOfflinemessage(_ids));
        setDisable(false);
        if (
          actionOfflinemessage.payload !== null &&
          typeof actionOfflinemessage.payload !== "undefined" &&
          typeof actionOfflinemessage.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchOfflinemessages({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-smile":
        const actionSurvey = await dispatch(deleteSurvey(_ids));
        setDisable(false);
        if (
          actionSurvey.payload !== null &&
          typeof actionSurvey.payload !== "undefined" &&
          typeof actionSurvey.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchSurveys({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-comment":
        const actionCommenttemplate = await dispatch(
          deleteCommenttemplate(_ids)
        );
        setDisable(false);
        if (
          actionCommenttemplate.payload !== null &&
          typeof actionCommenttemplate.payload !== "undefined" &&
          typeof actionCommenttemplate.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchCommenttemplates({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-user-shield":
        const actionPermissiongroup = await dispatch(
          deletePermissiongroup(_ids)
        );
        setDisable(false);
        if (
          actionPermissiongroup.payload !== null &&
          typeof actionPermissiongroup.payload !== "undefined" &&
          typeof actionPermissiongroup.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchPermissiongroups({
              skip,
              limit,
              filter: "",
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "las la-bullseye":
        await target.removeTargets.mutateAsync(_ids);
        setActivePage(1);
        gotoPage(0);
        setSkip(0);
        setLimit(10);
        setInstancesPerPage(10);
        setKeyword("");
        break;
      case "las la-bullhorn":
        const tableKey =
          typeof tableId !== "undefined" ? tableId.split("-") : undefined;
        const _tableId =
          typeof tableKey !== "undefined" ? tableKey[0] : "campaign";
        const _id = typeof tableKey !== "undefined" ? tableKey[1] : undefined;

        const props = {
          campaign: {
            deleteAction: async (keys: string[]) => {
              await dispatch(deleteCampaign(keys));
            },
            reloadAction: async () => {
              await dispatch(
                getSearchCampaigns({
                  skip,
                  limit,
                  filter: "",
                  allStatus: true,
                  deleted: showDeleted || false,
                })
              );
            },
          },
          campaignbatchtargets: {
            deleteAction: async (keys: string[]) => {
              if (typeof _id !== "undefined") {
                await dispatch(
                  deleteTargets({ campaignbatch: _id, ids: keys })
                );
              }
            },
            reloadAction: () => {
              if (typeof setReload !== "undefined") setReload(true);
            },
          },
        };
        const { deleteAction } = props[_tableId as keyof typeof props];
        const { reloadAction } = props[_tableId as keyof typeof props];

        const actionCampaign: any =
          typeof deleteAction !== "undefined"
            ? await deleteAction(_ids)
            : undefined;
        setDisable(false);
        if (
          typeof actionCampaign !== "undefined" &&
          actionCampaign.payload !== null &&
          typeof actionCampaign.payload !== "undefined" &&
          typeof actionCampaign.payload?.message !== "string" &&
          typeof reloadAction !== "undefined"
        ) {
          await reloadAction();
        }
        break;
      case "las la-address-book":
        const actionContact = await dispatch(deleteContact(_ids));
        setDisable(false);
        if (
          actionContact.payload !== null &&
          typeof actionContact.payload !== "undefined" &&
          typeof actionContact.payload?.message !== "string"
        ) {
          await dispatch(inactiveContactChats(actionContact.payload));
          await dispatch(
            getSearchContacts({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
              spam: showSpam || false,
            })
          );
        }
        break;
      case "las la-user-friends":
        const actionTeam = await dispatch(deleteTeam(_ids));
        setDisable(false);
        if (
          actionTeam.payload !== null &&
          typeof actionTeam.payload !== "undefined" &&
          typeof actionTeam.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchTeams({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
            })
          );
        }
        break;
      case "lab la-whatsapp":
        const actionWhatsApp = await dispatch(deleteExternalapps(_ids));
        setDisable(false);
        if (
          actionWhatsApp.payload !== null &&
          typeof actionWhatsApp.payload !== "undefined" &&
          typeof actionWhatsApp.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchExternalApps({
              skip,
              limit,
              filter: {
                type: ExternalAppType.WHATSAPP,
                deleted: showDeleted ? true : undefined,
                active: false,
              },
            })
          );
        }
        break;
      case "las la-robot":
        const actionBotmaker = await dispatch(deleteExternalapps(_ids));
        setDisable(false);
        if (
          actionBotmaker.payload !== null &&
          typeof actionBotmaker.payload !== "undefined" &&
          typeof actionBotmaker.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchExternalApps({
              skip,
              limit,
              filter: {
                type: ExternalAppType.BOTMAKER,
                deleted: showDeleted ? true : undefined,
                active: false,
              },
            })
          );
        }
        break;
      case "las la-shopping-cart":
        const actionVtex = await dispatch(deleteExternalapps(_ids));
        setDisable(false);
        if (
          actionVtex.payload !== null &&
          typeof actionVtex.payload !== "undefined" &&
          typeof actionVtex.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchExternalApps({
              skip,
              limit,
              filter: {
                type: ExternalAppType.VTEX,
                deleted: showDeleted ? true : undefined,
                active: false,
              },
            })
          );
        }
        break;
      case "agidesk":
        const actionAgidesk = await dispatch(deleteExternalapps(_ids));
        setDisable(false);
        if (
          actionAgidesk.payload !== null &&
          typeof actionAgidesk.payload !== "undefined" &&
          typeof actionAgidesk.payload?.message !== "string"
        ) {
          await dispatch(
            getSearchExternalApps({
              skip,
              limit,
              filter: {
                type: ExternalAppType.AGIDESK,
                deleted: showDeleted ? true : undefined,
                active: false,
              },
            })
          );
        }
        break;
      default:
        break;
    }
    setDisable(false);
    setShowDeleteModal(false);
  };

  const spamSelection = async (_ids: string[]) => {
    switch (icon) {
      case "las la-address-book":
        const actionContact = await dispatch(
          spamContact({ ids: _ids, spam: true })
        );
        setDisable(false);
        if (
          actionContact.payload !== null &&
          typeof actionContact.payload !== "undefined" &&
          typeof actionContact.payload?.message !== "string"
        ) {
          await dispatch(spamContactChats(_ids));
          await dispatch(
            getSearchContacts({
              skip,
              limit,
              filter: "",
              allStatus: true,
              deleted: showDeleted || false,
              spam: showSpam || false,
            })
          );
        }
        break;
      default:
        break;
    }
    setDisable(false);
    setShowSpamModal(false);
  };

  useEffect(() => {
    const array: cellObject[] = [];
    const tempValidRows = selectedFlatRows.map((row) => {
      const original = row.original as cellObject;
      if (
        original?.status !== "SUCCESS" &&
        original?._id !== user?._id &&
        (typeof original?.default === "undefined" ||
          original.default === false) &&
        !original.deleted
      ) {
        return row;
      }
      return null;
    });
    const _validRows = tempValidRows.filter(
      (row) => row != null
    ) as Row<object>[];
    setValidRows(_validRows);

    JSON.stringify(
      {
        selectedFlatRows: validRows.map((row) => {
          const original = row?.original as cellObject;
          return array.push(original);
        }),
      },
      null,
      2
    );
    deletedItems.current = selectedFlatRows.map(
      (item: any) => item?.original?._id
    );
    setSelectedRow(array);
  }, [selectedFlatRows]);

  const getType = () => {
    switch (icon) {
      case "las la-user":
        return "user";
      case "las la-building":
        return "customer";
      case "las la-address-book":
        return "contact";
      case "las la-user-friends":
        return "team";
      case "lab la-whatsapp":
        return "whatsapp";
      case "agidesk":
        return "agidesk";
      case "las la-robot":
        return "botmaker";
      case "las la-shopping-cart":
        return "vtex";
      case "las la-calendar-check":
        return "officehour";
      case "las la-calendar-times":
        return "holiday";
      case "las la-tags":
        return "chattag";
      case "las la-user-tag":
        return "contacttag";
      case "las la-hourglass-end":
        return "offlinemessage";
      case "las la-comment":
        return "commenttemplate";
      case "las la-user-shield":
        return "permissiongroup";
      case "las la-smile":
        return "survey";
      case "las la-bullhorn":
        return typeof tableId !== "undefined" &&
          tableId.indexOf("campaignbatchtargets") !== -1
          ? "campaignbatch"
          : "campaign";
      case "las la-bullseye":
        return "target";
      default:
        return "";
    }
  };

  const getTableComponent = () => {
    return rows.length > 0 ? (
      <Table
        id="table"
        {...getTableProps({
          className: "p-0 m-0",
        })}
        selectable
        basic="very"
      >
        <TableHeader
          headerGroups={headerGroups}
          className={
            typeof setShowDeleted !== "undefined" && getType() === "target"
              ? "border-l-4 border-l-background"
              : ""
          }
        />
        <TableBody
          type={getType()}
          setShowModal={setShowModal}
          prepareRow={prepareRow}
          getTableBodyProps={getTableBodyProps}
          page={rows}
          selectedRow={selectedRow}
        />
      </Table>
    ) : (
      <div className="flex w-full h-full justify-center p-6">
        Nenhum resultado
      </div>
    );
  };

  const getTotalInfo = () => {
    if (isLoading) {
      return (
        <p className={campaign ? "mt-[10px] mb-[20px]" : "text-lg"}>
          Buscando resultados...
        </p>
      );
    }
    if (!isLoading && rows.length > 0) {
      return (
        <p className={campaign ? "mt-[10px] mb-[20px]" : "text-lg"}>
          <b>{total}</b> resultado(s)
        </p>
      );
    }
    return null;
  };

  return (
    <div
      className={`container mx-auto ${
        title && title.length > 0 && getType() !== "target" ? "p-0 sm:p-4" : ""
      }`}
    >
      {title && title.length > 0 ? (
        <div className="text-2xl font-medium items-center flex">
          {icon !== "agidesk" ? (
            <i className={`${icon} mr-4 text-3xl`} />
          ) : (
            <img className="mr-4 agidesk-logo" alt="Agidesk" src={logoonly} />
          )}
          <p>{title}</p>
        </div>
      ) : null}

      <div className="flex justify-between items-center">
        <SearchBox
          userPage
          campaign={campaign}
          _escFunction={() => {
            setSkip(0);
            setActivePage(1);
            setLimit(10);
            setInstancesPerPage(10);
            setKeyword("");
          }}
          keyword={keyword}
          listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSkip(0);
            setActivePage(1);
            setLimit(10);
            setInstancesPerPage(10);
            setKeyword(e.target.value);
          }}
        />

        <TableButtonOptions
          setSkip={setSkip}
          setLimit={setLimit}
          setShowCards={setShowCards}
          setShowModal={setShowModal}
          showDeleted={showDeleted}
          setShowDeleted={setShowDeleted}
          showSpam={showSpam}
          setShowSpam={setShowSpam}
          selectedFlatRows={validRows.length}
          setShowDeleteModal={
            campaign && selectedLocalCampaign?.deleted
              ? undefined
              : setShowDeleteModal
          }
          setShowSpamModal={setShowSpamModal}
          setActivePage={setActivePage}
          basicGrid={basicGrid}
        />
      </div>

      <div className="flex items-center justify-between mt-2">
        {getTotalInfo()}
        {(isAdmin || isSupervisor) &&
        location.pathname === "/settings/contacts" ? (
          <LinkButton
            extraClass={`!flex-1 !justify-end ${
              getTotalInfo() === null ? "mt-[2px]" : ""
            }`}
            // color
            leftIcon="las la-upload mr-1"
            label="Importar"
            onClick={() => {
              setShowImport(true);
            }}
          />
        ) : null}
      </div>

      {isLoading ? (
        <div className="flex w-full h-full justify-center p-6">
          <LoadingDots
            medium={campaign && !selectedLocalCampaign?.deleted}
            className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
            large={!campaign || selectedLocalCampaign?.deleted}
          />
        </div>
      ) : (
        getTableComponent()
      )}
      {!isLoading && rows.length > 0 ? (
        <div className="flex justify-between rounded-md">
          <SelectPerPage
            value={instancesPerPage}
            onChange={(e) => {
              setInstancesPerPage(+e.target.value);
              setSkip(0);
              setActivePage(1);
              setLimit(+e.target.value);
            }}
          />
          <PaginationComponent
            table
            totalPages={pageCount}
            activePage={activePage}
            onPageChange={(_, _data) => {
              if (_data.activePage) {
                setActivePage(+_data.activePage);
                gotoPage(+_data.activePage - 1);
                setSkip(limit * (+_data.activePage - 1));
              }
            }}
          />
        </div>
      ) : null}
      {showDeleteModal ? (
        <ConfirmationModal
          disabled={disable}
          action={async () => {
            setDisable(true);
            await deleteSelection(deletedItems.current);
          }}
          setShowModal={setShowDeleteModal}
          title="Deseja remover os items selecionados?"
        />
      ) : null}
      {showSpamModal ? (
        <ConfirmationModal
          disabled={disable}
          action={async () => {
            setDisable(true);
            await spamSelection(deletedItems.current);
          }}
          setShowModal={setShowSpamModal}
          title="Deseja marcar como SPAM os contatos selecionados?"
          content={
            deletedItems.current.length > 1
              ? "<label class='ml-12 text-[14px] font-normal'>As conversas destes contatos serão canceladas.</label>"
              : "<label class='ml-12 text-[14px] font-normal'>As conversas deste contato serão canceladas.</label>"
          }
        />
      ) : null}
      {showDefaultagentModal ? (
        <ConfirmationModal
          widgetType={UserType.NONE}
          disabled={false}
          title="Ação interrompida"
          content={`O agente <b>${selectedSettings?.defaultagent?.name}</b> está selecionado para ações anônimas e, assim, não pode ser excluído ou inativado.<br />
            Para alterar o usuário para ações anônimas, acesse <b>Configurações > Configurações gerais > Usuário para ações anônimas.</b>`}
          setShowModal={setShowDefaultagentModal}
          action={() => {
            setShowDefaultagentModal(false);
            navigate("/settings/general#settings-defaultagent");
          }}
          submitIcon="las la-cog"
          submitLabel="Ir para configurações"
        />
      ) : null}
      {showImport ? (
        <RelationModal
          icon="las la-upload"
          setShowModal={setShowImport}
          title="Importar contatos"
        />
      ) : null}
    </div>
  );
};

TableComponent.defaultProps = {
  title: "",
  setShowCards: undefined,
  tableId: undefined,
  basicGrid: undefined,
  showDeleted: false,
  setShowDeleted: undefined,
  showSpam: false,
  setShowSpam: undefined,
  setShowModal: undefined,
  plus: "",
  campaign: undefined,
  reload: undefined,
  setReload: undefined,
};

export default TableComponent;
