import axios, { AxiosError } from "axios";

axios.defaults.withCredentials = true;
const URL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.baseURL = URL;

// const instanceTenant = axios.create({
//   signal: new AbortController().signal,
//   baseURL: `${URL}/api/tenant`,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

const instanceAuth = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/auth`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceAuth.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    const originalRequest = config.url;
    let isPublicRoute = false;
    if (originalRequest) {
      isPublicRoute = [
        "/company",
        "/login",
        "/login-agidesk",
        // "/register",
        "/recovery",
        "/password",
      ].includes(originalRequest);
    }

    if (
      !isPublicRoute &&
      _header &&
      _header.common &&
      !_header.common["x-tenant-id"]
    ) {
      controller.abort();
      if (window.location.pathname !== "/company") {
        localStorage.clear();
        window.location.replace("/company");
      }
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const isPublicRoute =
      originalRequest?.url &&
      [
        "/company",
        "/login",
        "/login-agidesk",
        // "/register",
        "/recovery",
        "/password",
      ].includes(originalRequest.url);

    if (
      !isPublicRoute &&
      error.response.status >= 400 &&
      error.response.status !== 404
    ) {
      if (window.location.pathname !== "/company") {
        localStorage.clear();
        window.location.replace("/company");
      }
    }
    if (originalRequest?.url && ["/register"].includes(originalRequest.url))
      return error.response;
    return undefined;
  }
);

const instanceAgidesk = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/agidesk`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceAgidesk.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceAgidesk.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceFiles = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/files`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
instanceFiles.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceFiles.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceUsers = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/users`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceUsers.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceUsers.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceSurveys = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/survey`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceSurveys.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    let isPublicRoute = false;
    const originalRequest = config.url;
    if (originalRequest) {
      isPublicRoute =
        originalRequest.indexOf("/rating/?code") >= 0 ||
        (typeof config !== "undefined" &&
          typeof config.data?.code !== "undefined" &&
          originalRequest.indexOf("/rating") >= 0);
    }
    if (
      !isPublicRoute &&
      _header &&
      _header.common &&
      !_header.common["x-tenant-id"]
    ) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceSurveys.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceCustomers = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/customers`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceCustomers.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceCustomers.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceCampaigns = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/campaign`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceCampaigns.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceCampaigns.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceCampaignBatches = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/campaignbatch`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceCampaignBatches.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceCampaignBatches.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceSettings = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/settings`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceSettings.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceSettings.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    // console.log(error);
    if (
      error?.response?.status &&
      error?.response?.status >= 400 &&
      error.response.status !== 404
    ) {
      localStorage.clear();
      const _header = error.config.headers as any;
      if (!_header?.common.widget) window.location.replace("/company");
    }
    // return error.response;
  }
);

const instanceChat = axios.create({
  baseURL: `${URL}/api/chat`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceChat.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceChat.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    // return error.response;
  }
);

const instanceMessages = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/messages`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceMessages.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    if (config.url && config?.method === "get") {
      const _id = config.url.includes("/history")
        ? config.url.split("/")[0]
        : config.url;

      if (!window.location.pathname.includes(_id)) {
        controller.abort();
        window.location.replace("/chat");
        return null;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceMessages.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    // return error.response;
  }
);

const instanceTeams = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/teams`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceTeams.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceTeams.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceWidget = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/widget`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceWidget.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceWidget.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/");
    }
    // return error.response;
  }
);

const instanceWhatsapp = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/whatsapp`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceWhatsapp.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceWhatsapp.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceExternalapps = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/externalapps`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceExternalapps.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceExternalapps.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceNotification = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/notification`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceNotification.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceNotification.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceDashcard = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/dashcard`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceDashcard.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceDashcard.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceBotmaker = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/botmaker`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceBotmaker.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceBotmaker.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);
const instanceVtex = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/vtex`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceVtex.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceVtex.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceTags = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/tag`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceTags.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceTags.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceOfficehours = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/officehour`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceOfficehours.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceOfficehours.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceHolidays = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/holiday`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceHolidays.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceHolidays.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceOfflinemessages = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/offlinemessage`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceOfflinemessages.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceOfflinemessages.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      (error.response.status >= 401 && error.response.status !== 404) ||
      error.response.message === "x-tenant-id is not supplied"
    ) {
      localStorage.clear();
      window.location.replace("/company");
    }
    return error.response;
  }
);

const instanceAppKey = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/appkey`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceAppKey.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceAppKey.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    // console.log(error);
    if (
      error?.response?.status &&
      error?.response?.status >= 400 &&
      error.response.status !== 404
    ) {
      localStorage.clear();
      const _header = error.config.headers as any;
      if (!_header?.common.widget) window.location.replace("/company");
    }
    // return error.response;
  }
);

const instanceCommenttemplates = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/commenttemplate`,
  headers: {
    "Content-Type": "application/json",
  },
});
instanceCommenttemplates.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instanceCommenttemplates.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    // console.log(error);
    if (
      error?.response?.status &&
      error?.response?.status >= 400 &&
      error.response.status !== 404
    ) {
      localStorage.clear();
      const _header = error.config.headers as any;
      if (!_header?.common.widget) window.location.replace("/company");
    }
    // return error.response;
  }
);

const instancePermissionGroups = axios.create({
  signal: new AbortController().signal,
  baseURL: `${URL}/api/permissiongroup`,
  headers: {
    "Content-Type": "application/json",
  },
});
instancePermissionGroups.interceptors.request.use(
  (config) => {
    const controller = new AbortController();
    config.signal = controller.signal;
    const _header = config.headers as any;
    if (_header && _header.common && !_header.common["x-tenant-id"]) {
      controller.abort();
      localStorage.clear();
      window.location.replace("/company");
      return null;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instancePermissionGroups.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    console.log(error);
    if (
      error?.response?.status &&
      error?.response?.status >= 400 &&
      error.response.status !== 404
    ) {
      localStorage.clear();
      const _header = error.config.headers as any;
      if (!_header?.common.widget) window.location.replace("/company");
    }
    // return error.response;
  }
);

export const updateTenant = (tenant: string, widget?: string) => {
  instanceExternalapps.defaults.headers.common["x-tenant-id"] = tenant;
  instanceWidget.defaults.headers.common["x-tenant-id"] = tenant;
  instanceAuth.defaults.headers.common["x-tenant-id"] = tenant;
  instanceFiles.defaults.headers.common["x-tenant-id"] = tenant;
  instanceUsers.defaults.headers.common["x-tenant-id"] = tenant;
  instanceCustomers.defaults.headers.common["x-tenant-id"] = tenant;
  instanceSettings.defaults.headers.common["x-tenant-id"] = tenant;
  instanceChat.defaults.headers.common["x-tenant-id"] = tenant;
  instanceMessages.defaults.headers.common["x-tenant-id"] = tenant;
  instanceTeams.defaults.headers.common["x-tenant-id"] = tenant;
  instanceWhatsapp.defaults.headers.common["x-tenant-id"] = tenant;
  instanceAgidesk.defaults.headers.common["x-tenant-id"] = tenant;
  instanceNotification.defaults.headers.common["x-tenant-id"] = tenant;
  instanceDashcard.defaults.headers.common["x-tenant-id"] = tenant;
  instanceBotmaker.defaults.headers.common["x-tenant-id"] = tenant;
  instanceVtex.defaults.headers.common["x-tenant-id"] = tenant;
  instanceSurveys.defaults.headers.common["x-tenant-id"] = tenant;
  instanceOfficehours.defaults.headers.common["x-tenant-id"] = tenant;
  instanceHolidays.defaults.headers.common["x-tenant-id"] = tenant;
  instanceOfflinemessages.defaults.headers.common["x-tenant-id"] = tenant;
  instanceTags.defaults.headers.common["x-tenant-id"] = tenant;
  instanceCampaigns.defaults.headers.common["x-tenant-id"] = tenant;
  instanceCampaignBatches.defaults.headers.common["x-tenant-id"] = tenant;
  instanceAppKey.defaults.headers.common["x-tenant-id"] = tenant;
  instanceCommenttemplates.defaults.headers.common["x-tenant-id"] = tenant;
  instancePermissionGroups.defaults.headers.common["x-tenant-id"] = tenant;
  if (typeof widget !== "undefined" && typeof widget === "string" && widget) {
    instanceExternalapps.defaults.headers.common.widget = widget;
    instanceWidget.defaults.headers.common.widget = widget;
    instanceAuth.defaults.headers.common.widget = widget;
    instanceFiles.defaults.headers.common.widget = widget;
    instanceUsers.defaults.headers.common.widget = widget;
    instanceCustomers.defaults.headers.common.widget = widget;
    instanceSettings.defaults.headers.common.widget = widget;
    instanceChat.defaults.headers.common.widget = widget;
    instanceMessages.defaults.headers.common.widget = widget;
    instanceTeams.defaults.headers.common.widget = widget;
    instanceWhatsapp.defaults.headers.common.widget = widget;
    instanceAgidesk.defaults.headers.common.widget = widget;
    instanceNotification.defaults.headers.common.widget = widget;
    instanceDashcard.defaults.headers.common.widget = widget;
    instanceBotmaker.defaults.headers.common.widget = widget;
    instanceVtex.defaults.headers.common.widget = widget;
    instanceSurveys.defaults.headers.common.widget = widget;
    instanceOfficehours.defaults.headers.common.widget = widget;
    instanceHolidays.defaults.headers.common.widget = widget;
    instanceOfflinemessages.defaults.headers.common.widget = widget;
    instanceTags.defaults.headers.common.widget = widget;
    instanceCampaigns.defaults.headers.common.widget = widget;
    instanceCampaignBatches.defaults.headers.common.widget = widget;
    instanceAppKey.defaults.headers.common.widget = widget;
    instanceCommenttemplates.defaults.headers.common.widget = widget;
    instancePermissionGroups.defaults.headers.common.widget = widget;
  }
};
export const updateTenantForgot = (tenant: string, widget?: string) => {
  instanceAuth.defaults.headers.common["x-tenant-id"] = tenant;
  if (typeof widget !== "undefined" && typeof widget === "string" && widget) {
    instanceAuth.defaults.headers.common.widget = widget;
  }
};

const instances = {
  instanceAgidesk,
  instanceAuth,
  instanceChat,
  instanceCustomers,
  // instanceDashboard,
  instanceDashcard,
  instanceExternalapps,
  instanceFiles,
  instanceMessages,
  instanceNotification,
  instanceSettings,
  // instanceTenant,
  instanceTeams,
  instanceUsers,
  instanceWidget,
  instanceWhatsapp,
  instanceBotmaker,
  instanceVtex,
  instanceSurveys,
  instanceOfficehours,
  instanceHolidays,
  instanceOfflinemessages,
  instanceCampaigns,
  instanceCampaignBatches,
  instanceTags,
  instanceAppKey,
  instanceCommenttemplates,
  instancePermissionGroups,
};
export default instances;
