/* eslint-disable no-restricted-syntax */
import { Files } from "../shared/models/interfaces/file.interface";
import {
  ILoginAgideskUser,
  ILoginUser,
  IUser,
  RegisterUser,
  UserStatus,
  RoleUser
} from "../slices/users.slice";
import instances from "./instances";
import { RoleUserPermissions } from "../slices/permissiongroups.slice";

const signin = async () => {
  try {
    const response = await instances.instanceAuth.post("/signin");
    return response.data;
  } catch (error) {
    return null;
  }
};

const userdata = async (): Promise<IUser | null> => {
  try {
    const response = await instances.instanceUsers.get("/userdata");
    const permissions = []

    // TODO: Temporary solution for tests
    for (let i = 0; i < response.data?.roles.length; i++) {
      const role = response.data?.roles[i] as keyof typeof RoleUserPermissions;
      permissions.push(...RoleUserPermissions[role])
    }
    return {
      ...response.data,
      permissions,
    };
  } catch (error) {
    return null;
  }
};

const changeStatus = async ({
  status,
  origin,
  manual,
}: {
  status: UserStatus;
  origin?: string;
  manual?: boolean;
}): Promise<IUser | null> => {
  try {
    let data = null;
    if (origin) {
      data = { status, origin };
      if (manual) {
        data = { status, origin, manual };
      }
    } else {
      data = { status };
      if (manual) {
        data = { status, manual };
      }
    }
    const response = await instances.instanceUsers.patch("/changestatus", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const changePassword = async (user: ILoginUser): Promise<IUser | null> => {
  try {
    const response = await instances.instanceAuth.post("/change", user);
    if ([200, 201].includes(response.status)) {
      return await userdata();
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const recoveryPassword = async ({
  code,
  password,
}: {
  code: string;
  password: string;
}): Promise<IUser | null> => {
  try {
    const data = { code, password };
    const response = await instances.instanceAuth.post("/recovery", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const forgotPassword = async ({
  email,
  tenant,
}: {
  email: string;
  tenant: string;
}) => {
  try {
    const data = { email, tenant };
    const response = await instances.instanceAuth.post("/forgot", data);
    if (response.status === 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const login = async (user: ILoginUser) => {
  try {
    const response = await instances.instanceAuth.post("/login", user);
    if ([200, 201].includes(response.status)) {
      return await userdata();
    }
    return null;
  } catch (error) {
    return null;
  }
};

const agideskLogin = async (user: ILoginAgideskUser) => {
  try {
    const response = await instances.instanceAgidesk.post("/login", user);
    if ([200, 201].includes(response.status)) {
      const responseUserStatus = await changeStatus({
        status: UserStatus.ONLINE,
      });
      if (responseUserStatus?._id) {
        return await userdata();
      }
      return responseUserStatus;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const update = async (_user: any): Promise<IUser | null> => {
  try {
    let updateUser = _user;
    if (
      typeof updateUser !== "undefined" &&
      typeof updateUser?.phone !== "undefined"
    ) {
      updateUser = {
        ...updateUser,
        phone: updateUser?.phone?.replace(/[^0-9.]/g, ""),
      };
    }
    const data = updateUser;
    const response = await instances.instanceUsers.patch(`${data._id}`, data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const register = async (
  registerUser: RegisterUser
): Promise<IUser | string | null> => {
  try {
    const response = await instances.instanceAuth.post(
      "/register",
      registerUser
    );
    if (response && response.data && !response.data.errorMessage) {
      if (response.data._id) {
        const data = {
          tenant: registerUser.tenant,
          email: registerUser.email,
          password: registerUser.password,
        };
        const responseLogin = await login(data);
        if (responseLogin?._id) {
          const updatedLogin = await update({
            ...responseLogin,
            onboarding: "completed",
          });
          return updatedLogin;
        }
        return responseLogin;
      }
      return null;
    }
    return response.data.errorMessage;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const registerFromAgidesk = async (
  registerUser: RegisterUser
): Promise<IUser | null> => {
  try {
    const response = await instances.instanceAuth.post(
      "/register",
      registerUser
    );
    if (response.data._id) {
      const data = {
        tenant: registerUser.tenant,
        email: registerUser.email,
        password: registerUser.password,
      };
      await login(data);
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const logout = async (): Promise<boolean | null> => {
  try {
    await instances.instanceAuth.post("/logout");
    return true;
  } catch (error) {
    return null;
    // console.log(error);
  }
};

const refresh = async () => {
  try {
    const response = await instances.instanceAuth.get("/refresh");
    return response;
  } catch (error) {
    return null;
  }
};

const uploadFile = async (
  _files: File[],
  external?: boolean,
  audio?: boolean,
  botmaker?: boolean
): Promise<Files | string | null> => {
  try {
    const formData = new FormData();
    for (const _file of _files) {
      formData.append("files", _file);
    }
    if (typeof external !== "undefined") {
      formData.append("external", "true");
    }
    if (typeof audio !== "undefined" && audio) {
      formData.append("audio", "true");
    }
    if (typeof botmaker !== "undefined" && botmaker) {
      formData.append("type", "botmaker");
    }
    const response = await instances.instanceFiles.post("", formData);
    if (response && response.data && !response.data.message) {
      if (response.data.length > 0) {
        return response.data;
      }
      return null;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const uploadFileWhatsApp = async ({
  _files,
  externalapp,
  audio,
}: {
  _files: File[];
  externalapp: string | undefined;
  audio?: boolean;
}): Promise<Files | string | null> => {
  try {
    const formData = new FormData();
    for (const _file of _files) {
      // console.log(_file);
      formData.append("files", _file);
    }
    if (typeof externalapp !== "undefined") {
      formData.append("externalapp", externalapp);
    }
    if (typeof audio !== "undefined" && audio) {
      formData.append("audio", "true");
    }
    formData.append("type", "whatsapp");
    const response = await instances.instanceFiles.post("", formData);
    if (response && response.data && !response.data.message) {
      if (response.data.length > 0) {
        return response.data;
      }
      return null;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const authService = {
  signin,
  register,
  registerFromAgidesk,
  login,
  agideskLogin,
  logout,
  refresh,
  userdata,
  changeStatus,
  recoveryPassword,
  changePassword,
  forgotPassword,
  uploadFile,
  uploadFileWhatsApp,
  update,
};

export default authService;
