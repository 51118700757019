import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import Dropdown from "../../../shared/components/Dropdown.component";
import useCampaigns from "../../../hooks/features/useCampaigns";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";
import { useAppSelector } from "../../../hooks/redux/hooks";
import Toggle from "../../../shared/components/Toggle.component";

const Chat = () => {
  const { dropdown, effect, chat } = useCampaigns();
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);

  useEffect(() => {
    effect.chat();
  }, [effect.chat]);

  return (
    <Form>
      <Form.Group widths="equal">
        <Toggle
          containerClass="flex w-full"
          checked={chat.checked}
          mainClass={`items-center flex ${chat.checked ? "" : "mb-4"}`}
          onChange={(_, { checked }) => {
            chat.onChange({ checked: checked || false });
          }}
          labelClass="ml-4"
          label="Criar conversas de campanha"
        />
      </Form.Group>
      {chat.checked ? (
        <Form.Group widths="equal">
          <Dropdown
            id="dropdown-campaign-team"
            required
            fluid
            search
            selection
            options={dropdown.options.team}
            disabled={
              selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor)
            }
            value={dropdown.value.team}
            label="Equipe"
            placeholder="Equipe"
            onChange={async (e, { value }) => {
              dropdown.onChange.team(e, { value });
            }}
          />
          <Dropdown
            id="dropdown-campaign-user"
            loading={dropdown.isLoading.user}
            // required
            fluid
            clearable
            search
            selection
            disabled={
              dropdown.disabled.user ||
              selectedLocalCampaign?.deleted ||
              (!isAdmin && !isSupervisor)
            }
            options={dropdown.options.user}
            value={dropdown.value.user}
            label="Agente"
            placeholder="Agente"
            onFocus={dropdown.onFocus.user}
            onOpen={dropdown.onOpen.user}
            onBlur={dropdown.onBlur}
            onChange={(e, { value }) => {
              dropdown.onChange.user(e, { value });
            }}
          />
        </Form.Group>
      ) : null}
    </Form>
  );
};

export default Chat;
