import React from "react";
import { Divider, DropdownSearchInputProps, Form } from "semantic-ui-react";
import Dropdown from "../../../shared/components/Dropdown.component";
import Button from "../../../shared/components/Buttons/Button.component";
import FilesArea from "../../../shared/components/Files/FilesArea.component";
import importModel from "../../../assets/spreadsheets/modelo_importacao.xlsx";
import LinkButton from "../../../shared/components/Buttons/LinkButton.component";
import TableComponent from "../../../shared/components/Tables/Table.component";
import PopupComponent from "../../../shared/components/Popup.component";
import useCampaigns from "../../../hooks/features/useCampaigns";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";
import { useAppSelector } from "../../../hooks/redux/hooks";

const Target = () => {
  const { dropdown, target, table } = useCampaigns();
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);
  return (
    <Form>
      {/* <Form.Group id="campaign-form" widths="equal">
        <Dropdown
          id="dropdown-campaign-import-customer"
          loading={dropdown.isLoading.customer}
          fluid
          search
          selection
          label="Cliente"
          placeholder="Cliente"
          clearable
          disabled={selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor)}
          options={dropdown.options.customer}
          value={dropdown.value.customer}
          onOpen={dropdown.onOpen.customer}
          onFocus={dropdown.onFocus.customer}
          onBlur={dropdown.onBlur}
          onSearchChange={(e: DropdownSearchInputProps) => {
            dropdown.onSearch.customer(e.target.value);
          }}
          onChange={async (e, { value }) => {
            dropdown.onChange.customer(e, { value });
          }}
        />
        <Dropdown
          id="dropdown-campaign-import-tag"
          loading={dropdown.isLoading.tag}
          fluid
          search
          selection
          label="Marcador"
          placeholder="Marcador"
          clearable
          disabled={selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor)}
          options={dropdown.options.tag}
          value={dropdown.value.tag}
          onOpen={dropdown.onOpen.tag}
          onFocus={dropdown.onFocus.tag}
          onBlur={dropdown.onBlur}
          onSearchChange={(e: DropdownSearchInputProps) => {
            dropdown.onSearch.tag(e.target.value);
          }}
          onChange={async (e, { value }) => {
            dropdown.onChange.tag(e, { value });
          }}
        />
        <Dropdown
          id="dropdown-campaign-import-contact"
          loading={dropdown.isLoading.contact}
          required
          fluid
          search
          selection
          multiple
          label="Contato"
          placeholder="Contato"
          disabled={selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor)}
          options={dropdown.options.contact}
          value={dropdown.value.contact}
          onOpen={dropdown.onOpen.contact}
          onFocus={dropdown.onFocus.contact}
          onBlur={dropdown.onBlur}
          onSearchChange={(e: DropdownSearchInputProps) => {
            dropdown.onSearch.contact(e.target.value);
          }}
          onChange={(e, { value }) => {
            dropdown.onChange.contact(e, { value });
          }}
        />
      </Form.Group> */}
      <Form.Group id="campaign-form-customer-tag" widths="equal">
        <Dropdown
          id="dropdown-campaign-import-customer"
          loading={dropdown.isLoading.customer}
          fluid
          search
          selection
          label="Cliente"
          placeholder="Cliente"
          clearable
          disabled={
            selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor)
          }
          options={dropdown.options.customer}
          value={dropdown.value.customer}
          onOpen={dropdown.onOpen.customer}
          onFocus={dropdown.onFocus.customer}
          onBlur={dropdown.onBlur}
          onSearchChange={(e: DropdownSearchInputProps) => {
            dropdown.onSearch.customer(e.target.value);
          }}
          onChange={async (e, { value }) => {
            dropdown.onChange.customer(e, { value });
          }}
        />
        <Dropdown
          id="dropdown-campaign-import-tag"
          loading={dropdown.isLoading.tag}
          fluid
          search
          selection
          label="Marcador"
          placeholder="Marcador"
          clearable
          disabled={
            selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor)
          }
          options={dropdown.options.tag}
          value={dropdown.value.tag}
          onOpen={dropdown.onOpen.tag}
          onFocus={dropdown.onFocus.tag}
          onBlur={dropdown.onBlur}
          onSearchChange={(e: DropdownSearchInputProps) => {
            dropdown.onSearch.tag(e.target.value);
          }}
          onChange={async (e, { value }) => {
            dropdown.onChange.tag(e, { value });
          }}
        />
      </Form.Group>
      <Form.Group id="campaign-form-contact" widths="equal">
        <Dropdown
          id="dropdown-campaign-import-contact"
          loading={dropdown.isLoading.contact}
          required
          fluid
          search
          selection
          multiple
          label="Contato"
          placeholder="Contato"
          disabled={
            selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor)
          }
          options={dropdown.options.contact}
          value={dropdown.value.contact}
          onOpen={dropdown.onOpen.contact}
          onFocus={dropdown.onFocus.contact}
          onBlur={dropdown.onBlur}
          onSearchChange={(e: DropdownSearchInputProps) => {
            dropdown.onSearch.contact(e.target.value);
          }}
          onChange={(e, { value }) => {
            dropdown.onChange.contact(e, { value });
          }}
        />
      </Form.Group>
      <Button
        inverted
        minWidth
        icon="las la-plus"
        label="Adicionar"
        onClick={target.addTargets.mutateAsync}
        disabled={
          selectedLocalCampaign?.deleted ||
          (!isAdmin && !isSupervisor) ||
          dropdown.value.contact.length === 0
        }
      />
      <Divider horizontal className="!text-agitalks !text-[10px]">
        OU VIA IMPORTAÇÃO
      </Divider>
      <Form.Field
        required
        className="flex flex-col cursor-normal w-full md:w-full"
      >
        <PopupComponent
          className="flex items-center mb-1"
          label="Baixe o modelo de arquivo para importação"
          required
          content={
            <h1 className="font-semibold text-[12px]">
              Colunas obrigatórias na planilha: Nome e telefone
            </h1>
          }
        />
        <a
          href={importModel}
          target="_blank"
          download="modelo_importacao.xlsx"
          rel="noreferrer"
        >
          <LinkButton
            label="Modelo de arquivo para importação"
            onClick={() => null}
          />
        </a>
      </Form.Field>
      <Form.Field
        required
        className="flex flex-col items-center cursor-normal w-full md:w-full"
      >
        <FilesArea
          extraClass="w-full"
          single
          importAction
          files={target.file}
          setFiles={target.setFile}
        />
      </Form.Field>
      <Button
        minWidth
        inverted
        icon="las la-upload"
        label="Importar"
        onClick={target.addTargets.mutateAsync}
        disabled={
          target.disabled ||
          selectedLocalCampaign?.deleted ||
          (!isAdmin && !isSupervisor)
        }
      />
      <div className="my-4">
        <PopupComponent
          className="flex items-center"
          label={table.title}
          content={
            <h1 className="font-semibold text-[12px]">
              Confira o limite de mensagens na sua conta META
            </h1>
          }
        />
        {/* <div className="items-center flex">
          <label>{table.title}</label>
        </div> */}
        <TableComponent
          campaign
          tableId="campaign-targets"
          keyword={table.keyword}
          isLoading={table.isLoading}
          setKeyword={table.setKeyword}
          skip={table.skip}
          setSkip={table.setSkip}
          activePage={table.activePage}
          setActivePage={table.setActivePage}
          instancesPerPage={table.instancesPerPage}
          setInstancesPerPage={table.setInstancesPerPage}
          limit={table.limit}
          setLimit={table.setLimit}
          total={table.total}
          list={table.list}
          icon={table.icon}
        />
      </div>
    </Form>
  );
};

export default Target;
