import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import GridButtonOptions from "./GridButtonOptions.component";
import SearchBox from "../SearchBox.component";
import { ICustomer } from "../../../slices/customers.slice";
import { IUser, Users } from "../../../slices/users.slice";
import { ITeam } from "../../../slices/teams.slice";
import { ISettings } from "../../../slices/settings.slice";
import useOnScreen from "../../../hooks/useOnScreen";
import LoadingGridCard from "./LoadingGridCard.component";
import {
  IExternalAppAgidesk,
  IExternalAppWhatsApp,
  IExternalAppBotmaker,
  IExternalAppVtex,
} from "../../../slices/externalapps.slice";
import logoonly from "../../../assets/images/logo-only-gray.png";
import CustomCard from "../CustomCard.component";
import { useAppSelector } from "../../../hooks/redux/hooks";
import LoadingDots from "../LoadingDots.component";
import { IOfficehour } from "../../../slices/officehours.slice";
import { IHoliday } from "../../../slices/holidays.slice";
import { IOfflinemessage } from "../../../slices/offlinemessages.slice";
import { ISurvey } from "../../../slices/surveys.slice";
import { ICampaign } from "../../../slices/campaigns.slice";
import { ICommenttemplate } from "../../../slices/commenttemplates.slice";
import { IPermissionGroup } from "../../../slices/permissiongroups.slice";
import { ITag, TagType } from "../../../slices/tags.slice";
import LinkButton from "../Buttons/LinkButton.component";
import RelationModal from "../Modals/RelationModal.component";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";

const Grid = ({
  keyword,
  setKeyword,
  setSkip,
  setLimit,
  list,
  total,
  title,
  icon,
  setShowCards,
  showDeleted,
  setShowDeleted,
  showSpam,
  setShowSpam,
  setShowModal,
  hasMore,
  setHasMore,
  isLoading,
}: {
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  // skip: number;
  list:
    | Users
    | ICustomer[]
    | ITeam[]
    | ISettings[]
    | IExternalAppWhatsApp[]
    | IExternalAppAgidesk[]
    | IExternalAppBotmaker[]
    | IExternalAppVtex[]
    | IOfficehour[]
    | IHoliday[]
    | IOfflinemessage[]
    | ISurvey[]
    | ITag[]
    | ICampaign[]
    | ICommenttemplate[]
    | IPermissionGroup[];
  total: number;
  title: string;
  icon: string;
  setShowCards: () => void;
  showDeleted: boolean;
  setShowDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  showSpam: boolean;
  setShowSpam: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const { user } = useAppSelector((state) => state.auth);
  const [showImport, setShowImport] = useState(false);
  const location = useLocation();
  const [listRef] = useOnScreen({
    hasMore,
    isLoading,
    setSkip,
    grid: true,
  });

  const getType = (
    item:
      | null
      | IUser
      | ICustomer
      | ITeam
      | ISettings
      | IExternalAppWhatsApp
      | IExternalAppAgidesk
      | IExternalAppBotmaker
      | IExternalAppVtex
      | IOfficehour
      | IHoliday
      | IOfflinemessage
      | ISurvey
      | ITag
      | ICampaign
      | ICommenttemplate
      | IPermissionGroup
  ) => {
    if (item === null) {
      return <LoadingGridCard />;
    }
    let _item = null;
    let _teams: ITeam[] = [];
    switch (icon) {
      case "las la-user":
        _item = item as IUser;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              avatar: {
                name: _item?.avatar?.name,
                path: _item?.avatar?.path,
              },
              deleted: _item?.deleted,
              roles: _item?.roles,
              email: _item?.email,
              phone: _item?.phone,
              team:
                typeof _item?.teams !== "undefined" &&
                _item?.teams?.length > 0 &&
                typeof _item.teams[0]?.name !== "undefined"
                  ? `${_item?.teams[0]?.name}${
                      _item.teams.length > 1
                        ? ` (e mais ${_item.teams.length - 1})`
                        : ""
                    }`
                  : undefined,
            }}
            instance
            url={
              _item?._id !== user?._id
                ? `/settings/user/${_item?._id}`
                : "/settings/profile"
            }
          />
        );
      case "las la-address-book":
        _item = item as IUser;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              avatar: {
                name: _item?.avatar?.name,
                path: _item?.avatar?.path,
              },
              customer:
                typeof _item?.customers !== "undefined" &&
                _item?.customers?.length > 0 &&
                typeof _item.customers[0]?.name !== "undefined"
                  ? `${_item?.customers[0]?.name}${
                      _item.customers.length > 1
                        ? ` (e mais ${_item.customers.length - 1})`
                        : ""
                    }`
                  : undefined,
              deleted: _item?.deleted,
              roles: _item?.roles,
              email: _item?.email,
              phone: _item?.phone,
            }}
            instance
            url={
              _item?._id !== user?._id
                ? `/settings/contact/${item?._id}`
                : "/settings/profile"
            }
          />
        );
      case "las la-building":
        _item = item as ICustomer;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/customer/${_item?._id}`}
          />
        );
      case "las la-user-friends":
        _item = item as ITeam;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/team/${_item?._id}`}
          />
        );
      case "lab la-whatsapp":
        _item = item as IExternalAppWhatsApp;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.fields?.name,
              active: _item?.active,
              phone: _item?.fields?.displayphone,
              deleted: _item?.deleted,
              team:
                typeof _item?.fields?.defaultteam !== "undefined" &&
                _item?.fields?.defaultteam?.length > 0 &&
                typeof _item.fields?.defaultteam[0]?.name !== "undefined"
                  ? `${_item?.fields?.defaultteam[0]?.name}${
                      _item.fields?.defaultteam.length > 1
                        ? ` (e mais ${_item.fields.defaultteam.length - 1})`
                        : ""
                    }`
                  : undefined,
            }}
            instance
            url={`/settings/integration/whatsapp/${_item?._id}`}
          />
        );
      case "agidesk":
        _item = item as IExternalAppAgidesk;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.fields?.name,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/integration/agidesk/${_item?._id}`}
          />
        );
      case "las la-robot":
        _item = item as IExternalAppBotmaker;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.fields?.name,
              active: _item?.active,
              deleted: _item?.deleted,
              team:
                typeof _item?.fields?.defaultteam !== "undefined" &&
                _item?.fields?.defaultteam?.length > 0 &&
                typeof _item.fields?.defaultteam[0]?.name !== "undefined"
                  ? `${_item?.fields?.defaultteam[0]?.name}${
                      _item.fields?.defaultteam.length > 1
                        ? ` (e mais ${_item.fields.defaultteam.length - 1})`
                        : ""
                    }`
                  : undefined,
              // phone: _item?.fields?.displayphone,
            }}
            instance
            url={`/settings/integration/agibot/${_item?._id}`}
          />
        );
      case "las la-shopping-cart":
        _item = item as IExternalAppVtex;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.fields?.name,
              active: _item?.active,
              deleted: _item?.deleted,
              // phone: _item?.fields?.displayphone,
            }}
            instance
            url={`/settings/integration/vtex/${_item?._id}`}
          />
        );
      case "las la-calendar-check":
        _item = item as IOfficehour;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              team:
                typeof _item?.teams !== "undefined" &&
                _item?.teams?.length > 0 &&
                typeof _item.teams[0]?.name !== "undefined"
                  ? `${_item?.teams[0]?.name}${
                      _item.teams.length > 1
                        ? ` (e mais ${_item.teams.length - 1})`
                        : ""
                    }`
                  : undefined,
              default: _item?.default,
            }}
            instance
            url={`/settings/officehour/${_item?._id}`}
          />
        );
      case "las la-calendar-times":
        _item = item as IHoliday;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              team:
                typeof _item?.teams !== "undefined" &&
                _item?.teams?.length > 0 &&
                typeof _item.teams[0]?.name !== "undefined"
                  ? `${_item?.teams[0]?.name}${
                      _item.teams.length > 1
                        ? ` (e mais ${_item.teams.length - 1})`
                        : ""
                    }`
                  : undefined,
            }}
            instance
            url={`/settings/holiday/${_item?._id}`}
            // setShowModal={setShowModal}
          />
        );
      case "las la-tags":
      case "las la-user-tag":
        _item = item as ITag;
        _teams = _item.teams as ITeam[];
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              team:
                typeof _teams !== "undefined" &&
                _teams?.length > 0 &&
                typeof _teams[0]?.name !== "undefined"
                  ? `${_teams[0]?.name}${
                      _teams.length > 1 ? ` (e mais ${_teams.length - 1})` : ""
                    }`
                  : undefined,
            }}
            instance
            url={
              _item.type === TagType.CHAT
                ? `/settings/tags/chattag/${_item?._id}`
                : `/settings/tags/contacttag/${_item?._id}`
            }
            // setShowModal={setShowModal}
          />
        );
      case "las la-hourglass-end":
        _item = item as IOfflinemessage;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/offlinemessage/${_item?._id}`}
            // setShowModal={setShowModal}
          />
        );
      case "las la-smile":
        _item = item as ISurvey;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              default: _item?.default,
            }}
            instance
            url={`/settings/survey/${_item?._id}`}
          />
        );
      case "las la-bullhorn":
        _item = item as ICampaign;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/campaign/${_item?._id}`}
          />
        );
      case "las la-comment":
        _item = item as ICommenttemplate;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
            }}
            instance
            url={`/settings/commenttemplate/${_item?._id}`}
          />
        );
      case "las la-user-shield":
        _item = item as IPermissionGroup;
        return (
          <CustomCard
            element={{
              _id: _item?._id,
              name: _item?.name as string,
              active: _item?.active,
              deleted: _item?.deleted,
              default: _item?.default,
            }}
            instance
            url={`/settings/permissiongroup/${_item?._id}`}
          />
        );
      default:
        return <LoadingGridCard />;
    }
  };

  const getTotalInfo = () => {
    if (isLoading) {
      return <p className="text-lg">Buscando resultados...</p>;
    }
    if (!isLoading && total > 0) {
      return (
        <p className="text-lg">
          <b>{total}</b> resultado(s)
        </p>
      );
    }
    return null;
  };

  return (
    <div className="container mx-auto p-0 sm:p-4">
      <div className="text-2xl font-medium items-center flex">
        {icon !== "agidesk" ? (
          <i className={`${icon} mr-4 text-3xl`} />
        ) : (
          <img className="mr-4 agidesk-logo" alt="Agidesk" src={logoonly} />
        )}
        <p>{title}</p>
      </div>

      <div className="flex justify-between items-center">
        <SearchBox
          userPage
          _escFunction={() => {
            if (keyword.length > 0) {
              setKeyword("");
              setHasMore(true);
              setSkip(0);
              setLimit(30);
            }
          }}
          keyword={keyword}
          listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
            setKeyword(e.target.value);
            setHasMore(true);
            setSkip(0);
            setLimit(30);
          }}
        />
        <GridButtonOptions
          setShowCards={setShowCards}
          setShowModal={setShowModal}
          showDeleted={showDeleted}
          setShowDeleted={setShowDeleted}
          showSpam={showSpam}
          setShowSpam={setShowSpam}
          setLimit={setLimit}
          setSkip={setSkip}
        />
      </div>

      <div className="flex items-center justify-between mt-2">
        {getTotalInfo()}
        {(isAdmin || isSupervisor) &&
        location.pathname === "/settings/contacts" ? (
          <LinkButton
            extraClass={`!flex-1 !justify-end ${
              getTotalInfo() === null ? "mt-[2px]" : ""
            }`}
            // color
            leftIcon="las la-upload mr-1"
            label="Importar"
            onClick={() => {
              setShowImport(true);
            }}
          />
        ) : null}
      </div>

      {isLoading && total === 0 && list.length === 0 ? (
        <LoadingDots
          className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
          large
        />
      ) : (
        <ul className="flex flex-wrap -mx-1 lg:-mx-4">
          {list.length > 0 ? (
            <>
              {list.map(
                (
                  item:
                    | IUser
                    | ICustomer
                    | ITeam
                    | ISettings
                    | IExternalAppWhatsApp
                    | IExternalAppAgidesk
                    | IExternalAppBotmaker
                    | IExternalAppVtex
                    | IOfficehour
                    | IHoliday
                    | IOfflinemessage
                    | ISurvey
                    | ITag
                    | ICampaign,
                  index
                ) => (
                  <li
                    ref={index === list.length - 3 ? listRef : null}
                    key={item._id}
                    className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3"
                  >
                    {getType(item)}
                  </li>
                )
              )}
              {isLoading ? (
                <li
                  key="loading"
                  className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3"
                >
                  {getType(null)}
                </li>
              ) : null}
            </>
          ) : (
            <div className="flex w-full h-full justify-center p-6">
              Nenhum resultado
            </div>
          )}
        </ul>
      )}
      {showImport ? (
        <RelationModal
          icon="las la-upload"
          setShowModal={setShowImport}
          title="Importar contatos"
        />
      ) : null}
    </div>
  );
};

export default Grid;
