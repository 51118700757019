import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IUser,
  RoleUser,
  reselectContact,
  reselectAgent,
  updateUser,
} from "../../../../../slices/users.slice";
import SectionElement from "../../../../../shared/components/SectionElement.component";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux/hooks";
import { Header } from "../../../../../shared/components/Sidebars";
import useUserIsAdmin from "../../../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../../../hooks/useUserIsSupervisor";
import validateEmail from "../../../../../shared/utils/validation/email";
import Campaigns from "../../../../contacts/components/Campaigns.section";
import Button from "../../../../../shared/components/Buttons/Button.component";
import { validNumberLength } from "../../../../../shared/utils/handleMaskLength";

const ChatDetails = ({
  chatContact,
  setChatContact,
  setVisible,
  disable,
  setDisable,
  back,
  setShowSpamModal,
}: {
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  chatContact: IUser | undefined;
  setChatContact: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSpamModal?: React.Dispatch<React.SetStateAction<boolean>>;
  back(): void;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedHistoryContact } = useAppSelector((state) => state.chats);
  const [contact, setContact] = useState<IUser | undefined>(chatContact);

  const goSave = async () => {
    setDisable(true);
    let _contact: any = {
      _id: contact?._id,
      name: contact?.name,
      active: contact?.active,
      phone: validNumberLength({ value: contact?.phone || "" })
        ? contact?.phone
        : undefined,
      code: contact?.code,
      roles: contact?.roles,
      teams: contact?.teams?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      tags: contact?.tags?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      customers: contact?.customers?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
    };

    if (
      !contact?.roles?.includes(RoleUser.AGENT) &&
      !contact?.roles?.includes(RoleUser.ADMINISTRATOR)
    ) {
      _contact = {
        ..._contact,
        email: contact?.email,
      };
    } else {
      delete _contact?.email;
    }
    const { payload } = await dispatch(updateUser(_contact));
    if (typeof payload?._id !== "undefined") {
      setChatContact({
        ...contact,
        name: payload.name,
        email: payload.email,
        phone: payload.phone,
        code: payload?.code,
        tags: payload?.tags,
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      });
      dispatch(
        contact?.roles?.includes(RoleUser.AGENT)
          ? reselectAgent({
              ...contact,
              name: payload.name,
              email: payload.email,
              phone: payload.phone,
              updated_at: payload.updated_at,
              updated_by: payload.updated_by,
            })
          : reselectContact({
              ...contact,
              name: payload.name,
              email: payload.email,
              phone: payload.phone,
              code: payload?.code,
              tags: payload?.tags,
              updated_at: payload.updated_at,
              updated_by: payload.updated_by,
            })
      );
    }

    if (
      payload !== null &&
      typeof payload !== "undefined" &&
      typeof payload?.message !== "string"
    ) {
      dispatch({
        type: "chats/changeUserChat",
        payload: {
          ...contact,
          tags: payload?.tags,
          name: payload.name,
          email: payload.email,
          phone: payload.phone,
          code: payload?.code,
        },
      });
      dispatch({
        type: "messages/changeUserMessage",
        payload: {
          ...contact,
          tags: payload?.tags,
          name: payload.name,
          email: payload.email,
          phone: payload.phone,
          code: payload?.code,
        },
      });
      dispatch({
        type: "users/changeUser",
        payload: {
          ...contact,
          tags: payload?.tags,
          name: payload.name,
          email: payload.email,
          phone: payload.phone,
          code: payload?.code,
        },
      });
    }
    setDisable(false);
    navigate(`${location.pathname}#infos`);
  };

  return (
    <div className="flex-container p-3">
      <div className="header">
        <Header
          icon={`las ${
            contact?.roles?.includes(RoleUser.AGENT)
              ? "la-user"
              : "la-address-book"
          }`}
          imported={typeof chatContact?.externalcode !== "undefined"}
          spam={typeof chatContact?.spam !== "undefined" && chatContact.spam}
          title={contact?.name || ""}
          _id={contact?._id}
          setVisible={setVisible}
        />
      </div>
      <div id="sidemenu" className="px-4 content overflow-y-scroll">
        <SectionElement
          instance={{ ...contact, avatar: chatContact?.avatar }}
          setChatContact={setContact}
          type={`${
            contact?.roles?.includes(RoleUser.AGENT)
              ? "user-info"
              : "contact-info"
          }`}
        />
        {!contact?.roles?.includes(RoleUser.AGENT) ? (
          <Campaigns _id={contact?._id} />
        ) : null}
      </div>
      <div className="footer px-4 ">
        <div className="flex mx-auto items-center justify-between w-full mt-2">
          <Button
            minWidth
            label="Cancelar"
            icon="las la-arrow-left"
            onClick={back}
            inverted
          />
          <div className="mr-4" />
          {!chatContact?.spam &&
          !contact?.roles?.includes(RoleUser.AGENT) &&
          (isAdmin || isSupervisor) &&
          !(
            selectedHistoryContact !== null &&
            typeof selectedHistoryContact?.key !== "undefined" &&
            selectedHistoryContact?.key.length > 0
          ) ? (
            <>
              <Button
                minWidth
                label="SPAM"
                icon="las la-exclamation-circle"
                onClick={() => {
                  if (setShowSpamModal) setShowSpamModal(true);
                }}
                inverted
                orange
              />
              <div className="mr-4" />
            </>
          ) : null}
          <Button
            minWidth
            label={t("formbutton.label.save") || ""}
            icon="las la-save"
            onClick={goSave}
            disabled={
              (selectedHistoryContact !== null &&
                typeof selectedHistoryContact?.key !== "undefined" &&
                selectedHistoryContact?.key.length > 0) ||
              (!isAdmin &&
                !isSupervisor &&
                !user?.roles?.includes(RoleUser.AGENT)) ||
              (!isAdmin &&
                !isSupervisor &&
                user?.roles?.includes(RoleUser.AGENT) &&
                contact?.roles?.includes(RoleUser.AGENT)) ||
              disable ||
              contact?.email?.length === 0 ||
              !validateEmail(contact?.email || "") ||
              !contact?.name ||
              contact?.roles?.length === 0 ||
              contact.name.length < 2 ||
              (contact.roles?.length === 1 &&
                contact.roles.includes(RoleUser.CONTACT) &&
                (!contact?.phone?.replace(/[^0-9.]/g, "") ||
                  !validNumberLength({
                    value: contact?.phone?.replace(/[^0-9.]/g, ""),
                  }) ||
                  (typeof contact?.code?.replace(/\D+/g, "") !== "undefined" &&
                    contact?.code?.replace(/\D+/g, "").length > 0 &&
                    contact?.code?.replace(/\D+/g, "").length !== 11)))
            }
          />
        </div>
      </div>
    </div>
  );
};

ChatDetails.defaultProps = {
  setShowSpamModal: undefined,
};

export default ChatDetails;
