import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  deleteHoliday,
  reselectHoliday,
  selectHoliday,
  updateHoliday,
} from "../slices/holidays.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import { SidebarComponent } from "../shared/components/Sidebars";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import { getSettings } from "../slices/settings.slice";
import { cellObject } from "../shared/components/Tables/TableBody.component";
import ConfirmationModal from "../shared/components/Modals/ConfirmationModal.component";
import { ITeam } from "../slices/teams.slice";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";

const HolidayPage = ({
  icon,
  title,
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const { canAccess } = useAccessControl();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingHolidays, selectedHoliday } = useAppSelector(
    (state) => state.holidays
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<cellObject[]>([]);

  const deletedItems = useRef<string[]>([]);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      await dispatch(selectHoliday({ _id: getId() }));
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedHoliday?._id) {
      const { payload } = await dispatch(deleteHoliday([selectedHoliday._id]));
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate(-1);
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _holiday = {
      ...selectedHoliday,
      teams: selectedHoliday?.teams?.map((_t: any) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      active: true,
      deleted: false,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateHoliday({ _holiday }));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSave = async () => {
    setDisable(true);
    const _holiday = {
      ...selectedHoliday,
      _id: selectedHoliday?._id,
      name: selectedHoliday?.name,
      day: selectedHoliday?.day,
      month: selectedHoliday?.month,
      year: selectedHoliday?.year,
      active: selectedHoliday?.active,
      deleted: selectedHoliday?.deleted,
      teams: selectedHoliday?.teams?.map((_t: ITeam) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
    };
    const { payload } = await dispatch(updateHoliday({ _holiday }));
    dispatch(
      reselectHoliday({
        ...selectedHoliday,
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      })
    );
    setDisable(false);
  };

  if (isLoadingHolidays)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        setReloadForced={setReloadForced}
        content={
          <CreateModal
            title={title}
            icon={icon}
            setShowModal={setShowModal}
            setVisible={setVisible}
          />
        }
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedHoliday?.created_at}
          updated={selectedHoliday?.updated_at}
          deleted={selectedHoliday?.deleted_at}
          title={selectedHoliday?.name || ""}
          imported={false}
          icon="las la-calendar-times"
        />
        <Section
          icon="las la-info-circle"
          title="Informações"
          description="Edite as informações exibidas"
          component={
            <SectionElement instance={selectedHoliday} type="holiday-info" />
          }
        />
        <DividerInfoPages />
        <Section
          icon="las la-user-friends"
          title="Equipes vinculadas a este feriado"
          description="Relacione as equipes desejadas"
          component={
            <SectionElement
              instance={selectedHoliday}
              type="holiday-teams"
              setShowModal={setShowModal}
              setVisible={setVisible}
            />
          }
        />
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            selectedHoliday?.deleted ||
            typeof selectedHoliday?.name === "undefined" ||
            selectedHoliday?.name?.length < 2
          }
          deleted={selectedHoliday?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedHoliday?.deleted
              ? "Deseja reativar este horário?"
              : "Deseja remover este horário?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={canAccess(Permissions.DELETE_HOLLIDAYS) ? goReactive : undefined}
          remove={canAccess(Permissions.DELETE_HOLLIDAYS) ? goRemove : undefined}
          save={canAccess(Permissions.UPDATE_HOLLIDAYS) ? goSave : undefined}
        />
        {/* MODALS */}
        {showModal ? (
          <RelationModal
            title="Relacionar equipes"
            icon="las la-user-friends"
            setShowModal={setShowModal}
          />
        ) : null}
      </Sidebar.Pusher>
    </div>
  );
};

export default HolidayPage;
