import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  IOfflineType,
  IOfflinemessage,
  IOfflinemessageType,
  deleteOfflinemessage,
  reselectOfflinemessage,
  selectOfflinemessage,
  updateOfflinemessage,
} from "../slices/offlinemessages.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import { SidebarComponent } from "../shared/components/Sidebars";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import { getSettings } from "../slices/settings.slice";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";

const OfflinemessagePage = ({
  icon,
  title,
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const { canAccess } = useAccessControl();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingOfflinemessages, selectedOfflinemessage } = useAppSelector(
    (state) => state.offlinemessages
  );

  interface OfflineSelected {
    name?: string;
    active?: boolean;
    deleted?: boolean;
    email?: string;
    widget?: {
      active: boolean;
      content: string;
    };
    whatsapp?: {
      active: boolean;
      content: string;
    };
  }

  const [, setReloadForced] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [_selected, setSelected] = useState<OfflineSelected>({
    name: "",
    active: false,
    deleted: false,
    email: "",
    widget: {
      active: false,
      content: "",
    },
    whatsapp: {
      active: false,
      content: "",
    },
  });

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      const { payload } = await dispatch(
        selectOfflinemessage({ _id: getId() })
      );
      const _payload: IOfflinemessage = payload;
      if (typeof _payload?._id !== "undefined") {
        if (
          typeof _payload?.message !== "undefined" &&
          _payload.message &&
          payload.message.length > 0
        ) {
          payload.message.forEach((_message: IOfflinemessageType) => {
            if (_message.type === IOfflineType.WIDGET) {
              setSelected((prevState) => {
                return {
                  ...prevState,
                  name: _payload?.name,
                  active: _payload?.active,
                  deleted: _payload?.deleted,
                  email: _payload?.email,
                  widget: {
                    active: true,
                    content: _message.content || "",
                  },
                };
              });
            } else if (_message.type === IOfflineType.WHATSAPP) {
              setSelected((prevState) => {
                return {
                  ...prevState,
                  name: _payload?.name,
                  deleted: _payload?.deleted,
                  active: _payload?.active,
                  email: _payload?.email,
                  whatsapp: {
                    active: true,
                    content: _message.content || "",
                  },
                };
              });
            }
          });
        } else {
          setSelected((prevState) => {
            return {
              ...prevState,
              name: _payload?.name,
              deleted: _payload?.deleted,
              active: _payload?.active,
              email: _payload?.email,
            };
          });
        }
      }
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  useEffect(() => {
    const handleClickScrollWhatsApp = () => {
      const element = document.getElementById("whatsapp-channel");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleClickScrollWidget = () => {
      const element = document.getElementById("widget-channel");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    switch (location.hash) {
      case "#whatsapp-channel":
        handleClickScrollWhatsApp();
        break;
      case "#widget-channel":
        handleClickScrollWidget();
        break;
      default:
        break;
    }
  }, [location]);

  const goBack = () => {
    navigate("/settings/offlinemessages");
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedOfflinemessage?._id) {
      const { payload } = await dispatch(
        deleteOfflinemessage([selectedOfflinemessage._id])
      );
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/offlinemessages");
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _offlinemessage = {
      ...selectedOfflinemessage,
      active: true,
      deleted: false,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(
      updateOfflinemessage({ _offlinemessage })
    );
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate("/settings/offlinemessages");
    }
  };

  const goSave = async () => {
    const _message: IOfflinemessageType[] = [];
    if (_selected.widget?.active && _selected.widget?.content?.length > 0) {
      _message.push({
        type: IOfflineType.WIDGET,
        content: _selected.widget.content,
      });
    }
    if (_selected.whatsapp?.active && _selected.whatsapp?.content?.length > 0) {
      _message.push({
        type: IOfflineType.WHATSAPP,
        content: _selected.whatsapp.content,
      });
    }

    setDisable(true);
    const _offlinemessage = {
      ...selectedOfflinemessage,
      _id: selectedOfflinemessage?._id,
      deleted: selectedOfflinemessage?.deleted,
      name: _selected.name,
      active: _selected.active,
      email: _selected.email,
      message: _message,
    };
    const { payload } = await dispatch(
      updateOfflinemessage({ _offlinemessage })
    );
    if (typeof payload?._id !== "undefined") {
      dispatch(
        reselectOfflinemessage({
          ...selectedOfflinemessage,
          name: payload.name,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
    }
    setDisable(false);
  };

  if (isLoadingOfflinemessages)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        setReloadForced={setReloadForced}
        content={null}
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedOfflinemessage?.created_at}
          updated={selectedOfflinemessage?.updated_at}
          deleted={selectedOfflinemessage?.deleted_at}
          title={selectedOfflinemessage?.name || ""}
          imported={false}
          icon="las la-hourglass-end"
        />
        <Section
          icon="las la-info-circle"
          title="Informações"
          description="Edite as informações exibidas"
          component={
            <SectionElement
              instance={_selected}
              setSelected={setSelected}
              type="offlinemessage-info"
            />
          }
        />
        <DividerInfoPages />
        <Section
          icon="las la-at"
          title="E-mails"
          description={`Informe os e-mails de quem será notificado.\nSepare os e-mails por ponto-e-vírgula ou pressionando enter.\nO envio de e-mails é utilizado pelo widget`}
          component={
            <SectionElement
              instance={_selected}
              setSelected={setSelected}
              type="offlinemessage-email"
            />
          }
        />
        {/* {typeof _selected.email !== "undefined" &&
        _selected.email.length > 0 ? (
          <> */}
        <DividerInfoPages />
        <Section
          icon="las la-rss"
          title="Configuração de canais"
          description="Selecione os canais que serão utilizados fora de horário de atendimento e personalize a mensagem que será mostrada ao contato"
          component={
            <SectionElement
              instance={_selected}
              setSelected={setSelected}
              type="offlinemessage-channels"
            />
          }
        />
        {/* </>
        ) : null} */}
        <FooterPageInfo
          disabled={
            disable ||
            (_selected.widget?.active &&
              _selected.widget?.content.length < 2) ||
            (_selected.whatsapp?.active &&
              _selected.whatsapp?.content.length < 2) ||
            selectedOfflinemessage?.deleted ||
            ((typeof _selected.email === "undefined" ||
              _selected.email.length === 0) &&
              _selected.widget?.active) ||
            typeof _selected.name === "undefined" ||
            _selected.name.length < 2
          }
          deleted={selectedOfflinemessage?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedOfflinemessage?.deleted
              ? "Deseja reativar esta mensagem?"
              : "Deseja remover esta mensagem?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={canAccess(Permissions.DELETE_OFFILINE_MESSAGES) ? goReactive : undefined}
          remove={canAccess(Permissions.DELETE_OFFILINE_MESSAGES) ? goRemove : undefined}
          save={canAccess(Permissions.UPDATE_OFFILINE_MESSAGES) ? goSave : undefined}
        />
      </Sidebar.Pusher>
    </div>
  );
};

export default OfflinemessagePage;
