import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Accordion } from "semantic-ui-react";
import { UserType } from "../slices/users.slice";
import AuthLayoutPage from "./AuthLayout.page";
import { officehoursService } from "../services";
import instances from "../services/instances";
import { IOfficehour, SettingOfficehours } from "../slices/officehours.slice";
import {
  ExternalAppType,
  IExternalAppBotmaker,
  IExternalAppWhatsApp,
} from "../slices/externalapps.slice";
import findInObject from "../shared/utils/findInObject";
import LoadingDots from "../shared/components/LoadingDots.component";
import removeDuplicates from "../shared/utils/removeDuplicates";

const Publichours = () => {
  const params = useParams();
  const { pathname } = useLocation();
  const [teams, setTeams] = useState<
    {
      name: string;
      _id: string;
      channels: string[];
      channelsLabel: string;
      officehours: JSX.Element | null;
      officehourId: string | null;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getTenant = () => {
      if (params && typeof params._id !== "undefined") return params._tenant;
      return pathname.split("/").slice(-1)[0];
    };
    instances.instanceOfficehours.defaults.headers.common["x-tenant-id"] =
      getTenant() as string;
    instances.instanceOfficehours.defaults.headers.common.widget = "contact";
    setIsLoading(true);

    (async () => {
      const _tempTeams: {
        name: string;
        _id: string;
        channels: string[];
        channelsLabel: string;
        officehourId: string | null;
        officehours: JSX.Element | null;
      }[] = [];

      const _payload = await officehoursService.officehours();

      const getTeams = () => {
        if (
          typeof _payload?.teams !== "undefined" &&
          _payload?.teams.length > 0
        ) {
          const _teams = _payload.teams;
          _teams.forEach(
            (_team: {
              _id: string;
              name: string;
              officehour: string | null;
            }) => {
              _tempTeams.push({
                _id: _team._id,
                name: _team.name,
                officehours: null,
                channels: [],
                officehourId: _team.officehour,
                channelsLabel: "",
              });
            }
          );
        }
      };

      const getWidgetTeams = () => {
        if (
          typeof _payload?.settings?.defaultteam !== "undefined" &&
          _payload?.settings?.defaultteam.length > 0
        ) {
          const _teams = _payload.settings.defaultteam;
          _tempTeams.forEach((_team) => {
            if (typeof findInObject(_teams, "_id", _team._id) !== "undefined") {
              _team.channels.push("widget");
            }
          });
        }
      };

      const getExternalAppsTeams = () => {
        if (typeof _payload?.externalapps !== "undefined") {
          const _extapps = _payload.externalapps;
          // const _extapps = _payload.externalapps.filter(
          //   (_ext: IExternalAppWhatsApp | IExternalAppBotmaker) =>
          //     typeof _ext?.fields?.defaultteam !== "undefined"
          // );
          if (typeof _extapps !== "undefined" && _extapps) {
            _extapps.forEach(
              (_extapp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
                _tempTeams.forEach((_team) => {
                  if (
                    typeof findInObject(
                      _extapp.fields.defaultteam,
                      "_id",
                      _team._id
                    ) !== "undefined"
                  ) {
                    if (_extapp.type === ExternalAppType.WHATSAPP) {
                      _team.channels.push("WhatsApp");
                    } else if (_extapp.type === ExternalAppType.BOTMAKER) {
                      _team.channels.push("Agibot");
                    }
                  }
                });
              }
            );
          }
        }
      };

      const getTableElement = (_hour: IOfficehour) => {
        const getDayLabel = (_day: string) => {
          switch (_day) {
            case "monday":
              return "Segunda-feira";
            case "tuesday":
              return "Terça-feira";
            case "wednesday":
              return "Quarta-feira";
            case "thursday":
              return "Quinta-feira";
            case "friday":
              return "Sexta-feira";
            case "saturday":
              return "Sábado";
            case "sunday":
              return "Domingo";
            default:
              return "";
          }
        };

        type weekdayOptions = {
          [key: string]: number;
        };

        const order: weekdayOptions = {
          monday: 1,
          tuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
          sunday: 7,
        };
        const _officehours =
          typeof _hour?.officehours !== "undefined" ? _hour?.officehours : [];
        const DisplayData =
          _officehours.length > 0
            ? _officehours
                ?.sort((a: SettingOfficehours, b: SettingOfficehours) =>
                  a?.start.localeCompare(b?.start)
                )
                ?.sort(
                  (a: SettingOfficehours, b: SettingOfficehours) =>
                    order[a?.weekday] - order[b?.weekday]
                )
                ?.map((_officehour) => {
                  if (_officehour.active) {
                    return (
                      <tr key={_officehour._id}>
                        <td className="text-justify leading-[2.25]">
                          {getDayLabel(_officehour.weekday)}
                        </td>
                        <td className="leading-[2.25]">{_officehour.start}</td>
                        <td className="leading-[2.25]">{_officehour.finish}</td>
                      </tr>
                    );
                  }
                  return null;
                })
            : [];

        if (
          typeof _hour?.officehours !== "undefined" &&
          _hour?.officehours.length > 0
        ) {
          return (
            <table className="text-center align-middle w-full">
              <thead>
                <tr>
                  <th className="leading-[3] align-middle font-semibold">
                    Dia da semana
                  </th>
                  <th className="leading-[3] text-center align-middle font-semibold">
                    Hora de início
                  </th>
                  <th className="leading-[3] text-center align-middle font-semibold">
                    Hora de término
                  </th>
                </tr>
              </thead>
              <tbody>{DisplayData}</tbody>
            </table>
          );
        }
        return (
          <div className="flex w-full h-full justify-center p-6">
            Nenhum horário cadastrado
          </div>
        );
      };

      const getOfficehours = () => {
        if (
          typeof _payload?.officehours !== "undefined" &&
          _payload?.officehours?.length > 0
        ) {
          const _officehours = _payload.officehours;
          _tempTeams.forEach((_team) => {
            if (_team.channels.length > 0) {
              _officehours.forEach((_hour: IOfficehour) => {
                if (
                  typeof _hour.officehours !== "undefined" &&
                  ((typeof _hour.teams !== "undefined" &&
                    _hour.teams.length > 0) ||
                    _hour.default)
                ) {
                  if (
                    typeof findInObject(_hour.teams, "_id", _team._id) !==
                    "undefined"
                  ) {
                    _team.officehours = getTableElement(_hour);
                  } else if (_hour.default) {
                    if (
                      _team.officehourId !== null &&
                      typeof findInObject(
                        _officehours,
                        "_id",
                        _team.officehourId
                      ) !== "undefined"
                    ) {
                      _team.officehours = getTableElement(_hour);
                    }
                  }
                }
              });
            }
          });
        }
      };

      const getChannelsLabel = () => {
        const _label = "Esta equipe atende pelo ";
        _tempTeams.forEach((_team) => {
          if (_team.channels.length > 0) {
            const _channels: string[] = removeDuplicates(_team.channels);
            _team.channelsLabel = _label
              .concat(
                _channels
                  .reduce((prev, curr, i) => {
                    return (
                      prev + curr + (i === _channels.length - 2 ? " e " : ", ")
                    );
                  }, "")
                  .slice(0, -2)
              )
              .concat(".");
          }
        });
      };

      if (typeof _payload.officehours !== "undefined") {
        setIsLoading(false);
        getTeams();
        getWidgetTeams();
        getExternalAppsTeams();
        getOfficehours();
        getChannelsLabel();
        setTeams(
          _tempTeams.filter(
            (_team) => _team.channels.length > 0 && _team.officehours !== null
          )
        );
        // console.log(_tempTeams.filter((_team) => _team.channels.length > 0));
      }
    })();
  }, []);

  const panels = teams.map((_team) => ({
    key: _team._id,
    title: {
      content: (
        <label htmlFor={_team._id} className="text-[16px]">
          {_team.name}
        </label>
      ),
    },
    content: {
      content: (
        <div className="mx-[22px]">
          <h1 className="mb-2 text-agitalks">{_team.channelsLabel}</h1>
          {_team.officehours}
        </div>
      ),
    },
  }));

  return (
    <div className="max-w-[1000px] w-full overflow-y-auto">
      <AuthLayoutPage widgetType={UserType.NONE} _public>
        <div className="w-full">
          <h1 className="font-semibold text-[22px] mb-4">
            Horários de atendimento no Agitalks
          </h1>
          {isLoading ? (
            <LoadingDots className="flex justify-center items-center" />
          ) : (
            <div>
              <Accordion panels={panels} fluid exclusive={false} />
              <h2 className="text-[12px] text-agitalks mt-8">
                Estes horários podem variar em dias de feriado.
              </h2>
            </div>
          )}
        </div>
      </AuthLayoutPage>
    </div>
  );
};

export default Publichours;
