import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import {
  ExternalAppType,
  selectExternalapp,
  updateExternalapps,
  reselectExternalappWhatsApp,
  deleteExternalapps,
} from "../slices/externalapps.slice";
import { getTemplatesWpp } from "../slices/messages.slice";
import { SidebarComponent } from "../shared/components/Sidebars";
import CreateModal from "../shared/components/Modals/CreateModal.component";
import { getSettings } from "../slices/settings.slice";
import FinishMessagesSection from "../features/settings/FinishMessages.section";
import useAccessControl from "../hooks/useAccessControl";
import { Permissions } from "../slices/permissiongroups.slice";

const WhatsappPage = ({
  icon,
  title,
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const { canAccess } = useAccessControl();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingExternalapps, selectedExternalAppWhatsApp } = useAppSelector(
    (state) => state.externalapps
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(
    typeof selectedExternalAppWhatsApp?.fields?.offlinemessage?._id !==
    "undefined"
  );

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      const { payload } = await dispatch(
        selectExternalapp({
          _id: getId(),
        })
      );
      if (typeof payload?.fields?.offlinemessage?._id !== "undefined") {
        setToggle(true);
      }
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  useEffect(() => {
    const handleClickScroll = () => {
      const element = document.getElementById("whatsapp-defaultteam");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    switch (location.hash) {
      case "#whatsapp-defaultteam":
        handleClickScroll();
        break;
      default:
        break;
    }
  }, [location]);

  const goBack = () => {
    navigate("/settings/integration/whatsapp");
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedExternalAppWhatsApp?._id) {
      const { payload } = await dispatch(
        deleteExternalapps([selectedExternalAppWhatsApp._id])
      );
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/integration/whatsapp");
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _externalapp = {
      ...selectedExternalAppWhatsApp,
      fields: {
        ...selectedExternalAppWhatsApp.fields,
        offlinemessage:
          selectedExternalAppWhatsApp?.fields?.offlinemessage?._id,
        defaultteam: selectedExternalAppWhatsApp?.fields?.defaultteam?.map(
          (_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }
        ),
      },
      type: ExternalAppType.WHATSAPP,
      deleted: false,
      active: true,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSaveWhatsApp = async () => {
    setDisable(true);
    const _externalapp = {
      ...selectedExternalAppWhatsApp,
      _id: selectedExternalAppWhatsApp?._id,
      active: selectedExternalAppWhatsApp?.active,
      type: ExternalAppType.WHATSAPP,
      fields: {
        ...selectedExternalAppWhatsApp.fields,
        name: selectedExternalAppWhatsApp?.fields?.name,
        displayphone:
          selectedExternalAppWhatsApp?.fields?.displayphone?.replace(
            /[^0-9.]/g,
            ""
          ),
        phone: selectedExternalAppWhatsApp?.fields?.phone,
        key: selectedExternalAppWhatsApp?.fields?.key,
        verifytoken: selectedExternalAppWhatsApp?._id,
        wabaid: selectedExternalAppWhatsApp?.fields?.wabaid,
        offlinemessage:
          selectedExternalAppWhatsApp?.fields?.offlinemessage?._id,
        finishmessages: selectedExternalAppWhatsApp?.fields?.finishmessages,
        defaultteam: selectedExternalAppWhatsApp?.fields?.defaultteam?.map(
          (_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }
        ),
      },
    };
    const { payload } = await dispatch(
      updateExternalapps({ _externalapp, _validate: true })
    );
    if (typeof payload.error === "undefined") {
      dispatch(
        reselectExternalappWhatsApp({
          ...selectedExternalAppWhatsApp,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
      const action = await dispatch(
        getTemplatesWpp({
          _validate: true,
          externalappid: selectedExternalAppWhatsApp?._id || "",
        })
      );
      if (typeof action.payload.error === "undefined") {
        const _action = await dispatch(
          updateExternalapps({
            _externalapp: {
              ..._externalapp,
              fields: {
                ..._externalapp.fields,
                validate: true,
              },
            },
            _validate: true,
          })
        );
        if (
          selectedExternalAppWhatsApp?.fields?.key &&
          selectedExternalAppWhatsApp?.fields?.phone &&
          // selectedExternalAppWhatsApp?.fields?.verifytoken &&
          selectedExternalAppWhatsApp?.fields?.wabaid
        ) {
          dispatch(
            reselectExternalappWhatsApp({
              ...selectedExternalAppWhatsApp,
              fields: {
                ...selectedExternalAppWhatsApp.fields,
                validate: true,
              },
              updated_at: _action.payload.updated_at,
              updated_by: _action.payload.updated_by,
            })
          );
        }
        navigate(`${location.pathname}#whatsapp-defaultteam`);
      } else {
        dispatch(
          reselectExternalappWhatsApp({
            ...selectedExternalAppWhatsApp,
            fields: {
              ...selectedExternalAppWhatsApp.fields,
              validate: false,
            },
          })
        );
      }
    }
    setDisable(false);
  };

  const goSave = async () => {
    setDisable(true);
    const _externalapp = {
      _id: selectedExternalAppWhatsApp?._id,
      active: selectedExternalAppWhatsApp?.active,
      type: ExternalAppType.WHATSAPP,
      fields: {
        name: selectedExternalAppWhatsApp?.fields?.name,
        displayphone:
          selectedExternalAppWhatsApp?.fields?.displayphone?.replace(
            /[^0-9.]/g,
            ""
          ),
        phone: selectedExternalAppWhatsApp?.fields?.phone,
        key: selectedExternalAppWhatsApp?.fields?.key,
        verifytoken: selectedExternalAppWhatsApp?._id,
        wabaid: selectedExternalAppWhatsApp?.fields?.wabaid,
        validate: selectedExternalAppWhatsApp?.fields?.validate,
        header: selectedExternalAppWhatsApp?.fields?.header,
        body: selectedExternalAppWhatsApp?.fields?.body,
        button: selectedExternalAppWhatsApp?.fields?.button,
        finishmessages: selectedExternalAppWhatsApp?.fields?.finishmessages,
        offlinemessage:
          selectedExternalAppWhatsApp?.fields?.offlinemessage?._id,
        defaultteam: selectedExternalAppWhatsApp?.fields?.defaultteam?.map(
          (_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }
        ),
      },
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    dispatch(
      reselectExternalappWhatsApp({
        ...selectedExternalAppWhatsApp,
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      })
    );
    setDisable(false);
  };

  if (isLoadingExternalapps)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        content={
          <CreateModal
            title={title}
            icon={icon}
            setShowModal={setShowModal}
            setVisible={setVisible}
          />
        }
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedExternalAppWhatsApp?.created_at}
          updated={selectedExternalAppWhatsApp?.updated_at}
          deleted={selectedExternalAppWhatsApp?.deleted_at}
          title={selectedExternalAppWhatsApp?.fields?.name}
          imported={false}
          icon="lab la-whatsapp"
        />
        <Section
          icon="las la-info-circle"
          title="Informações do WhatsApp"
          description="Preencha os dados necessários para completar a integração"
          component={
            <SectionElement
              instance={selectedExternalAppWhatsApp || null}
              type="whatsapp-info"
              save={goSaveWhatsApp}
            />
          }
        />
        {selectedExternalAppWhatsApp?.fields?.key?.length > 0 &&
          selectedExternalAppWhatsApp?.fields?.phone?.length > 0 &&
          // selectedExternalAppWhatsApp?.fields?.verifytoken?.length > 0 &&
          selectedExternalAppWhatsApp?.fields?.wabaid?.length > 0 &&
          selectedExternalAppWhatsApp.fields.validate ? (
          <>
            <DividerInfoPages />
            <Section
              id="whatsapp-defaultteam"
              icon="las la-user-friends"
              title="Equipes disponíveis para atendimento"
              description="Selecione as equipes que atenderão e que poderão iniciar conversas através deste número"
              component={
                <SectionElement
                  setVisible={setVisible}
                  instance={selectedExternalAppWhatsApp}
                  type="whatsapp-defaultteam"
                  setShowModal={setShowModal}
                />
              }
            />
          </>
        ) : null}
        {selectedExternalAppWhatsApp?.fields?.defaultteam &&
          selectedExternalAppWhatsApp?.fields?.defaultteam.length > 0 &&
          selectedExternalAppWhatsApp.fields.validate ? (
          <>
            <DividerInfoPages />
            <Section
              icon="las la-flag-checkered"
              title="Mensagens de encerramento de conversa"
              // description="Personalize as mensagens de encerramento pelo agente, pelo contato e por inatividade"
              description={`Personalize as mensagens de encerramento pelo agente e por inatividade.\n
                <b>Atenção</b>: As mensagens configuradas aqui substituirão as mensagens de encerramento configuradas nas configurações gerais.\n
                Consulte as <b><a class="externalapplink" href="/settings/general">configurações gerais</a></b> ou solicite a um administrador.`}
              component={<FinishMessagesSection type="whatsapp" />}
            />
            <DividerInfoPages />
            <Section
              id="whatsapp-offlinemessage"
              icon="las la-hourglass-end"
              title="Atendimentos fora de horário"
              description={`Habilite o atendimento fora de horário e selecione a configuração de perfil de atendimento fora do horário.\n
              Essa configuração definirá a mensagem a ser enviada ao contato ao solicitar um atendimento fora do horário e registrará uma conversa na fila de espera com esse contato.`}
              // description={`Habilite o atendimento fora de horário.\nSelecione a mensagem que será enviada ao contato que tentar acessar fora do horário de atendimento.\nSerá`}
              component={
                <SectionElement
                  instance={selectedExternalAppWhatsApp}
                  type="whatsapp-offlinemessage"
                  toggle={toggle}
                  setToggle={setToggle}
                />
              }
            />
          </>
        ) : null}
        {selectedExternalAppWhatsApp?.fields?.defaultteam &&
          selectedExternalAppWhatsApp?.fields?.defaultteam.length > 1 &&
          selectedExternalAppWhatsApp?.fields?.key &&
          selectedExternalAppWhatsApp?.fields?.key.length > 0 &&
          selectedExternalAppWhatsApp.fields.validate ? (
          <>
            <DividerInfoPages />
            <Section
              icon="lab la-whatsapp"
              title="Padrão da mensagem inicial - (WhatsApp)"
              description="Mensagem inicial para exibição e seleção das equipes disponíveis para atendimento"
              component={
                <SectionElement
                  instance={selectedExternalAppWhatsApp || null}
                  type="whatsapp-interactive"
                />
              }
            />
          </>
        ) : null}
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            (typeof selectedExternalAppWhatsApp?.fields?.offlinemessage?._id ===
              "undefined" &&
              toggle) ||
            selectedExternalAppWhatsApp.deleted ||
            typeof selectedExternalAppWhatsApp?.fields?.name === "undefined" ||
            typeof selectedExternalAppWhatsApp?.fields?.key === "undefined" ||
            typeof selectedExternalAppWhatsApp?.fields?.phone === "undefined" ||
            // typeof selectedExternalAppWhatsApp?.fields?.verifytoken ===
            //   "undefined" ||
            typeof selectedExternalAppWhatsApp?.fields?.wabaid ===
            "undefined" ||
            selectedExternalAppWhatsApp?.fields?.name.length < 2 ||
            selectedExternalAppWhatsApp?.fields?.key.length === 0 ||
            selectedExternalAppWhatsApp?.fields?.phone.length === 0 ||
            // selectedExternalAppWhatsApp?.fields?.verifytoken.length === 0 ||
            selectedExternalAppWhatsApp?.fields?.wabaid.length === 0
          }
          deleted={selectedExternalAppWhatsApp?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedExternalAppWhatsApp?.deleted
              ? "Deseja reativar este número?"
              : "Deseja remover este número?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={canAccess(Permissions.DELETE_INTEGRATIONS_WHATSAPP) ? goReactive : undefined}
          remove={canAccess(Permissions.DELETE_INTEGRATIONS_WHATSAPP) ? goRemove : undefined}
          save={canAccess(Permissions.UPDATE_INTEGRATIONS_WHATSAPP) ? goSave : undefined}
        />
        {/* MODALS */}
        {showModal ? (
          <RelationModal
            title="Relacionar equipes"
            icon={icon}
            setShowModal={setShowModal}
          />
        ) : null}
      </Sidebar.Pusher>
    </div>
  );
};

export default WhatsappPage;
