// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { RoleUser } from "../slices/users.slice";
import { useAppSelector } from "./redux/hooks";

const useUserIsSupervisor = () => {
  const { user } = useAppSelector((state) => state.auth);

  const isSupervisor =
    (user?.roles?.includes(RoleUser.SUPERVISOR) &&
      !user?.roles?.includes(RoleUser.ADMINISTRATOR)) ||
    false;

  return { isSupervisor };
};

export default useUserIsSupervisor;
