import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  reselectLocalPermissiongroup,
  reselectPermissiongroup,
  selectPermissiongroup,
} from "../slices/permissiongroups.slice";
import useGetIdPage from "../hooks/useGetIdPage";
import usePermissiongroups from "../hooks/features/usePermissiongroups";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import Section from "../shared/components/Section.component";
import DefaultPage from "./Default.page";
import Infos from "../features/permissiongroups/components/Infos.section";
import Permissions from "../features/permissiongroups/components/Permissions.section";

const PermissiongroupPage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { getId } = useGetIdPage();
  // const { isAdmin } = useUserIsAdmin();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoadingPermissiongroups, selectedPermissiongroup } = useAppSelector(
    (state) => state.permissiongroups
  );
  const { sectionDetails, header, footer } = usePermissiongroups({
    disable,
    setDisable,
    toggleSidebar,
  });

  useEffect(() => {
    const _id = getId();
    if (location.pathname === `/settings/permissiongroup/${_id}`) {
      (async () => {
        dispatch(reselectPermissiongroup(null));
        dispatch(reselectLocalPermissiongroup(null));
        await dispatch(selectPermissiongroup({ _id }));
      })();
    }
  }, []);

  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <DefaultPage
        isLoading={
          isLoadingPermissiongroups && selectedPermissiongroup === null
        }
        header={header}
        content={
          <>
            <Section
              icon={sectionDetails.infos.icon}
              title={sectionDetails.infos.title}
              description={sectionDetails.infos.description}
              component={<Infos />}
            />
            <DividerInfoPages />
            <Section
              icon={sectionDetails.content.icon}
              title={sectionDetails.content.title}
              description={sectionDetails.content.description}
              fullarea
              component={<Permissions />}
            />
          </>
        }
        footer={footer}
      />
    </div>
  );
};

export default PermissiongroupPage;
