import React from "react";
import { useAppSelector } from "../../../hooks/redux/hooks";
import RelatedInstance from "../../../shared/components/RelatedInstance.component";
import RelatedButtons from "../../../shared/components/RelatedButtons.component";
import RelationModal from "../../../shared/components/Modals/RelationModal.component";
import useCommenttemplates from "../../../hooks/features/useCommenttemplates";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";

const Teams = () => {
  const { teams } = useCommenttemplates({});
  const { selectedLocalCommenttemplate } = useAppSelector(
    (state) => state.commenttemplates
  );
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  return (
    <>
      <div className="flex flex-col w-full text-justify">
        <label>Equipes relacionadas</label>
        <div
          id="sidemenu"
          className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
            selectedLocalCommenttemplate?.teams?.length ? "" : "justify-center"
          }`}
        >
          {selectedLocalCommenttemplate?.teams?.length ? (
            selectedLocalCommenttemplate?.teams?.map((_team) => {
              if (typeof _team._id !== "undefined") {
                return (
                  <RelatedInstance
                    key={`${_team._id}team`}
                    type="team"
                    instance={_team}
                    avatar={false}
                  />
                );
              }
              return null;
            })
          ) : (
            <div className="my-4 text-center">Nenhuma equipe</div>
          )}
        </div>
      </div>
      {!selectedLocalCommenttemplate?.deleted && (isAdmin || isSupervisor) ? (
        <RelatedButtons onClickRelation={teams.relation.onClickRelation} />
      ) : null}
      {teams.modal.show ? (
        <RelationModal
          title="Relacionar equipes"
          icon="las la-user-friends"
          setShowModal={teams.modal.setShow}
        />
      ) : null}
    </>
  );
};

export default Teams;
