import React, { useMemo } from "react";
import { Form } from "semantic-ui-react";
import usePermissiongroups from "../../../hooks/features/usePermissiongroups";
import { useAppSelector } from "../../../hooks/redux/hooks";
import Toggle from "../../../shared/components/Toggle.component";
import { Permissions as _Permissions } from "../../../slices/permissiongroups.slice";

const modules = [
  {
    title: "Grupos de permissões",
    permission: [
      { value: _Permissions.VIEW_PERMISSIONS_GROUPS, label: "Visualizar" },
      { value: _Permissions.CREATE_PERMISSIONS_GROUPS, label: "Criar" },
      { value: _Permissions.UPDATE_PERMISSIONS_GROUPS, label: "Atualizar" },
      { value: _Permissions.DELETE_PERMISSIONS_GROUPS, label: "Excluir" },
    ]
  },
  {
    title: "Configurações gerais da empresa",
    permission: [
      { value: _Permissions.VIEW_GENERAL_SETTINGS, label: "Visualizar" },
      { value: _Permissions.UPDATE_GENERAL_SETTINGS, label: "Atualizar" },
    ]
  },
  {
    title: "Conversas",
    permission: [
      { value: _Permissions.VIEW_CHATS, label: "Visualizar" },
    ],
    submodules: [
      {
        title: "Fila de espera",
        permission: [
          { value: _Permissions.VIEW_WAITING_LINE, label: "Visualizar" },
          { value: _Permissions.UPDATE_WAITING_LINE, label: "Atualizar" },
          { value: _Permissions.START_SERVICE_FROM_WAITING_LINE, label: "Iniciar atendimento" },
          { value: _Permissions.CANCEL_WAITING_LINE, label: "Cancelar" },
        ]
      },
      {
        title: "Minhas conversas",
        permission: [
          { value: _Permissions.VIEW_MY_CHATS, label: "Visualizar" },
          { value: _Permissions.CREATE_MY_CHATS, label: "Criar" },
          { value: _Permissions.UPDATE_MY_CHATS, label: "Atualizar" },
          { value: _Permissions.DELETE_MY_CHATS, label: "Excluir" },
          { value: _Permissions.TRANSFER_CHATS, label: "Transferir" },
          { value: _Permissions.CANCEL_CHAT, label: "Cancelar" },
          { value: _Permissions.CREATE_CHAT_GROUP, label: "Criar grupo" },
        ]
      },
      {
        title: "Minhas equipes",
        permission: [
          { value: _Permissions.VIEW_TEAM_CHATS, label: "Visualizar" },
          { value: _Permissions.UPDATE_TEAM_CHATS, label: "Atualizar" },
          { value: _Permissions.DELETE_TEAM_CHATS, label: "Excluir" },
          { value: _Permissions.TRANSFER_TEAM_CHATS, label: "Transferir" },
          { value: _Permissions.CANCEL_TEAM_CHATS, label: "Cancelar" },
        ]
      },
      {
        title: "Histórico",
        permission: [
          { value: _Permissions.VIEW_CHATS_HISTORY, label: "Visualizar" },
          { value: _Permissions.UPDATE_CHATS_HISTORY, label: "Atualizar" },
        ]
      },
    ]
  },
  {
    title: "Agentes",
    permission: [
      { value: _Permissions.VIEW_AGENTS, label: "Visualizar" },
      { value: _Permissions.CREATE_AGENTS, label: "Criar" },
      { value: _Permissions.UPDATE_AGENTS, label: "Atualizar" },
      { value: _Permissions.DELETE_AGENTS, label: "Excluir" },
    ]
  },
  {
    title: "Equipes",
    permission: [
      { value: _Permissions.VIEW_TEAMS, label: "Visualizar" },
      { value: _Permissions.CREATE_TEAMS, label: "Criar" },
      { value: _Permissions.UPDATE_TEAMS, label: "Atualizar" },
      { value: _Permissions.DELETE_TEAMS, label: "Excluir" },
    ]
  },
  {
    title: "Contatos",
    permission: [
      { value: _Permissions.VIEW_CONTACTS, label: "Visualizar" },
      { value: _Permissions.CREATE_CONTACTS, label: "Criar" },
      { value: _Permissions.UPDATE_CONTACTS, label: "Atualizar" },
      { value: _Permissions.DELETE_CONTACTS, label: "Excluir" },
      { value: _Permissions.MARK_CONTACTS_AS_SPAM, label: "Marcar como SPAM" },
    ]
  },
  {
    title: "Clientes",
    permission: [
      { value: _Permissions.VIEW_CUSTOMERS, label: "Visualizar" },
      { value: _Permissions.CREATE_CUSTOMERS, label: "Criar" },
      { value: _Permissions.UPDATE_CUSTOMERS, label: "Atualizar" },
      { value: _Permissions.DELETE_CUSTOMERS, label: "Excluir" },
    ]
  },
  {
    title: "Resultados",
    permission: [
      { value: _Permissions.VIEW_DASHBOARDS, label: "Visualizar" },
    ]
  },
  {
    title: "Menu de configurações",
    permission: [
      { value: _Permissions.VIEW_SETTINGS, label: "Visualizar" },
    ]
  },
  {
    title: "Horários de atendimento",
    permission: [
      { value: _Permissions.VIEW_OFFICE_HOURS, label: "Visualizar" },
      { value: _Permissions.CREATE_OFFICE_HOURS, label: "Criar" },
      { value: _Permissions.UPDATE_OFFICE_HOURS, label: "Atualizar" },
      { value: _Permissions.DELETE_OFFICE_HOURS, label: "Excluir" },
      { value: _Permissions.SET_DEFAULT_OFFICE_HOURS, label: "Definir padrão" },
    ]
  },
  {
    title: "Feriados",
    permission: [
      { value: _Permissions.VIEW_HOLLIDAYS, label: "Visualizar" },
      { value: _Permissions.CREATE_HOLLIDAYS, label: "Criar" },
      { value: _Permissions.UPDATE_HOLLIDAYS, label: "Atualizar" },
      { value: _Permissions.DELETE_HOLLIDAYS, label: "Excluir" },
    ]
  },
  {
    title: "Perfis de atendimento fora de horário",
    permission: [
      { value: _Permissions.VIEW_OFFILINE_MESSAGES, label: "Visualizar" },
      { value: _Permissions.CREATE_OFFILINE_MESSAGES, label: "Criar" },
      { value: _Permissions.UPDATE_OFFILINE_MESSAGES, label: "Atualizar" },
      { value: _Permissions.DELETE_OFFILINE_MESSAGES, label: "Excluir" },
    ]
  },
  {
    title: "Modelos de respostas",
    permission: [
      { value: _Permissions.VIEW_COMMENT_TEMPLATES, label: "Visualizar" },
      { value: _Permissions.CREATE_COMMENT_TEMPLATES, label: "Criar" },
      { value: _Permissions.UPDATE_COMMENT_TEMPLATES, label: "Atualizar" },
      { value: _Permissions.DELETE_COMMENT_TEMPLATES, label: "Excluir" },
    ]
  },
  {
    title: "Pesquisa de satisfação",
    permission: [
      { value: _Permissions.VIEW_SURVEYS, label: "Visualizar" },
      { value: _Permissions.CREATE_SURVEYS, label: "Criar" },
      { value: _Permissions.UPDATE_SURVEYS, label: "Atualizar" },
      { value: _Permissions.DELETE_SURVEYS, label: "Excluir" },
    ]
  },
  {
    title: "Indicadores de satisfação",
    permission: [
      { value: _Permissions.VIEW_SURVEYS_RESULTS, label: "Visualizar" },
    ]
  },
  {
    title: "Campanhas",
    permission: [
      { value: _Permissions.VIEW_CAMPAIGNS, label: "Visualizar" },
      { value: _Permissions.CREATE_CAMPAIGNS, label: "Criar" },
      { value: _Permissions.UPDATE_CAMPAIGNS, label: "Atualizar" },
      { value: _Permissions.DELETE_CAMPAIGNS, label: "Excluir" },
      { value: _Permissions.START_CAMPAIGNS, label: "Iniciar campanha" },
    ]
  },
  {
    title: "Marcadores de conversa",
    permission: [
      { value: _Permissions.VIEW_CHAT_TAGS, label: "Visualizar" },
      { value: _Permissions.CREATE_CHAT_TAGS, label: "Criar" },
      { value: _Permissions.UPDATE_CHAT_TAGS, label: "Atualizar" },
      { value: _Permissions.DELETE_CHAT_TAGS, label: "Excluir" },
    ]
  },
  {
    title: "Marcadores de contato",
    permission: [
      { value: _Permissions.VIEW_CONTACT_TAGS, label: "Visualizar" },
      { value: _Permissions.CREATE_CONTACT_TAGS, label: "Criar" },
      { value: _Permissions.UPDATE_CONTACT_TAGS, label: "Atualizar" },
      { value: _Permissions.DELETE_CONTACT_TAGS, label: "Excluir" },
    ]
  },
  {
    title: "Integrações",
    permission: [
      { value: _Permissions.VIEW_INTEGRATIONS, label: "Visualizar" },
    ],
    submodules: [
      {
        title: "Agidesk",
        permission: [
          { value: _Permissions.VIEW_INTEGRATIONS_AGIDESK, label: "Visualizar" },
          { value: _Permissions.CREATE_INTEGRATIONS_AGIDESK, label: "Criar" },
          { value: _Permissions.UPDATE_INTEGRATIONS_AGIDESK, label: "Atualizar" },
          { value: _Permissions.DELETE_INTEGRATIONS_AGIDESK, label: "Excluir" },
        ]
      },
      {
        title: "Whatsapp",
        permission: [
          { value: _Permissions.VIEW_INTEGRATIONS_WHATSAPP, label: "Visualizar" },
          { value: _Permissions.CREATE_INTEGRATIONS_WHATSAPP, label: "Criar" },
          { value: _Permissions.UPDATE_INTEGRATIONS_WHATSAPP, label: "Atualizar" },
          { value: _Permissions.DELETE_INTEGRATIONS_WHATSAPP, label: "Excluir" },
        ]
      },
      {
        title: "Agibot",
        permission: [
          { value: _Permissions.VIEW_INTEGRATIONS_AGIBOT, label: "Visualizar" },
          { value: _Permissions.CREATE_INTEGRATIONS_AGIBOT, label: "Criar" },
          { value: _Permissions.UPDATE_INTEGRATIONS_AGIBOT, label: "Atualizar" },
          { value: _Permissions.DELETE_INTEGRATIONS_AGIBOT, label: "Excluir" },
        ]
      },
      {
        title: "VTEX",
        permission: [
          { value: _Permissions.VIEW_INTEGRATIONS_VTEX, label: "Visualizar" },
          { value: _Permissions.CREATE_INTEGRATIONS_VTEX, label: "Criar" },
          { value: _Permissions.UPDATE_INTEGRATIONS_VTEX, label: "Atualizar" },
          { value: _Permissions.DELETE_INTEGRATIONS_VTEX, label: "Excluir" },
        ]
      },
    ]
  },
];

const Divider = () => {
  return (
    <div className="py-3">
      <div className="border-t border-gray-200" />
    </div>
  );
};

const Permissions = () => {
  const { permissions: { readOnly, onChange } } = usePermissiongroups({});
  const { selectedLocalPermissiongroup } = useAppSelector(
    (state) => state.permissiongroups
  );

  const permissions = useMemo(() =>
    selectedLocalPermissiongroup?.permissions || [],
    [selectedLocalPermissiongroup?.permissions]
  );
console.log('permissions :>> ', permissions.length);
  return (
    <Form>
      {modules.map((module, m) => (
        <div
          key={`permission-module-${m}-${module.title}`}
        >
          <Form.Group className="!mb-0">
            <Toggle
              containerClass="flex"
              checked={module.permission.every(p => permissions.indexOf(p.value) > -1)}
              readOnly={readOnly}
              simple
              mainClass="items-center flex"
              onChange={async (_, { checked }) => {
                const _permissions = new Set(permissions);
                const method = checked ? "add" : "delete";
                module.permission.forEach(p => _permissions[method](p.value));
                module.submodules?.forEach(sub =>
                  sub.permission.forEach(p => _permissions[method](p.value))
                );

                onChange({
                  field: "permissions",
                  value: [..._permissions],
                })
              }}
              labelClass="ml-2"
              label={module.title}
            />
          </Form.Group>

          <div className="pl-6 mb-6">
            <Divider />
            <Form.Group className="gap-12 !mb-0 py-3">
              {module.permission.map((permission, p) => {
                return (
                  <Toggle
                    key={`permission-${p}-${permission.value}`}
                    containerClass="flex"
                    checked={permissions.indexOf(permission.value) > -1}
                    readOnly={readOnly}
                    simple
                    mainClass="items-center flex"
                    onChange={async (_, { checked }) => {
                      const _permissions = new Set(permissions);
                      const method = checked ? "add" : "delete";
                      _permissions[method](permission.value)
      
                      onChange({
                        field: "permissions",
                        value: [..._permissions],
                      })
                    }}
                    labelClass="ml-2 font-[400]"
                    label={permission.label}
                  />
                )
              })}
            </Form.Group>

            {module.submodules?.map((submodule, s) => (
              <div key={`submodule-${s}-${submodule.title}`} className="pl-6 mt-4">
                <Form.Group className="!mb-0">
                  <Toggle
                    containerClass="flex"
                    checked={submodule.permission.every(p => permissions.indexOf(p.value) > -1)}
                    readOnly={readOnly}
                    simple
                    mainClass="items-center flex"
                    onChange={async (_, { checked }) => {
                      const _permissions = new Set(permissions);
                      const method = checked ? "add" : "delete";
                      submodule.permission.forEach(p => _permissions[method](p.value));

                      onChange({
                        field: "permissions",
                        value: [..._permissions],
                      })
                    }}
                    labelClass="ml-2"
                    label={submodule.title}
                  />
                </Form.Group>

                <div className="pl-6">
                  <Divider />
                  <Form.Group className="gap-12 !mb-0 py-3">
                    {submodule.permission.map((_permission, _p) => (
                      <Toggle
                        key={`permission-${_p}-${_permission.value}`}
                        containerClass="flex"
                        checked={permissions.indexOf(_permission.value) > -1}
                        readOnly={readOnly}
                        simple
                        mainClass="items-center flex"
                        onChange={async (_, { checked }) => {
                          const _permissions = new Set(permissions);
                          const method = checked ? "add" : "delete";
                          _permissions[method](_permission.value)
          
                          onChange({
                            field: "permissions",
                            value: [..._permissions],
                          })
                        }}
                        labelClass="ml-2 font-[400]"
                        label={_permission.label}
                      />
                    ))}
                  </Form.Group>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </Form>
  );
};
export default Permissions;
