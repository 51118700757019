/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { SectionPageProps } from "../../pages/Default.page";
import {
  ICampaign,
  addTargetsToCampaign,
  deleteCampaign,
  getTargetsByCampaignId,
  reselectCampaign,
  reselectLocalCampaign,
  toggleStartCampaign,
  updateCampaign,
} from "../../slices/campaigns.slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ddOptions } from "../../shared/models/interfaces/dropdownOptions.interface";
import {
  authService,
  botmakerService,
  campaignsService,
  customersService,
  externalappsService,
  tagsService,
  teamsService,
  usersService,
  whatsappService,
} from "../../services";
import { IUser } from "../../slices/users.slice";
import {
  DocumentWpp,
  ExampleComponent,
  FileTemplateVars,
  ITemplate,
  ITemplateVariables,
  ITemplateVarsArray,
  ImageWpp,
  TextTemplateVars,
  VideoWpp,
  reselectTemplateFiles,
  reselectTemplateMessage,
  selectTemplate,
} from "../../slices/messages.slice";
import { IAcceptedFiles } from "../../shared/models/interfaces/acceptedfile.interface";
import { ICustomer } from "../../slices/customers.slice";
import useUserIsAdmin from "../useUserIsAdmin";
import customToast from "../../shared/utils/customToast";
import { IToastType } from "../../shared/models/types/Toast.type";
import { IFile } from "../../shared/models/interfaces/file.interface";
import campaignBatchesService from "../../services/campaignBatches.service";
import { ITag, TagType } from "../../slices/tags.slice";
import {
  ExternalAppType,
  IExternalAppBotmaker,
  IExternalAppWhatsApp,
} from "../../slices/externalapps.slice";
import { handleMaskRegex } from "../../shared/utils/handleMaskLength";
import useUserIsSupervisor from "../useUserIsSupervisor";
import useAccessControl from "../../hooks/useAccessControl";
import { Permissions } from "../../slices/permissiongroups.slice";

const useCampaigns = () => {
  const { canAccess } = useAccessControl();
  interface sectionDetailsObject {
    [key: string]: SectionPageProps;
  }
  const URL = process.env.REACT_APP_API_BASE_URL;
  const {
    selectedCampaign,
    selectedLocalCampaign,
    selectedCampaignTargets,
    selectedTotalCampaignTargets,
    isLoadingCampaignTargets,
    isImportingTargets,
    disableStartCampaign,
  } = useAppSelector((state) => state.campaigns);
  const {
    selectedTemplateParameters,
    selectedTemplateMessage,
    selectedTemplateFiles,
    selectedTemplateFilesChanged,
    selectedTemplateButtons,
    selectedTemplate,
  } = useAppSelector((state) => state.messages);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();

  const [activePage, setActivePage] = useState<number>(1);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [instancesPerPage, setInstancesPerPage] = useState<number>(5);
  const [keyword, setKeyword] = useState<string>("");

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [showStartModal, setShowStartModal] = useState<boolean>(false);

  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);
  const [focusId, setFocusId] = useState<string>("");

  const [customerValue, setCustomerValue] = useState<string>("");
  const [tagValue, setTagValue] = useState<string>("");
  const [contactsValue, setContactsValue] = useState<string[]>([]);

  const [teamOptions, setTeamOptions] = useState<ddOptions[]>([]);
  const [userOptions, setUserOptions] = useState<ddOptions[]>([]);
  const [customerOptions, setCustomerOptions] = useState<ddOptions[]>([]);
  const [tagOptions, setTagOptions] = useState<ddOptions[]>([]);
  const [contactOptions, setContactOptions] = useState<ddOptions[]>([]);
  const [externalappOptions, setExternalappOptions] = useState<ddOptions[]>([]);
  const phoneNumberRef = useRef<string>("");
  const [templates, setTemplates] = useState<ITemplate[]>([
    // {
    //   name: "bem_vindo_botao_acao",
    //   components: [
    //     {
    //       type: "BODY",
    //       text: "Olá, seja bem-vindo(a) a {{1}} ! Obrigada por entrar em contato conosco, esperamos construir uma relação duradoura com você. Como podemos te ajudar? Segue em vídeo, algumas informações sobre nossa empresa! Fique a vontade para perguntar! {{2}}",
    //       example: {
    //         body_text: [["contact.name", "contact.tenant"]],
    //       },
    //     },
    //     {
    //       type: "FOOTER",
    //       text: "Não tem interesse? Toque em Parar promoções",
    //     },
    //     {
    //       type: "BUTTONS",
    //       buttons: [
    //         {
    //           type: "QUICK_REPLY",
    //           text: "Parar promoções",
    //         },
    //         {
    //           type: "URL",
    //           text: "Agidesk contato",
    //           url: "https://agidesk.com/{{1}}",
    //           example: ["https://agidesk.com/contato"],
    //         },
    //         {
    //           type: "URL",
    //           text: "Agidesk site",
    //           url: "https://agidesk.com/",
    //           example: ["https://agidesk.com/contato"],
    //         },
    //         {
    //           type: "PHONE_NUMBER",
    //           text: "Ligue",
    //           phone_number: "+5551992678151",
    //         },
    //         {
    //           type: "COPY_CODE",
    //           text: "Copiar código da oferta",
    //           example: ["AGIDESK15"],
    //         },
    //       ],
    //     },
    //   ],
    //   language: "pt_BR",
    //   status: "APPROVED",
    //   category: "MARKETING",
    //   id: "887397175934635",
    // },
    // {
    //   name: "bem_vindo_video2",
    //   components: [
    //     {
    //       type: "HEADER",
    //       format: "VIDEO",
    //       example: {
    //         header_handle: [
    //           "https://scontent.whatsapp.net/v/t61.29466-34/402923134_1400026750622903_984974011781653329_n.mp4?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=9Xl4AAQ7ZeQAX9S0X41&_nc_oc=AQm_rglTCzr6t5swCWNeI9gCd2ZfRHEE1rOaPlKXZ_7nQlDhWAvznl7dihx8obxslY4&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_AdSYkBnlCKlzmb0QodHLsdb-1uF-jrP4NSq9CgzpsDA-mA&oe=659AE32B",
    //         ],
    //       },
    //     },
    //     {
    //       type: "BODY",
    //       text: "Olá, seja bem-vindo(a) a {{1}}! Obrigada por entrar em contato conosco, esperamos construir uma relação duradoura com você. Como podemos te ajudar? Segue em vídeo, algumas informações sobre nossa empresa! Fique a vontade para perguntar! {{2}}",
    //       example: {
    //         body_text: [["contact.tenant", "contact.name"]],
    //       },
    //     },
    //   ],
    //   language: "pt_BR",
    //   status: "APPROVED",
    //   category: "MARKETING",
    //   id: "1400026747289570",
    // },
    // {
    //   name: "bem_vindo_header_documento2",
    //   components: [
    //     {
    //       type: "HEADER",
    //       format: "DOCUMENT",
    //       example: {
    //         header_handle: [
    //           "https://scontent.whatsapp.net/v/t61.29466-34/406070017_333886766023451_414511576035057112_n.pdf?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=9KY63axxMWIAX9Fdwbt&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_AdTkiRo7DSbQIQWNWcGYd3YhXwxQoXT5hC2zAxgVL99lnA&oe=659B0F5F",
    //         ],
    //       },
    //     },
    //     {
    //       type: "BODY",
    //       text: "Olá, seja bem-vindo(a) a {{1}}! Obrigada por entrar em contato conosco, esperamos construir uma relação duradoura com você. Como podemos te ajudar? Segue em anexo, algumas informações sobre nossa empresa! Fique a vontade para perguntar! {{2}}",
    //       example: {
    //         body_text: [["contact.name", "contact.phone"]],
    //       },
    //     },
    //   ],
    //   language: "pt_BR",
    //   status: "APPROVED",
    //   category: "MARKETING",
    //   id: "333886762690118",
    // },
    // {
    //   name: "bem_vindo",
    //   components: [
    //     {
    //       type: "HEADER",
    //       format: "IMAGE",
    //       example: {
    //         header_handle: [
    //           "https://scontent.whatsapp.net/v/t61.29466-34/367990400_725283619030726_183987663683710695_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=bN1P3nC42M8AX-XoKlS&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_AdSr725Xynjy3Shtr8QWHBhHDWrczpzfw-sQQ2fHBHd9BQ&oe=659AEA6C",
    //         ],
    //       },
    //     },
    //     {
    //       type: "BODY",
    //       text: "Olá, seja bem-vindo(a) a {{1}}! Obrigada por entrar em contato conosco, esperamos construir uma relação duradoura com você. Como podemos te ajudar? Segue em anexo, algumas informações sobre nossa empresa! Fique a vontade para perguntar!",
    //       example: {
    //         body_text: [["company_name"]],
    //       },
    //     },
    //   ],
    //   language: "pt_BR",
    //   status: "APPROVED",
    //   category: "UTILITY",
    //   id: "725282375697517",
    // },
    // {
    //   name: "lead_agitalks",
    //   components: [
    //     {
    //       type: "BODY",
    //       text: "Olá, {{1}} como você está?\n\nEstamos escrevendo para você de atendimento, o motivo do nosso contato é saber se você ainda está interessado em nossos serviços e ver se podemos bater um papo para explicar de forma concisa como podemos ajudá-lo em seu negócio 🙂\n\nUma saudação!\nEquipe {{2}}",
    //       example: {
    //         body_text: [["agent.name", "contact.tenant"]],
    //       },
    //     },
    //     {
    //       type: "FOOTER",
    //       text: "Não tem interesse? Toque em Parar promoções",
    //     },
    //   ],
    //   language: "pt_BR",
    //   status: "APPROVED",
    //   category: "UTILITY",
    //   id: "872379727779908",
    // },
  ]);
  const [templateOptions, setTemplateOptions] = useState<ddOptions[]>([]);
  const [fileImport, setFileImport] = useState<IAcceptedFiles>([]);
  const [filesTemplate, setFilesTemplate] = useState<IAcceptedFiles>([]);
  const [templateButtons, setTemplateButtons] = useState({
    url: "",
    code: "",
  });
  const templateObject = useRef<{
    templatevars: string;
    templatejson: string;
    templatefiles: string[];
  }>({
    templatejson: "",
    templatevars: "",
    templatefiles: [],
  });

  const buildParams = async () => {
    const templatevars: ITemplateVarsArray = [];
    const getMessageError = (_files: string) => {
      if (_files.toLowerCase().includes("unsupported")) {
        return t("toast.error.files.unsupported");
      }
      if (_files.toLowerCase().includes("too large"))
        return t("toast.error.files.too-large");
      return t("toast.error.files.upload");
    };

    const defaultVars = [
      "{{contact.name}}",
      "{{contact.tenant}}",
      "{{contact.phone}}",
      "{{contact.email}}",
      // "{{user.name}}",
      // "{{user.email}}",
    ];

    const getFiles = () => {
      const _files: IFile[] | string | null = [];
      if (
        typeof selectedLocalCampaign?.templatevars !== "undefined" &&
        selectedLocalCampaign?.templatevars &&
        selectedLocalCampaign?.templatevars.length > 0
      ) {
        const _templatevars: ITemplateVarsArray = JSON.parse(
          selectedLocalCampaign.templatevars
        );
        if (
          typeof _templatevars !== "undefined" &&
          _templatevars &&
          _templatevars.length > 0
        ) {
          _templatevars.forEach((_templatevar) => {
            if (
              _templatevar.key === "{{FILE-1}}" &&
              typeof _templatevar?.file !== "undefined"
            ) {
              _files.push(_templatevar.file);
            }
          });
        }
      }
      return _files;
    };

    // const _files: IFile[] | string | null = [];
    let _files: IFile[] | string | null = getFiles();
    // let fileIds: string[] = selectedCampaign?.templatefiles || [];
    let fileIds: string[] = [];
    if (
      selectedTemplateFilesChanged === true &&
      selectedTemplateFiles.length > 0
    ) {
      if (
        typeof selectedLocalCampaign?.externalapp?.type !== "undefined" &&
        selectedLocalCampaign?.externalapp?.type === ExternalAppType.WHATSAPP
      ) {
        _files = await authService.uploadFileWhatsApp({
          _files: selectedTemplateFiles,
          externalapp:
            selectedLocalCampaign &&
            typeof selectedLocalCampaign?.externalapp === "string"
              ? selectedLocalCampaign?.externalapp
              : selectedLocalCampaign?.externalapp?._id,
        });
      } else if (
        typeof selectedLocalCampaign?.externalapp?.type !== "undefined" &&
        selectedLocalCampaign?.externalapp?.type === ExternalAppType.BOTMAKER
      ) {
        _files = await authService.uploadFile(
          selectedTemplateFiles,
          true,
          undefined,
          true
        );
      }

      if (typeof _files === "string") {
        customToast({
          type: IToastType.ERROR,
          message: getMessageError(_files),
        });
        return;
      }
    }

    if (
      typeof _files !== "undefined" &&
      _files !== null &&
      _files?.length > 0
    ) {
      fileIds = _files.map((file) => {
        if (
          typeof file._id !== "undefined" &&
          typeof file.path !== "undefined" &&
          typeof file.name !== "undefined"
        ) {
          const _objFile: FileTemplateVars = {
            key: "{{FILE-1}}",
            type: "file",
            file,
            url: `${URL}/api/files/${file.path}/${file.name}`,
          };
          templatevars.push(_objFile);
        }
        dispatch(
          reselectTemplateFiles({
            files: { ...selectedTemplateFiles },
            currentChanged: false,
          })
        );
        return file?._id || "";
      });
    }

    let wppTemplateVariables: ITemplateVariables = {};
    const section: string[] = ["body_text", "header_text"];
    for (const item of section) {
      const _selectedTemplate: any = selectedTemplate;
      const _template =
        typeof _selectedTemplate?.components[0]?.example !== "undefined" &&
        typeof _selectedTemplate?.components[0]?.example[
          item as keyof ExampleComponent
        ] !== "undefined"
          ? _selectedTemplate?.components[0]?.example[
              item as keyof ExampleComponent
            ]
          : typeof _selectedTemplate?.components[1]?.example !== "undefined" &&
            typeof _selectedTemplate?.components[1]?.example[
              item as keyof ExampleComponent
            ] !== "undefined"
          ? _selectedTemplate?.components[1]?.example[
              item as keyof ExampleComponent
            ]
          : null;
      const componentVariables =
        typeof _template !== "undefined" ? _template : null;

      if (typeof componentVariables !== "undefined" && componentVariables) {
        wppTemplateVariables = {
          ...wppTemplateVariables,
          [item]: componentVariables,
        };
      }
    }

    const _parameters = selectedTemplateParameters?.map((_obj) => {
      return {
        type:
          _obj.type.toLowerCase() === "buttons"
            ? "button"
            : _obj.type.toLowerCase(),
        sub_type: _obj.sub_type,
        index: _obj.index,
        parameters: _obj.parameters.map((_p) => {
          const isAgibot =
            selectedLocalCampaign?.externalapp?.type ===
            ExternalAppType.BOTMAKER;
          if (_p.type === "image") {
            if (
              typeof _files !== "undefined" &&
              _files !== null &&
              _files?.length > 0
            ) {
              let _parameter = {};
              if (isAgibot) {
                _parameter = {
                  id: (_files as IFile[])[0]?._id,
                  filepath: (_files as IFile[])[0]?.path || undefined,
                  filename:
                    `${(_files as IFile[])[0]?.name}` ||
                    `imagem.${(_files as IFile[])[0]?.extension}` ||
                    undefined,
                };
              } else {
                _parameter = {
                  id: (_files as IFile[])[0]?.referenceid,
                };
              }
              const _parametervars = {
                id: (_files as IFile[])[0]?._id,
              };
              const _returnObj = {
                key: _p.key,
                type: _p.type,
                image: _parametervars,
              };
              templatevars.push(_returnObj);
              const _returnObjectWithoutKey = {
                ..._returnObj,
                image: _parameter,
              };
              delete _returnObjectWithoutKey.key;
              return _returnObjectWithoutKey;
            }
            const _returnObj = {
              key: _p.key,
              type: _p.type,
              image: _p.image as ImageWpp,
            };
            templatevars.push(_returnObj);
            const _returnObjectWithoutKey = { ..._returnObj };
            delete _returnObjectWithoutKey.key;
            return _returnObjectWithoutKey;
          }
          if (_p.type === "video") {
            if (
              typeof _files !== "undefined" &&
              _files !== null &&
              _files?.length > 0
            ) {
              let _parameter = {};
              if (isAgibot) {
                _parameter = {
                  id: (_files as IFile[])[0]?._id,
                  filepath: (_files as IFile[])[0]?.path || undefined,
                  filename:
                    `${(_files as IFile[])[0]?.name}` ||
                    `video.${(_files as IFile[])[0]?.extension}` ||
                    undefined,
                };
              } else {
                _parameter = {
                  id: (_files as IFile[])[0]?.referenceid,
                };
              }
              const _parametervars = {
                id: (_files as IFile[])[0]?._id,
              };
              const _returnObj = {
                key: _p.key,
                type: _p.type,
                video: _parametervars,
              };
              templatevars.push(_returnObj);
              const _returnObjectWithoutKey = {
                ..._returnObj,
                video: _parameter,
              };
              delete _returnObjectWithoutKey.key;
              return _returnObjectWithoutKey;
            }
            const _returnObj = {
              key: _p.key,
              type: _p.type,
              video: _p.video as VideoWpp,
            };
            templatevars.push(_returnObj);
            const _returnObjectWithoutKey = { ..._returnObj };
            delete _returnObjectWithoutKey.key;
            return _returnObjectWithoutKey;
          }
          if (_p.type === "document") {
            if (
              typeof _files !== "undefined" &&
              _files !== null &&
              _files?.length > 0
            ) {
              let _parameter = {};
              if (isAgibot) {
                _parameter = {
                  id: (_files as IFile[])[0]?._id,
                  filepath: (_files as IFile[])[0]?.path || "",
                  filename: `${(_files as IFile[])[0]?.name}` || "arquivo.pdf",
                };
              } else {
                _parameter = {
                  id: (_files as IFile[])[0]?.referenceid,
                  filename: `${(_files as IFile[])[0]?.name}` || "arquivo.pdf",
                };
              }
              const _parametervars = {
                id: (_files as IFile[])[0]?._id,
                filename: `${(_files as IFile[])[0]?.name}` || "arquivo.pdf",
              };
              const _returnObj = {
                key: _p.key,
                type: _p.type,
                document: _parametervars,
              };
              templatevars.push(_returnObj);
              const _returnObjectWithoutKey = {
                ..._returnObj,
                document: _parameter,
              };
              delete _returnObjectWithoutKey.key;
              return _returnObjectWithoutKey;
            }
            const _returnObj = {
              key: _p.key,
              type: _p.type,
              document: _p.document as DocumentWpp,
            };
            templatevars.push(_returnObj);
            const _returnObjectWithoutKey = { ..._returnObj };
            delete _returnObjectWithoutKey.key;
            return _returnObjectWithoutKey;
          }
          if (_p.type === "coupon_code") {
            const _returnObj = {
              key: _p.key,
              type: _p.type,
              coupon_code: selectedTemplateButtons.code,
            };
            templatevars.push(_returnObj);
            const _returnObjectWithoutKey = { ..._returnObj };
            delete _returnObjectWithoutKey.key;
            return _returnObjectWithoutKey;
          }
          if (_p.type === "url") {
            const _returnObj = {
              key: _p.key,
              type: _p.type,
              text: selectedTemplateButtons.url,
            };
            templatevars.push(_returnObj);
            const _returnObjectWithoutKey = { ..._returnObj };
            delete _returnObjectWithoutKey.key;
            return _returnObjectWithoutKey;
          }
          if (
            typeof _p?.text !== "undefined" &&
            _p.text.length > 0 &&
            _p.key !== _p.text &&
            defaultVars.includes(_p.text)
          ) {
            dispatch(
              reselectTemplateMessage({
                index: `</>${_p.key}</>`,
                newValue: _p.text,
              })
            );
          }
          const _returnObj = {
            key: _p.key,
            type: _p.type,
            text: _p.text,
          } as TextTemplateVars;
          templatevars.push(_returnObj);
          const _returnObjectWithoutKey = { ..._returnObj };
          delete _returnObjectWithoutKey.key;
          return _returnObjectWithoutKey;
        }),
      };
    });

    const templatejson = {
      name: selectedTemplate?.name,
      language: selectedTemplate?.language,
      components: _parameters || [],
      phone: selectedTemplate?.phone,
      variables:
        selectedLocalCampaign?.externalapp?.type === ExternalAppType.BOTMAKER
          ? wppTemplateVariables
          : undefined,
    };

    templateObject.current = {
      templatejson: JSON.stringify(templatejson),
      templatevars: JSON.stringify(templatevars),
      templatefiles: fileIds,
    };
  };

  const back = () => {
    if (
      location.pathname.includes("/batches") &&
      typeof selectedLocalCampaign?._id !== "undefined"
    ) {
      navigate(`/settings/campaign/${selectedLocalCampaign?._id}`);
    } else {
      navigate("/settings/campaigns");
      dispatch(reselectCampaign(null));
      dispatch(reselectLocalCampaign(null));
    }
  };

  const remove = async (
    setDisable: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (setDisable) setDisable(true);
    setShowDeleteModal(false);
    if (selectedLocalCampaign?._id) {
      const { payload } = await dispatch(
        deleteCampaign([selectedLocalCampaign._id])
      );
      if (setDisable) setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/campaigns");
      }
    }
  };

  const reactive = async (
    setDisable: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (setDisable) setDisable(true);
    setShowReactiveModal(false);
    const _campaign = {
      ...selectedLocalCampaign,
      team: selectedLocalCampaign?.team?._id,
      user: selectedLocalCampaign?.user?._id,
      externalapp:
        typeof selectedLocalCampaign?.externalapp === "string"
          ? selectedLocalCampaign?.externalapp
          : selectedLocalCampaign?.externalapp?._id,
      template: selectedLocalCampaign?.template || "",
      templatecontent: selectedLocalCampaign?.templatecontent || "",
      templatejson: selectedLocalCampaign?.templatejson || "",
      templatevars: selectedLocalCampaign?.templatevars || "",
      templatefiles: selectedLocalCampaign?.templatefiles || [],
      active: true,
      deleted: false,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateCampaign({ _campaign }));
    if (setDisable) setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const save = async (
    setDisable: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (setDisable) setDisable(true);
    await buildParams();
    const _campaign = {
      ...selectedLocalCampaign,
      team: selectedLocalCampaign?.team?._id || null,
      user: selectedLocalCampaign?.user?._id || null,
      externalapp:
        typeof selectedLocalCampaign?.externalapp === "string"
          ? selectedLocalCampaign?.externalapp
          : selectedLocalCampaign?.externalapp?._id,
      template: selectedLocalCampaign?.template || "",
      templatecontent:
        selectedTemplateMessage
          .replaceAll("</>", "")
          .replaceAll("undefined", "") || "",
      templatejson: templateObject.current.templatejson || "",
      templatevars: templateObject.current.templatevars || "",
      templatefiles: templateObject.current.templatefiles || [],
      templatephone: selectedLocalCampaign?.templatephone || "",
    };
    const { payload } = await dispatch(updateCampaign({ _campaign }));
    if (typeof payload._id !== "undefined") {
      dispatch(reselectLocalCampaign(payload));
    }
    if (setDisable) setDisable(false);
  };

  const start = async (
    setDisable: React.Dispatch<React.SetStateAction<boolean>>,
    name: string
  ) => {
    if (setDisable) setDisable(true);
    await buildParams();
    const _campaign = {
      ...selectedLocalCampaign,
      team: selectedLocalCampaign?.team?._id || null,
      user: selectedLocalCampaign?.user?._id || null,
      externalapp:
        typeof selectedLocalCampaign?.externalapp === "string"
          ? selectedLocalCampaign?.externalapp
          : selectedLocalCampaign?.externalapp?._id,
      templatecontent: selectedTemplateMessage
        .replaceAll("</>", "")
        .replaceAll("undefined", ""),
      templatejson: templateObject.current.templatejson,
      templatevars: templateObject.current.templatevars,
      templatefiles: templateObject.current.templatefiles,
      templatephone: selectedLocalCampaign?.templatephone,
    } as unknown as ICampaign;
    const _payload = await campaignsService.update({ _campaign });
    if (typeof _payload._id !== "undefined") {
      dispatch(reselectLocalCampaign(_payload));
      const _scheduledatte = format(new Date(), "yyyy-MM-dd");
      if (
        typeof selectedLocalCampaign?._id !== "undefined" &&
        typeof name !== "undefined" &&
        name.length > 0 &&
        typeof _scheduledatte !== "undefined"
      ) {
        const payload = await campaignBatchesService.startCampaign({
          campaign: selectedLocalCampaign?._id,
          name,
          scheduledate: _scheduledatte,
        });
        if (
          payload !== null &&
          typeof payload !== "undefined" &&
          typeof payload?.message !== "string"
        ) {
          customToast({
            type: IToastType.SUCCESS,
            message:
              "Campanha iniciada com sucesso! O envio do lote iniciará em breve",
          });
          // dispatch(selectTemplate({ template: null }));
          navigate(`/settings/campaign/${selectedLocalCampaign?._id}/batches`);
        } else {
          customToast({
            type: IToastType.ERROR,
            message: "A campanha não pode ser iniciada. Tente novamente",
          });
        }
      }
    }
    if (setDisable) setDisable(false);
    setShowStartModal(false);
  };

  const getButtonDisable = () => {
    if (typeof selectedTemplate?.components !== "undefined") {
      let buttonDisable = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in Object.keys(selectedTemplate.components)) {
        if (
          (selectedTemplate?.components[key].type === "button" &&
            selectedTemplate?.components[key].sub_type === "url" &&
            selectedTemplateButtons.url.length === 0) ||
          (selectedTemplate?.components[key].type === "button" &&
            selectedTemplate?.components[key].sub_type === "copy_code" &&
            selectedTemplateButtons.code.length === 0)
        ) {
          buttonDisable = true;
        }
      }
      return buttonDisable;
    }
    return true;
  };

  const updateStartDisabled = (targets: number) => {
    dispatch(
      toggleStartCampaign(
        getButtonDisable() ||
          selectedTemplateMessage.length === 0 ||
          selectedTemplateMessage.includes("{{H-") ||
          selectedTemplateMessage.includes("{{B-") ||
          typeof selectedLocalCampaign?.template === "undefined" ||
          !selectedLocalCampaign?.template ||
          targets === 0 ||
          (selectedLocalCampaign?.sendemail &&
            (typeof selectedLocalCampaign?.email === "undefined" ||
              selectedLocalCampaign?.email?.length === 0)) ||
          (selectedLocalCampaign?.createchat === true &&
            typeof selectedLocalCampaign?.team?._id === "undefined") ||
          (typeof selectedTemplate?.components[0]?.example?.header_handle !==
            "undefined" &&
            selectedTemplateFiles.length === 0)
      )
    );
  };

  // ========================== TARGET GRID ==========================================

  const client = useQueryClient();

  const addMutation = useMutation({
    mutationFn: async () => {
      if (
        typeof selectedLocalCampaign?._id !== "undefined" &&
        (contactsValue.length > 0 || fileImport.length > 0)
      ) {
        const { payload } = await dispatch(
          addTargetsToCampaign({
            _id: selectedLocalCampaign?._id,
            file: fileImport.length === 1 ? fileImport[0] : undefined,
            customer:
              contactsValue.length > 0 && contactsValue.includes("-1")
                ? customerValue
                : undefined,
            tag:
              contactsValue.length > 0 && contactsValue.includes("-1")
                ? tagValue
                : undefined,
            target:
              contactsValue.length > 0 ? contactsValue.toString() : undefined,
          })
        );
        if (typeof payload?.count !== "undefined") {
          updateStartDisabled(payload?.count);
        }
      }
    },
    onSuccess: () => {
      setCustomerOptions([]);
      setCustomerValue("");
      setContactOptions([]);
      setContactsValue([]);
      setFileImport([]);
      customToast({
        type: IToastType.SUCCESS,
        message: "Alvos vinculados a campanha com sucesso",
      });
      client.invalidateQueries({
        queryKey: ["targets", skip, limit, keyword],
      });
    },
    onError: () => {
      customToast({
        type: IToastType.ERROR,
        message: "Algo deu errado na importação",
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (target: string[]) => {
      if (
        typeof selectedLocalCampaign?._id !== "undefined" &&
        target.length > 0
      ) {
        await campaignsService.removeTargetsFromCampaign({
          _id: selectedLocalCampaign?._id,
          target,
        });
      }
    },
    onSuccess: () => {
      customToast({
        type: IToastType.SUCCESS,
        message: "Alvos removidos da campanha com sucesso",
      });
      client.invalidateQueries({
        queryKey: ["targets", skip, limit, keyword],
      });
    },
    onError: () => {
      customToast({
        type: IToastType.ERROR,
        message: "Algo deu errado na remoção dos alvos",
      });
    },
  });

  const { data } = useQuery({
    refetchOnWindowFocus: false,
    initialData: { results: [], count: 0 },
    enabled:
      selectedLocalCampaign !== null &&
      typeof selectedLocalCampaign?._id !== "undefined" &&
      location.pathname === `/settings/campaign/${selectedLocalCampaign?._id}`,
    queryKey: ["targets", skip, limit, keyword],
    queryFn: async () => {
      const { payload } = await dispatch(
        getTargetsByCampaignId({
          _id: selectedLocalCampaign?._id as string,
          skip,
          limit,
          filter: keyword,
        })
      );
      // if (typeof payload?.count !== "undefined") {
      //   updateStartDisabled(payload?.count);
      // }
      return payload;
    },
  });
  // =================================================================================

  const startDisabledCallback = useCallback(() => {
    if (typeof data?.count !== "undefined") {
      updateStartDisabled(data.count);
    }
  }, [
    selectedTemplateFiles.length,
    selectedTemplateButtons,
    selectedTemplateMessage,
    data.count,
    selectedLocalCampaign?.team,
    selectedLocalCampaign?.sendemail,
    selectedLocalCampaign?.email,
    selectedLocalCampaign?.createchat,
  ]);

  const getValueUsers = useCallback(() => {
    if (userOptions.length === 0) return "";
    // if (userOptions.length === 1) return userOptions[0].value as string;
    if (
      selectedLocalCampaign &&
      typeof selectedLocalCampaign?.user === "string"
    )
      return selectedLocalCampaign?.user;
    return selectedLocalCampaign?.user?._id;
  }, [selectedLocalCampaign, userOptions]);

  const getValueTemplates = useCallback(() => {
    // if (templateOptions.length === 1) return templateOptions[0].value as string;
    if (
      templateOptions.length > 0 &&
      selectedLocalCampaign &&
      typeof selectedLocalCampaign?.template === "string"
    )
      return selectedLocalCampaign?.template;
    return "";
  }, [selectedLocalCampaign, templateOptions, phoneNumberRef.current]);

  const sectionDetails: sectionDetailsObject = {
    infos: {
      id: "campaign-info",
      icon: "las la-info-circle",
      title: "Informações",
      description: "Edite as informações exibidas",
    },
    team: {
      id: "campaign-chat",
      icon: "las la-comments",
      title: "Configuração de atendimento",
      description: `Defina se serão geradas conversas para a campanha.\nConfigure a equipe e o agente atribuídos nas conversas geradas.\nAs equipes disponíveis são apenas as configuradas na integração escolhida.`,
    },
    email: {
      id: "campaign-email",
      icon: "las la-at",
      title: "E-mails",
      description: `Informe os e-mails de quem será notificado.\nSepare os e-mails por ponto-e-vírgula ou pressionando enter.\nO envio de e-mails é sempre ao final do envio do lote ou em casos de erro.`,
    },
    template: {
      id: "campaign-template",
      icon: "las la-copy",
      title: "Template",
      description: `Selecione e configure o template que será enviado na campanha.`,
    },
    targets: {
      id: "campaign-targets",
      icon: "las la-bullseye",
      title: "Alvos",
      description: `Configure os alvos da campanha, selecionando-os através dos respectivos clientes, com a opção de filtro de marcadores, ou através de uma planilha importada.\n\nFaça o download do modelo da planilha, se optar por esse formato.`,
    },
  };

  const updateCampaignData = ({
    field,
    value,
  }: {
    field: string;
    value:
      | undefined
      | null
      | string
      | boolean
      | number
      | string[]
      | {
          _id: string;
          name: string;
        }
      | {
          _id: string;
          name: string;
        }[];
  }) => {
    if (selectedLocalCampaign) {
      if (field === "template") {
        const _selected = templates.find((_temp) => _temp.id === value) || null;
        dispatch(
          selectTemplate({
            template: _selected,
            campaignMode: true,
            campaignVars:
              value === selectedCampaign?.template
                ? selectedCampaign?.templatevars
                : undefined,
          })
        );

        dispatch(
          reselectLocalCampaign({
            ...selectedLocalCampaign,
            template: value as string,
            templatephone: _selected?.phone as string,
          })
        );
      } else {
        dispatch(
          reselectLocalCampaign({ ...selectedLocalCampaign, [field]: value })
        );
      }
    } else {
      dispatch(reselectLocalCampaign(null));
    }
  };

  const onBlur = () => {
    setFocusId("");
  };

  // const checkUsers = async (_teamid: string, _teamname: string) => {
  //   setIsLoadingDropdown(true);
  //   let _id = "";
  //   let name = "";
  //   if (typeof _teamid !== "undefined" && _teamid) {
  //     _id = _teamid;
  //   } else if (typeof selectedLocalCampaign?.team === "string") {
  //     _id = selectedLocalCampaign?.team as string;
  //   } else if (typeof selectedLocalCampaign?.team?._id !== "undefined") {
  //     _id = selectedLocalCampaign?.team._id as string;
  //   }
  //   if (typeof _teamname !== "undefined" && _teamname.length > 0) {
  //     name = _teamname;
  //   }
  //   if (typeof _id === "string" && _id.length > 0) {
  //     const payload = await teamsService.postChatusersByTeamId({
  //       _id,
  //       campaign: true,
  //     });
  //     if (
  //       typeof payload !== "undefined" &&
  //       payload.length === 1 &&
  //       selectedCampaign !== null
  //     ) {
  //       dispatch(
  //         reselectLocalCampaign({
  //           ...selectedCampaign,
  //           team: { _id, name },
  //           user: { _id: payload[0]._id, name: payload[0].name },
  //         })
  //       );
  //       setUserOptions([
  //         {
  //           key: payload[0]._id,
  //           text: payload[0].name,
  //           value: payload[0]._id,
  //         },
  //       ]);
  //     }
  //     setIsLoadingDropdown(false);
  //   }
  // };

  const onOpenUsers = async () => {
    setIsLoadingDropdown(true);
    const _options: ddOptions[] = [];
    if (
      typeof selectedLocalCampaign?.team?._id !== "undefined" &&
      selectedLocalCampaign?.team?._id.length > 0
    ) {
      const payload = await teamsService.postChatusersByTeamId({
        _id: selectedLocalCampaign?.team?._id,
        campaign: true,
      });
      setIsLoadingDropdown(false);
      (payload as IUser[])?.forEach((_user) => {
        const _option: ddOptions = {
          key: _user?._id,
          text: _user?.name,
          value: _user?._id,
          content: (
            <div className="flex">
              <h1 className="mr-2">{_user?.name} </h1>
              {typeof _user?.email !== "undefined" ? (
                <h1 className="text-gray-text">({_user?.email})</h1>
              ) : null}
            </div>
          ),
        };
        _options.push(_option);
      });
    }
    setUserOptions(_options);
  };

  const onOpenCustomers = async () => {
    setIsLoadingDropdown(true);
    const _options: ddOptions[] = [];
    const payload = await customersService.search({
      skip: 0,
      limit: 10,
      filter: "",
      deleted: false,
    });
    setIsLoadingDropdown(false);
    if (typeof payload?.results !== "undefined") {
      (payload.results as ICustomer[])?.forEach((_customer) => {
        const _option: ddOptions = {
          key: _customer?._id,
          text: _customer?.name,
          value: _customer?._id,
        };
        _options.push(_option);
      });
    }
    setCustomerOptions(_options);
  };

  const onOpenTags = async () => {
    setIsLoadingDropdown(true);
    const _options: ddOptions[] = [];
    const payload = await tagsService.search({
      skip: 0,
      limit: 10,
      filter: {
        content: "",
        type: [TagType.CONTACT],
        active: true,
      },
    });
    setIsLoadingDropdown(false);
    if (typeof payload?.results !== "undefined") {
      (payload.results as ITag[])?.forEach((_tag) => {
        const _option: ddOptions = {
          key: _tag?._id,
          text: _tag?.name,
          value: _tag?._id,
        };
        _options.push(_option);
      });
    }
    setTagOptions(_options);
  };

  const onSearchCustomers = async (filter: string) => {
    setIsLoadingDropdown(true);
    const _options: ddOptions[] = [];
    const payload = await customersService.search({
      skip: 0,
      limit: 10,
      filter,
      deleted: false,
    });
    setIsLoadingDropdown(false);
    if (typeof payload?.results !== "undefined") {
      (payload.results as ICustomer[])?.forEach((_customer) => {
        const _option: ddOptions = {
          key: _customer?._id,
          text: _customer?.name,
          value: _customer?._id,
        };
        _options.push(_option);
      });
    }
    setCustomerOptions(_options);
  };

  const onSearchTags = async (filter: string) => {
    setIsLoadingDropdown(true);
    const _options: ddOptions[] = [];
    const payload = await tagsService.search({
      skip: 0,
      limit: 10,
      filter: {
        content: filter,
        type: [TagType.CONTACT],
        active: true,
      },
    });
    setIsLoadingDropdown(false);
    if (typeof payload?.results !== "undefined") {
      (payload.results as ITag[])?.forEach((_tag) => {
        const _option: ddOptions = {
          key: _tag?._id,
          text: _tag?.name,
          value: _tag?._id,
        };
        _options.push(_option);
      });
    }
    setTagOptions(_options);
  };

  const onSearchExternalapps = async (filter: string) => {
    setIsLoadingDropdown(true);
    const _options: ddOptions[] = [];
    const payload = await externalappsService.searchExternalApps({
      skip: 0,
      limit: 10,
      filter: {
        "fields.name": filter,
        type: [ExternalAppType.WHATSAPP, ExternalAppType.BOTMAKER],
      },
    });
    setIsLoadingDropdown(false);
    if (typeof payload?.results !== "undefined") {
      payload?.results?.map(
        (_externalApp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
          if (_externalApp.type === ExternalAppType.WHATSAPP) {
            const _externalWpp = _externalApp as IExternalAppWhatsApp;
            _options.push({
              key: _externalWpp._id,
              text: _externalWpp.fields.name,
              value: _externalWpp._id,
              content: (
                <div className="flex">
                  <h1 className="mr-2">{_externalWpp?.fields?.name}</h1>
                  {typeof _externalWpp?.fields?.displayphone !== "undefined" ? (
                    <h1 className="text-gray-text">
                      ({handleMaskRegex(_externalWpp?.fields?.displayphone)})
                    </h1>
                  ) : null}
                </div>
              ),
            });
          } else if (_externalApp.type === ExternalAppType.BOTMAKER) {
            const _externalBot = _externalApp as IExternalAppBotmaker;
            const _listPhones = _externalBot.fields.externalphones;
            if (typeof _listPhones !== "undefined" && _listPhones.length > 0) {
              _listPhones.map((_externalapp) => {
                return _options.push({
                  key: _externalapp.phone,
                  text: _externalapp.name,
                  value: `${_externalBot._id}|%@g1%|${_externalapp.phone}`,
                  content: (
                    <div className="flex">
                      <h1 className="mr-2">{_externalapp?.name}</h1>
                      {typeof _externalapp.phone !== "undefined" ? (
                        <h1 className="text-gray-text">
                          ({handleMaskRegex(_externalapp.phone)})
                        </h1>
                      ) : null}
                    </div>
                  ),
                });
              });
            }
          }
          return null;
        }
      );
    }
    setExternalappOptions(_options);
  };

  const onOpenContacts = async () => {
    const _filters: any = {
      content: "",
    };
    if (tagValue.length > 0) {
      _filters.tags = [tagValue];
    }
    setIsLoadingDropdown(true);
    const _options: ddOptions[] =
      customerValue.length > 0 || tagValue.length > 0
        ? [
            {
              key: "-1",
              text: "Adicionar todos",
              value: "-1",
            },
          ]
        : [];
    let _payload: IUser[] = [];
    if (customerValue.length > 0) {
      const payload = await usersService.getContactsByCustomerId({
        _id: customerValue,
      });
      _payload = payload;
    } else if (tagValue.length > 0) {
      const payload = await usersService.searchContacts({
        skip: 0,
        limit: 10,
        filter: _filters,
        deleted: false,
        spam: false,
        historySearch: true,
      });
      _payload = payload?.results;
    } else {
      const payload = await usersService.searchContacts({
        skip: 0,
        limit: 10,
        filter: "",
        deleted: false,
        spam: false,
        historySearch: true,
      });
      _payload = payload?.results;
    }
    setIsLoadingDropdown(false);
    if (typeof _payload !== "undefined") {
      (_payload as IUser[])?.forEach((_contact) => {
        const _option: ddOptions = {
          key: _contact?._id,
          text: _contact?.name,
          value: _contact?._id,
          content: (
            <div className="flex items-center">
              <h1 className="mr-2">{_contact?.name} </h1>
              {typeof _contact?.phone !== "undefined" ? (
                <h1 className="text-[12px] text-gray-text">
                  {handleMaskRegex(_contact?.phone)}
                </h1>
              ) : null}
            </div>
          ),
        };
        _options.push(_option);
      });
    }
    if (_options.length === 1 && _options[0].key === "-1") {
      setContactOptions([]);
    } else {
      setContactOptions(_options);
    }
  };

  const onSearchContacts = async (filter: string) => {
    const _filters: any = {
      content: typeof filter !== "undefined" ? filter : "",
    };
    if (tagValue.length > 0) {
      _filters.tags = [tagValue];
    }

    if (customerValue.length === 0) {
      setIsLoadingDropdown(true);
      const _options: ddOptions[] = contactOptions;
      let _payload: IUser[] = [];
      const payload = await usersService.searchContacts({
        skip: 0,
        limit: 10,
        filter: _filters,
        deleted: false,
        spam: false,
        historySearch: true,
      });
      _payload = payload?.results;
      setIsLoadingDropdown(false);
      if (typeof _payload !== "undefined") {
        (_payload as IUser[])?.forEach((_contact) => {
          if (!_options.map((_op) => _op.key).includes(_contact._id)) {
            const _option: ddOptions = {
              key: _contact?._id,
              text: _contact?.name,
              value: _contact?._id,
              content: (
                <div className="flex items-center">
                  <h1 className="mr-2">{_contact?.name} </h1>
                  {typeof _contact?.phone !== "undefined" ? (
                    <h1 className="text-[12px] text-gray-text">
                      {handleMaskRegex(_contact?.phone)}
                    </h1>
                  ) : null}
                </div>
              ),
            };
            _options.push(_option);
          }
        });
      }
      if (_options.length === 1 && _options[0].key === "-1") {
        setContactOptions([]);
      } else {
        setContactOptions(_options);
      }
    }
  };

  const onOpenTemplates = async () => {
    setIsLoadingDropdown(true);
    const _options: ddOptions[] = [];
    let externalappid = "";
    let externalapptype = ExternalAppType.WHATSAPP;

    if (
      typeof selectedLocalCampaign?.externalapp?._id !== "undefined" &&
      selectedLocalCampaign?.externalapp?._id.length > 0
    ) {
      externalappid = selectedLocalCampaign?.externalapp?._id;
      externalapptype = selectedLocalCampaign?.externalapp?.type;
    } else if (
      typeof selectedLocalCampaign?.externalapp === "string" &&
      selectedLocalCampaign?.externalapp?.length > 0
    ) {
      externalappid = selectedLocalCampaign.externalapp;
    }
    if (typeof externalappid !== "undefined" && externalappid.length > 0) {
      const _payload =
        externalapptype === ExternalAppType.WHATSAPP
          ? await whatsappService.templateWpp({ externalappid })
          : await botmakerService.templateWpp({ externalappid });

      const _phone =
        externalapptype === ExternalAppType.WHATSAPP
          ? ""
          : typeof selectedLocalCampaign?.externalappnumber !== "undefined" &&
            selectedLocalCampaign?.externalappnumber
          ? selectedLocalCampaign?.externalappnumber
          : phoneNumberRef.current;
      const payload =
        typeof _phone !== "undefined" &&
        _phone &&
        externalapptype !== ExternalAppType.WHATSAPP
          ? _payload.filter((_temp: any) => _temp.phone === _phone)
          : _payload;
      // const _payload =
      //   externalapptype === ExternalAppType.WHATSAPP
      //     ? await whatsappService.templateWpp({ externalappid })
      //     : await botmakerService.templateWpp({ externalappid });
      // const payload = !selectedLocalCampaign?.externalappnumber || !phoneNumberRef.current
      //   ? _payload
      //   : _payload.filter(
      //       (_temp: any) => _temp.phone === phoneNumberRef.current
      //     );

      // const payload = await whatsappService.templateWpp({ externalappid });
      if (typeof payload !== "undefined" && payload.length > 0) {
        payload?.forEach((template: ITemplate) => {
          _options.push({
            key: template.id,
            text: template.name,
            value: template.id,
          });
        });
        setTemplates(payload);
      }
      //   templates?.forEach((template: ITemplate) => {
      //     _options.push({
      //       key: template.id,
      //       text: template.name,
      //       value: template.id,
      //     });
      //   });
    }
    setTemplateOptions(_options);
    setIsLoadingDropdown(false);
  };

  const onOpenExternalapps = async () => {
    setIsLoadingDropdown(true);
    const _options: ddOptions[] = [];
    const payload = await externalappsService.searchExternalApps({
      skip: 0,
      limit: 10,
      filter: {
        types: [ExternalAppType.WHATSAPP, ExternalAppType.BOTMAKER],
      },
    });
    setIsLoadingDropdown(false);
    payload?.results?.map(
      (_externalApp: IExternalAppWhatsApp | IExternalAppBotmaker) => {
        if (_externalApp.type === ExternalAppType.WHATSAPP) {
          const _externalWpp = _externalApp as IExternalAppWhatsApp;
          _options.push({
            key: _externalWpp._id,
            text: _externalWpp.fields.name,
            value: _externalWpp._id,
            content: (
              <div className="flex">
                <h1 className="mr-2">{_externalWpp?.fields?.name}</h1>
                {typeof _externalWpp?.fields?.displayphone !== "undefined" ? (
                  <h1 className="text-gray-text">
                    ({handleMaskRegex(_externalWpp?.fields?.displayphone)})
                  </h1>
                ) : null}
              </div>
            ),
          });
        } else if (_externalApp.type === ExternalAppType.BOTMAKER) {
          const _externalBot = _externalApp as IExternalAppBotmaker;
          const _listPhones = _externalBot.fields.externalphones;
          if (typeof _listPhones !== "undefined" && _listPhones.length > 0) {
            _listPhones.map((_externalapp) => {
              return _options.push({
                key: _externalapp.phone,
                text: _externalapp.name,
                value: `${_externalBot._id}|%@g1%|${_externalapp.phone}`,
                content: (
                  <div className="flex">
                    <h1 className="mr-2">{_externalapp?.name}</h1>
                    {typeof _externalapp.phone !== "undefined" ? (
                      <h1 className="text-gray-text">
                        ({handleMaskRegex(_externalapp.phone)})
                      </h1>
                    ) : null}
                  </div>
                ),
              });
            });
          }
        }
        return null;
      }
    );
    setExternalappOptions(_options);
    setIsLoadingDropdown(false);
  };

  const onFocusUsers = () => {
    setFocusId("dropdown-campaign-user");
  };

  const onFocusTemplates = () => {
    setFocusId("dropdown-campaign-templates");
  };

  const onFocusExternalapps = () => {
    setFocusId("dropdown-campaign-externalapps");
  };

  const onFocusCustomers = () => {
    setFocusId("dropdown-campaign-import-customer");
  };

  const onFocusTags = () => {
    setFocusId("dropdown-campaign-import-tag");
  };

  const onFocusContacts = () => {
    setFocusId("dropdown-campaign-import-contact");
  };

  const dropdownOnFocus = {
    contact: onFocusContacts,
    customer: onFocusCustomers,
    tag: onFocusTags,
    template: onFocusTemplates,
    externalapp: onFocusExternalapps,
    user: onFocusUsers,
  };

  const dropdownOptions = {
    contact: contactOptions,
    customer: customerOptions,
    tag: tagOptions,
    externalapp: externalappOptions,
    team: teamOptions,
    template: templateOptions,
    user: userOptions,
  };

  const dropdownOnOpen = {
    contact: onOpenContacts,
    customer: onOpenCustomers,
    tag: onOpenTags,
    template: onOpenTemplates,
    externalapp: onOpenExternalapps,
    user: onOpenUsers,
  };

  const dropdownOnSearch = {
    contact: onSearchContacts,
    customer: onSearchCustomers,
    tag: onSearchTags,
    externalapp: onSearchExternalapps,
  };

  const templateCallback = useCallback(() => {
    // dispatch(selectTemplate({ template: null }));
    if (
      selectedCampaign !== null &&
      typeof selectedCampaign?._id !== "undefined" &&
      !!selectedCampaign.externalapp
    ) {
      const _options: ddOptions[] = [];
      const _optionsExt: ddOptions[] = [];
      const _externalApp: IExternalAppWhatsApp | IExternalAppBotmaker =
        selectedCampaign.externalapp;
      if (_externalApp.type === ExternalAppType.WHATSAPP) {
        const _externalWpp = _externalApp as IExternalAppWhatsApp;
        _optionsExt.push({
          key: _externalWpp._id,
          text: _externalWpp.fields.name,
          value: _externalWpp._id,
          content: (
            <div className="flex">
              <h1 className="mr-2">{_externalWpp?.fields?.name}</h1>
              {typeof _externalWpp?.fields?.displayphone !== "undefined" ? (
                <h1 className="text-gray-text">
                  ({handleMaskRegex(_externalWpp?.fields?.displayphone)})
                </h1>
              ) : null}
            </div>
          ),
        });
      } else if (_externalApp.type === ExternalAppType.BOTMAKER) {
        const _externalBot = _externalApp as IExternalAppBotmaker;
        const _listPhones = _externalBot.fields.externalphones;
        if (typeof _listPhones !== "undefined" && _listPhones.length > 0) {
          for (const _phone of _listPhones) {
            if (_phone.phone === selectedCampaign.externalappnumber) {
              _optionsExt.push({
                key: _phone.phone,
                text: _phone.name,
                value: `${_externalBot._id}|%@g1%|${_phone.phone}`,
                content: (
                  <div className="flex">
                    <h1 className="mr-2">{_phone?.name}</h1>
                    {typeof _phone.phone !== "undefined" ? (
                      <h1 className="text-gray-text">
                        ({handleMaskRegex(_phone.phone)})
                      </h1>
                    ) : null}
                  </div>
                ),
              });
            }
          }
        }
      }
      setExternalappOptions(_optionsExt);
      // setExternalappOptions([
      //   {
      //     key: selectedCampaign?.externalapp?._id,
      //     text: selectedCampaign?.externalapp?.fields?.name,
      //     value: selectedCampaign?.externalapp?._id,
      //   },
      // ]);
      if (
        typeof selectedCampaign?.externalapp?._id !== "undefined" &&
        selectedCampaign?.externalapp?._id.length > 0
      ) {
        (async () => {
          const externalappid = selectedCampaign?.externalapp?._id as string;
          const externalapptype = selectedCampaign?.externalapp
            ?.type as ExternalAppType;

          const _payload =
            externalapptype === ExternalAppType.WHATSAPP
              ? await whatsappService.templateWpp({ externalappid })
              : await botmakerService.templateWpp({ externalappid });

          const _phone =
            externalapptype === ExternalAppType.WHATSAPP
              ? ""
              : typeof selectedLocalCampaign?.externalappnumber !==
                  "undefined" && selectedLocalCampaign?.externalappnumber
              ? selectedLocalCampaign?.externalappnumber
              : phoneNumberRef.current;
          const payload =
            typeof _phone !== "undefined" &&
            _phone &&
            externalapptype !== ExternalAppType.WHATSAPP
              ? _payload.filter((_temp: any) => _temp.phone === _phone)
              : _payload;

          if (typeof payload !== "undefined" && payload.length > 0) {
            // if (typeof templates !== "undefined" && templates.length > 0) {
            for (const template of payload) {
              // for (const template of templates) {
              _options.push({
                key: template.id,
                text: template.name,
                value: template.id,
              });
              if (
                template.id === selectedCampaign.template &&
                selectedTemplate === null
              ) {
                const _value = {
                  code: "",
                  url: "",
                  key: "",
                };
                dispatch(
                  selectTemplate({
                    template,
                    campaignMode: true,
                    campaignVars:
                      template.id === selectedCampaign?.template
                        ? selectedCampaign?.templatevars
                        : undefined,
                  })
                );
                if (
                  typeof selectedCampaign?.templatevars !== "undefined" &&
                  selectedCampaign.templatevars &&
                  selectedCampaign.templatevars.length > 0
                ) {
                  const _templatevars = selectedCampaign.templatevars
                    ? JSON.parse(selectedCampaign.templatevars)
                    : "";
                  for (const _templatevar of _templatevars) {
                    if (
                      _templatevar.key === "{{CODE-1}}" &&
                      _templatevar.type === "coupon_code" &&
                      typeof _templatevar.coupon_code !== "undefined"
                    ) {
                      _value.code = _templatevar.coupon_code;
                      _value.key = _templatevar.key;
                    } else if (
                      _templatevar.key === "{{URL-1}}" &&
                      _templatevar.type === "text" &&
                      typeof _templatevar.text !== "undefined"
                    ) {
                      _value.url = _templatevar.text;
                    }
                  }
                  if (_value.code.length > 0 || _value.url.length > 0) {
                    setTemplateButtons((prevState) => ({
                      ...prevState,
                      code: _value.code,
                      url: _value.url,
                    }));
                  }
                }
              }
            }
            setTemplates(payload);
            // setTemplates(templates);
          }
        })();
      }
      setTemplateOptions(_options);
    }
  }, []);

  const chatCallback = useCallback(() => {
    if (
      selectedLocalCampaign !== null &&
      typeof selectedLocalCampaign?._id !== "undefined"
    ) {
      const _teamOptions: ddOptions[] = [];
      const _userOptions: ddOptions[] = [];
      selectedLocalCampaign?.externalapp?.fields?.defaultteam?.forEach(
        (_team: { _id: string; name: string }) => {
          _teamOptions.push({
            key: _team._id,
            text: _team.name,
            value: _team._id,
          });
        }
      );
      if (selectedLocalCampaign.createchat === true) {
        if (_teamOptions.length === 1) {
          const _teamid = _teamOptions[0].value as string;
          let _id = "";
          const name = "";
          if (typeof selectedLocalCampaign?.team === "string") {
            _id = selectedLocalCampaign?.team as string;
          } else if (typeof selectedLocalCampaign?.team?._id !== "undefined") {
            _id = selectedLocalCampaign?.team._id as string;
          } else if (typeof _teamid !== "undefined" && _teamid) {
            _id = _teamid;
          }
          dispatch(
            reselectLocalCampaign({
              ...selectedLocalCampaign,
              team: { _id, name },
            })
          );
          // (async () => {
          //   await checkUsers(
          //     _teamOptions[0].value as string,
          //     _teamOptions[0].text as string
          //   );
          // })();
        } else {
          // if (_teamOptions.length > 1) {
          dispatch(reselectLocalCampaign(selectedLocalCampaign));
        }
        if (typeof selectedLocalCampaign?.user?._id !== "undefined") {
          _userOptions.push({
            key: selectedLocalCampaign?.user?._id,
            text: selectedLocalCampaign?.user?.name,
            value: selectedLocalCampaign?.user?._id,
          });
          setUserOptions(_userOptions);
        }
      } else {
        dispatch(reselectLocalCampaign(selectedLocalCampaign));
      }
      setTeamOptions(_teamOptions);
    }
  }, [selectedCampaign, selectedLocalCampaign?.externalapp?._id]);

  const effect = {
    chat: chatCallback,
    template: templateCallback,
  };

  const getValue = () => {
    if (
      selectedLocalCampaign &&
      typeof selectedLocalCampaign?.externalapp === "string"
    ) {
      return !selectedLocalCampaign?.externalappnumber
        ? selectedLocalCampaign?.externalapp
        : `${selectedLocalCampaign?.externalapp}|%@g1%|${selectedLocalCampaign?.externalappnumber}`;
    }
    return !selectedLocalCampaign?.externalappnumber
      ? selectedLocalCampaign?.externalapp?._id
      : `${selectedLocalCampaign?.externalapp?._id}|%@g1%|${selectedLocalCampaign?.externalappnumber}`;
  };

  const dropdownValue = {
    contact: contactsValue,
    customer: customerValue,
    tag: tagValue,
    externalapp: getValue(),
    team:
      selectedLocalCampaign && typeof selectedLocalCampaign?.team === "string"
        ? selectedLocalCampaign?.team
        : selectedLocalCampaign?.team?._id,
    template: getValueTemplates(),
    user: getValueUsers(),
  };

  const dropdownIsLoading = {
    contact:
      isLoadingDropdown && focusId === "dropdown-campaign-import-contact",
    customer:
      isLoadingDropdown && focusId === "dropdown-campaign-import-customer",
    tag: isLoadingDropdown && focusId === "dropdown-campaign-import-tag",
    template: isLoadingDropdown && focusId === "dropdown-campaign-templates",
    externalapp:
      isLoadingDropdown && focusId === "dropdown-campaign-externalapps",
    user: isLoadingDropdown && focusId === "dropdown-campaign-user",
  };

  const dropdownDisabled = {
    active: selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor),
    // team: teamOptions.length === 1,
    user: typeof selectedLocalCampaign?.team?._id === "undefined",
    // || userOptions.length === 1,
  };

  type onChangeEvent = React.SyntheticEvent<HTMLElement, Event>;
  type onChangeValue =
    | string
    | number
    | boolean
    | (string | number | boolean)[]
    | undefined;

  const onChangeTeams = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    setUserOptions([]);
    // updateCampaignData({
    //   field: "user",
    //   value: "",
    // });
    const _target = e.target as HTMLInputElement;
    const _value: { _id: string; name: string } = {
      _id: value as string,
      name: _target.innerText,
    };
    // updateCampaignData({
    //   field: "team",
    //   value: _value,
    // });
    dispatch(
      reselectLocalCampaign({
        ...selectedLocalCampaign,
        team: _value,
        user: "",
      })
    );
    // await checkUsers(_value._id, _value.name);
  };

  const onChangeUsers = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    const _target = e.target as HTMLInputElement;
    const _value: { _id: string; name: string } = {
      _id: value as string,
      name: _target.innerText,
    };
    updateCampaignData({
      field: "user",
      value: _value,
    });
  };

  const onChangeCreateChat = async ({ checked }: { checked: boolean }) => {
    if (checked === true) {
      updateCampaignData({
        field: "createchat",
        value: checked,
      });
      const _userOptions: ddOptions[] = [];
      // if (teamOptions.length === 1) {
      //   (async () => {
      //     await checkUsers(
      //       teamOptions[0].value as string,
      //       teamOptions[0].text as string
      //     );
      //   })();
      // }
      if (typeof selectedLocalCampaign?.user?._id !== "undefined") {
        _userOptions.push({
          key: selectedLocalCampaign?.user?._id,
          text: selectedLocalCampaign?.user?.name,
          value: selectedLocalCampaign?.user?._id,
        });
        setUserOptions(_userOptions);
      }
    } else {
      setUserOptions([]);
      dispatch(
        reselectLocalCampaign({
          ...selectedLocalCampaign,
          createchat: checked,
          team: "",
          user: "",
        })
      );
    }
  };

  const onChangeSendEmail = async ({ checked }: { checked: boolean }) => {
    if (checked === true) {
      updateCampaignData({
        field: "sendemail",
        value: checked,
      });
    } else {
      dispatch(
        reselectLocalCampaign({
          ...selectedLocalCampaign,
          sendemail: checked,
          email: "",
        })
      );
    }
  };

  const onChangeExternalapps = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    try {
      if (typeof value === "string") {
        const _value = !value.includes("|%@g1%|")
          ? value
          : value.split("|%@g1%|")[0];
        const _key = !value?.includes("|%@g1%|")
          ? null
          : value.split("|%@g1%|")[1];
        if (_key) {
          const _selected =
            externalappOptions.find(
              (_op) => _op.value && _op.value.includes(_key as string)
            ) || null;
          phoneNumberRef.current = _selected ? (_selected.key as string) : "";
        }
        const _externalappid = _value;
        if (typeof _externalappid !== "undefined") {
          let _payload: any = "";
          if (_externalappid.length > 0) {
            const payload = await externalappsService.externalapps({
              _id: _externalappid,
            });
            _payload = payload;
          }
          dispatch(selectTemplate({ template: null }));
          dispatch(
            reselectLocalCampaign({
              ...selectedLocalCampaign,
              externalappnumber: _key ? phoneNumberRef.current : "",
              externalapp: typeof _payload?._id !== "undefined" ? _payload : "",
              team: "",
              user: "",
              template: "",
              templatejson: "",
              templatecontent: "",
              templatefiles: [],
              templatevars: "",
              templatephone: "",
            })
          );
          setUserOptions([]);
          setTemplateOptions([]);
          setTemplateButtons({ code: "", url: "" });
          setFilesTemplate([]);
          setTemplates([]);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onChangeEmail = async ({ emails }: { emails: string[] }) => {
    updateCampaignData({
      field: "email",
      value: emails?.join(";")?.toLowerCase(),
    });
  };

  const onChangeTemplates = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    try {
      if (typeof value === "string") {
        updateCampaignData({
          field: "template",
          value,
        });
        // const _template = templates.find((_temp) => _temp.id === value);
        // if (typeof _template?.phone !== "undefined" && _template?.phone.length > 0) {
        //   updateCampaignData({
        //     field: "templatephone",
        //     value: _template?.phone,
        //   });
        // }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onChangeCustomers = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    try {
      setContactOptions([]);
      setContactsValue([]);
      setCustomerValue(value as string);
    } catch (error) {
      // console.log(error);
    }
  };

  const onChangeTags = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    try {
      setContactOptions([]);
      setContactsValue([]);
      setTagValue(value as string);
    } catch (error) {
      // console.log(error);
    }
  };

  const onChangeContacts = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    try {
      const _value = value as string[];
      setContactsValue(_value.includes("-1") ? ["-1"] : _value);
    } catch (error) {
      // console.log(error);
    }
  };

  const onChangeActive = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    try {
      if (typeof value === "boolean") {
        updateCampaignData({
          field: "active",
          value,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onChangeName = async (
    e: onChangeEvent,
    { value }: { value: onChangeValue }
  ) => {
    try {
      if (typeof value === "string") {
        updateCampaignData({
          field: "name",
          value,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };
  // const onChangeMaxsend = async (
  //   e: onChangeEvent,
  //   { value }: { value: onChangeValue }
  // ) => {
  //   try {
  //     if (typeof value !== "undefined") {
  //       updateCampaignData({
  //         field: "maxsend",
  //         value: +value,
  //       });
  //     }
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };

  const dropdownOnChange = {
    active: onChangeActive,
    contact: onChangeContacts,
    customer: onChangeCustomers,
    tag: onChangeTags,
    user: onChangeUsers,
    team: onChangeTeams,
    template: onChangeTemplates,
    externalapp: onChangeExternalapps,
  };

  const fieldOnChange = {
    name: onChangeName,
    // maxsend: onChangeMaxsend,
  };

  const email = {
    emails: selectedLocalCampaign?.email,
    disabled: selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor),
    checked: selectedLocalCampaign?.sendemail,
    onChange: onChangeSendEmail,
    onUpdate: onChangeEmail,
  };

  const chat = {
    disabled: selectedLocalCampaign?.deleted || (!isAdmin && !isSupervisor),
    checked: selectedLocalCampaign?.createchat,
    onChange: onChangeCreateChat,
  };

  const dropdown = {
    disabled: dropdownDisabled,
    isLoading: dropdownIsLoading,
    onBlur,
    onChange: dropdownOnChange,
    onFocus: dropdownOnFocus,
    onOpen: dropdownOnOpen,
    onSearch: dropdownOnSearch,
    options: dropdownOptions,
    value: dropdownValue,
  };

  const target = {
    targets: data?.results || [],
    file: fileImport,
    setFile: setFileImport,
    addTargets: addMutation,
    removeTargets: deleteMutation,
    disabled: !fileImport || fileImport.length !== 1,
  };

  const template = {
    templates,
    files: filesTemplate,
    setFiles: setFilesTemplate,
    buttons: templateButtons,
    setButtons: setTemplateButtons,
    startDisabledCallback,
  };

  const field = {
    onChange: fieldOnChange,
  };

  const table = {
    keyword,
    isLoading: isLoadingCampaignTargets,
    setKeyword,
    skip,
    setSkip,
    activePage,
    setActivePage,
    instancesPerPage,
    setInstancesPerPage,
    limit,
    setLimit,
    total: selectedTotalCampaignTargets || 0,
    title: "Alvos selecionados",
    icon: "las la-bullseye",
    list: selectedCampaignTargets || [],
  };

  const getFooterTitle = () => {
    if (selectedLocalCampaign?.deleted) {
      return "Deseja reativar esta campanha?";
    }
    if (showStartModal) {
      return "Deseja iniciar esta campanha?";
    }
    return "Deseja remover esta campanha?";
  };

  const footer = {
    reactive: canAccess(Permissions.DELETE_CAMPAIGNS) ? reactive : undefined,
    start: canAccess(Permissions.START_CAMPAIGNS) ? start : undefined,
    save: canAccess(Permissions.CREATE_CAMPAIGNS) ? save : undefined,
    remove: canAccess(Permissions.DELETE_CAMPAIGNS) ? remove : undefined,
    back,
    showStartModal,
    showDeleteModal,
    showReactiveModal,
    setShowStartModal,
    setShowDeleteModal,
    setShowReactiveModal,
    startDisabled:
      disableStartCampaign ||
      selectedLocalCampaign?.active === false ||
      isImportingTargets,
    disabled:
      // startDisabled ||
      isImportingTargets ||
      typeof selectedLocalCampaign?.name === "undefined" ||
      selectedLocalCampaign?.name?.length < 2 ||
      // typeof selectedLocalCampaign?.maxsend === "undefined" ||
      // !selectedLocalCampaign?.maxsend ||
      // selectedLocalCampaign?.maxsend <= 0 ||
      selectedLocalCampaign?.deleted ||
      (selectedLocalCampaign?.sendemail &&
        (typeof selectedLocalCampaign?.email === "undefined" ||
          selectedLocalCampaign?.email?.length === 0)) ||
      (selectedLocalCampaign?.createchat === true &&
        (typeof selectedLocalCampaign?.team === "undefined" ||
          !selectedLocalCampaign?.team)),
    // typeof selectedLocalCampaign?.template === "undefined" ||
    // !selectedLocalCampaign?.template,
    title: getFooterTitle(),
  };

  return {
    chat,
    email,
    effect,
    sectionDetails,
    field,
    dropdown,
    target,
    template,
    table,
    footer,
  };
};

export default useCampaigns;
