import React from "react";
import { useLocation } from "react-router-dom";
import Toggle from "../Toggle.component";
import Button from "../Buttons/Button.component";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../../hooks/useUserIsSupervisor";

const TableButtonOptions = ({
  setShowCards,
  setShowModal,
  showDeleted,
  setShowDeleted,
  showSpam,
  setShowSpam,
  setSkip,
  setLimit,
  setActivePage,
  selectedFlatRows,
  setShowDeleteModal,
  setShowSpamModal,
  basicGrid,
}: {
  setShowCards?: () => void;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleted?: boolean;
  setShowDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
  showSpam?: boolean;
  setShowSpam?: React.Dispatch<React.SetStateAction<boolean>>;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  selectedFlatRows: number;
  setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSpamModal?: React.Dispatch<React.SetStateAction<boolean>>;
  basicGrid?: boolean;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const location = useLocation();
  return (
    <div className="flex flex-1 justify-end items-center">
      <div className="flex w-full justify-start items-center">
        {/* {typeof tagToggleList !== "undefined" &&
        typeof setTagToggleList !== "undefined" &&
        location.pathname.includes("tag") ? (
          <TagTypeDropdown
            placeholder="Filtrar por tipo"
            onChange={async (e, { value }) => {
              const _value = value as TagType;
              setTagToggleList(_value);
            }}
          />
        ) : null} */}
        {typeof setShowDeleted !== "undefined" && !basicGrid ? (
          <Toggle
            containerClass="flex justify-start items-center"
            defaultChecked={showDeleted}
            mainClass="ml-4 flex items-center"
            onChange={(_, { checked }) => {
              setSkip(0);
              setLimit(10);
              setActivePage(1);
              setShowDeleted(checked || false);
            }}
            labelClass="hidden lg:flex ml-4"
            label="Removidos"
          />
        ) : null}
        {typeof setShowSpam !== "undefined" &&
          !basicGrid &&
          (isAdmin || isSupervisor) &&
          location.pathname.includes("settings/contact") ? (
          <Toggle
            containerClass="flex w-full justify-start items-center"
            defaultChecked={showSpam}
            mainClass="ml-4 flex items-center"
            onChange={(_, { checked }) => {
              setSkip(0);
              setLimit(10);
              setActivePage(1);
              setShowSpam(checked || false);
            }}
            labelClass="hidden lg:flex ml-4"
            label="SPAM"
          />
        ) : null}
      </div>
      <div className="w-full flex justify-end">
        {selectedFlatRows > 0 && setShowDeleteModal ? (
          <Button
            minWidth={false}
            label={`Remover (${selectedFlatRows})`}
            icon="las la-trash"
            extraClass="mr-1"
            onClick={() => {
              setShowDeleteModal(true);
            }}
            inverted
            red
          />
        ) : null}
        {selectedFlatRows > 0 &&
          setShowSpamModal &&
          !showSpam &&
          location.pathname.includes("settings/contact") ? (
          <Button
            minWidth={false}
            label={`SPAM (${selectedFlatRows})`}
            icon="las la-exclamation-circle"
            onClick={() => {
              setShowSpamModal(true);
            }}
            inverted
            orange
          />
        ) : null}
        <div className="mr-4" />
      </div>

      {basicGrid !== true &&
        !!setShowModal ? (
        <>
          <Button
            extraClass="md:w-[80px]"
            minWidth={false}
            label="Criar"
            onClick={() => setShowModal(true)}
            icon="las la-plus"
          />
          <div className="mr-1" />
        </>
      ) : null}
      <div className="flex">
        {setShowCards ? (
          <Button
            extraClass="md:w-[80px]"
            minWidth={false}
            label="Grid"
            icon="las la-th-large"
            onClick={setShowCards}
            inverted
          />
        ) : null}
      </div>
    </div>
  );
};

TableButtonOptions.defaultProps = {
  setShowCards: undefined,
  showDeleted: undefined,
  setShowDeleted: undefined,
  showSpam: undefined,
  setShowSpam: undefined,
  basicGrid: undefined,
  setShowModal: undefined,
  setShowDeleteModal: undefined,
  setShowSpamModal: undefined,
};

export default TableButtonOptions;
